/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser(
    $filter: ModelSubscriptionUserFilterInput
    $owner: String
  ) {
    onCreateUser(filter: $filter, owner: $owner) {
      id
      email
      name
      avatar
      crews {
        items {
          id
          userId
          crewId
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          crew {
            id
            name
            avatar
            members {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            admins {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoritedByUsers {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      adminCrews {
        items {
          id
          userId
          crewId
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          crew {
            id
            name
            avatar
            members {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            admins {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoritedByUsers {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      favoriteCrews {
        items {
          id
          userId
          crewId
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          crew {
            id
            name
            avatar
            members {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            admins {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoritedByUsers {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      userPreference {
        id
        user {
          id
          email
          name
          avatar
          crews {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          adminCrews {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          favoriteCrews {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          userPreference {
            id
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            selectedEventId
            userIdFilter
            timeFilter
            grouping
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userPreferenceUserId
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserPreferenceId
          owner
          __typename
        }
        selectedEventId
        userIdFilter
        timeFilter
        grouping
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userPreferenceUserId
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userUserPreferenceId
      owner
      __typename
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser(
    $filter: ModelSubscriptionUserFilterInput
    $owner: String
  ) {
    onUpdateUser(filter: $filter, owner: $owner) {
      id
      email
      name
      avatar
      crews {
        items {
          id
          userId
          crewId
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          crew {
            id
            name
            avatar
            members {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            admins {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoritedByUsers {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      adminCrews {
        items {
          id
          userId
          crewId
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          crew {
            id
            name
            avatar
            members {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            admins {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoritedByUsers {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      favoriteCrews {
        items {
          id
          userId
          crewId
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          crew {
            id
            name
            avatar
            members {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            admins {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoritedByUsers {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      userPreference {
        id
        user {
          id
          email
          name
          avatar
          crews {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          adminCrews {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          favoriteCrews {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          userPreference {
            id
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            selectedEventId
            userIdFilter
            timeFilter
            grouping
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userPreferenceUserId
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserPreferenceId
          owner
          __typename
        }
        selectedEventId
        userIdFilter
        timeFilter
        grouping
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userPreferenceUserId
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userUserPreferenceId
      owner
      __typename
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser(
    $filter: ModelSubscriptionUserFilterInput
    $owner: String
  ) {
    onDeleteUser(filter: $filter, owner: $owner) {
      id
      email
      name
      avatar
      crews {
        items {
          id
          userId
          crewId
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          crew {
            id
            name
            avatar
            members {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            admins {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoritedByUsers {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      adminCrews {
        items {
          id
          userId
          crewId
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          crew {
            id
            name
            avatar
            members {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            admins {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoritedByUsers {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      favoriteCrews {
        items {
          id
          userId
          crewId
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          crew {
            id
            name
            avatar
            members {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            admins {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoritedByUsers {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      userPreference {
        id
        user {
          id
          email
          name
          avatar
          crews {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          adminCrews {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          favoriteCrews {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          userPreference {
            id
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            selectedEventId
            userIdFilter
            timeFilter
            grouping
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userPreferenceUserId
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserPreferenceId
          owner
          __typename
        }
        selectedEventId
        userIdFilter
        timeFilter
        grouping
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userPreferenceUserId
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userUserPreferenceId
      owner
      __typename
    }
  }
`;
export const onCreateUserPreference = /* GraphQL */ `
  subscription OnCreateUserPreference(
    $filter: ModelSubscriptionUserPreferenceFilterInput
    $owner: String
  ) {
    onCreateUserPreference(filter: $filter, owner: $owner) {
      id
      user {
        id
        email
        name
        avatar
        crews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        adminCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        favoriteCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userPreference {
          id
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          selectedEventId
          userIdFilter
          timeFilter
          grouping
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userPreferenceUserId
          owner
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserPreferenceId
        owner
        __typename
      }
      selectedEventId
      userIdFilter
      timeFilter
      grouping
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userPreferenceUserId
      owner
      __typename
    }
  }
`;
export const onUpdateUserPreference = /* GraphQL */ `
  subscription OnUpdateUserPreference(
    $filter: ModelSubscriptionUserPreferenceFilterInput
    $owner: String
  ) {
    onUpdateUserPreference(filter: $filter, owner: $owner) {
      id
      user {
        id
        email
        name
        avatar
        crews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        adminCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        favoriteCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userPreference {
          id
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          selectedEventId
          userIdFilter
          timeFilter
          grouping
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userPreferenceUserId
          owner
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserPreferenceId
        owner
        __typename
      }
      selectedEventId
      userIdFilter
      timeFilter
      grouping
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userPreferenceUserId
      owner
      __typename
    }
  }
`;
export const onDeleteUserPreference = /* GraphQL */ `
  subscription OnDeleteUserPreference(
    $filter: ModelSubscriptionUserPreferenceFilterInput
    $owner: String
  ) {
    onDeleteUserPreference(filter: $filter, owner: $owner) {
      id
      user {
        id
        email
        name
        avatar
        crews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        adminCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        favoriteCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userPreference {
          id
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          selectedEventId
          userIdFilter
          timeFilter
          grouping
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userPreferenceUserId
          owner
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserPreferenceId
        owner
        __typename
      }
      selectedEventId
      userIdFilter
      timeFilter
      grouping
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userPreferenceUserId
      owner
      __typename
    }
  }
`;
export const onCreateCrew = /* GraphQL */ `
  subscription OnCreateCrew(
    $filter: ModelSubscriptionCrewFilterInput
    $owner: String
  ) {
    onCreateCrew(filter: $filter, owner: $owner) {
      id
      name
      avatar
      members {
        items {
          id
          userId
          crewId
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          crew {
            id
            name
            avatar
            members {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            admins {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoritedByUsers {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      admins {
        items {
          id
          userId
          crewId
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          crew {
            id
            name
            avatar
            members {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            admins {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoritedByUsers {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      favoritedByUsers {
        items {
          id
          userId
          crewId
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          crew {
            id
            name
            avatar
            members {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            admins {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoritedByUsers {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateCrew = /* GraphQL */ `
  subscription OnUpdateCrew(
    $filter: ModelSubscriptionCrewFilterInput
    $owner: String
  ) {
    onUpdateCrew(filter: $filter, owner: $owner) {
      id
      name
      avatar
      members {
        items {
          id
          userId
          crewId
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          crew {
            id
            name
            avatar
            members {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            admins {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoritedByUsers {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      admins {
        items {
          id
          userId
          crewId
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          crew {
            id
            name
            avatar
            members {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            admins {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoritedByUsers {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      favoritedByUsers {
        items {
          id
          userId
          crewId
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          crew {
            id
            name
            avatar
            members {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            admins {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoritedByUsers {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteCrew = /* GraphQL */ `
  subscription OnDeleteCrew(
    $filter: ModelSubscriptionCrewFilterInput
    $owner: String
  ) {
    onDeleteCrew(filter: $filter, owner: $owner) {
      id
      name
      avatar
      members {
        items {
          id
          userId
          crewId
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          crew {
            id
            name
            avatar
            members {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            admins {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoritedByUsers {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      admins {
        items {
          id
          userId
          crewId
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          crew {
            id
            name
            avatar
            members {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            admins {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoritedByUsers {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      favoritedByUsers {
        items {
          id
          userId
          crewId
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          crew {
            id
            name
            avatar
            members {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            admins {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoritedByUsers {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateVenue = /* GraphQL */ `
  subscription OnCreateVenue(
    $filter: ModelSubscriptionVenueFilterInput
    $owner: String
  ) {
    onCreateVenue(filter: $filter, owner: $owner) {
      id
      name
      address
      info
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateVenue = /* GraphQL */ `
  subscription OnUpdateVenue(
    $filter: ModelSubscriptionVenueFilterInput
    $owner: String
  ) {
    onUpdateVenue(filter: $filter, owner: $owner) {
      id
      name
      address
      info
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteVenue = /* GraphQL */ `
  subscription OnDeleteVenue(
    $filter: ModelSubscriptionVenueFilterInput
    $owner: String
  ) {
    onDeleteVenue(filter: $filter, owner: $owner) {
      id
      name
      address
      info
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateEvent = /* GraphQL */ `
  subscription OnCreateEvent(
    $filter: ModelSubscriptionEventFilterInput
    $owner: String
  ) {
    onCreateEvent(filter: $filter, owner: $owner) {
      id
      name
      startDate
      endDate
      venueId
      venue {
        id
        name
        address
        info
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      info
      avatar
      isPublic
      isSponsored
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateEvent = /* GraphQL */ `
  subscription OnUpdateEvent(
    $filter: ModelSubscriptionEventFilterInput
    $owner: String
  ) {
    onUpdateEvent(filter: $filter, owner: $owner) {
      id
      name
      startDate
      endDate
      venueId
      venue {
        id
        name
        address
        info
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      info
      avatar
      isPublic
      isSponsored
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteEvent = /* GraphQL */ `
  subscription OnDeleteEvent(
    $filter: ModelSubscriptionEventFilterInput
    $owner: String
  ) {
    onDeleteEvent(filter: $filter, owner: $owner) {
      id
      name
      startDate
      endDate
      venueId
      venue {
        id
        name
        address
        info
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      info
      avatar
      isPublic
      isSponsored
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateLocation = /* GraphQL */ `
  subscription OnCreateLocation(
    $filter: ModelSubscriptionLocationFilterInput
    $owner: String
  ) {
    onCreateLocation(filter: $filter, owner: $owner) {
      id
      eventId
      event {
        id
        name
        startDate
        endDate
        venueId
        venue {
          id
          name
          address
          info
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        info
        avatar
        isPublic
        isSponsored
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      name
      info
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateLocation = /* GraphQL */ `
  subscription OnUpdateLocation(
    $filter: ModelSubscriptionLocationFilterInput
    $owner: String
  ) {
    onUpdateLocation(filter: $filter, owner: $owner) {
      id
      eventId
      event {
        id
        name
        startDate
        endDate
        venueId
        venue {
          id
          name
          address
          info
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        info
        avatar
        isPublic
        isSponsored
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      name
      info
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteLocation = /* GraphQL */ `
  subscription OnDeleteLocation(
    $filter: ModelSubscriptionLocationFilterInput
    $owner: String
  ) {
    onDeleteLocation(filter: $filter, owner: $owner) {
      id
      eventId
      event {
        id
        name
        startDate
        endDate
        venueId
        venue {
          id
          name
          address
          info
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        info
        avatar
        isPublic
        isSponsored
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      name
      info
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateSession = /* GraphQL */ `
  subscription OnCreateSession(
    $filter: ModelSubscriptionSessionFilterInput
    $owner: String
  ) {
    onCreateSession(filter: $filter, owner: $owner) {
      id
      eventId
      event {
        id
        name
        startDate
        endDate
        venueId
        venue {
          id
          name
          address
          info
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        info
        avatar
        isPublic
        isSponsored
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      locationId
      location {
        id
        eventId
        event {
          id
          name
          startDate
          endDate
          venueId
          venue {
            id
            name
            address
            info
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          info
          avatar
          isPublic
          isSponsored
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        name
        info
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      name
      startDate
      endDate
      info
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateSession = /* GraphQL */ `
  subscription OnUpdateSession(
    $filter: ModelSubscriptionSessionFilterInput
    $owner: String
  ) {
    onUpdateSession(filter: $filter, owner: $owner) {
      id
      eventId
      event {
        id
        name
        startDate
        endDate
        venueId
        venue {
          id
          name
          address
          info
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        info
        avatar
        isPublic
        isSponsored
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      locationId
      location {
        id
        eventId
        event {
          id
          name
          startDate
          endDate
          venueId
          venue {
            id
            name
            address
            info
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          info
          avatar
          isPublic
          isSponsored
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        name
        info
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      name
      startDate
      endDate
      info
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteSession = /* GraphQL */ `
  subscription OnDeleteSession(
    $filter: ModelSubscriptionSessionFilterInput
    $owner: String
  ) {
    onDeleteSession(filter: $filter, owner: $owner) {
      id
      eventId
      event {
        id
        name
        startDate
        endDate
        venueId
        venue {
          id
          name
          address
          info
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        info
        avatar
        isPublic
        isSponsored
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      locationId
      location {
        id
        eventId
        event {
          id
          name
          startDate
          endDate
          venueId
          venue {
            id
            name
            address
            info
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          info
          avatar
          isPublic
          isSponsored
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        name
        info
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      name
      startDate
      endDate
      info
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateUserSession = /* GraphQL */ `
  subscription OnCreateUserSession(
    $filter: ModelSubscriptionUserSessionFilterInput
    $owner: String
  ) {
    onCreateUserSession(filter: $filter, owner: $owner) {
      id
      eventId
      event {
        id
        name
        startDate
        endDate
        venueId
        venue {
          id
          name
          address
          info
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        info
        avatar
        isPublic
        isSponsored
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      sessionId
      session {
        id
        eventId
        event {
          id
          name
          startDate
          endDate
          venueId
          venue {
            id
            name
            address
            info
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          info
          avatar
          isPublic
          isSponsored
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        locationId
        location {
          id
          eventId
          event {
            id
            name
            startDate
            endDate
            venueId
            venue {
              id
              name
              address
              info
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            info
            avatar
            isPublic
            isSponsored
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          name
          info
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        name
        startDate
        endDate
        info
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      userId
      user {
        id
        email
        name
        avatar
        crews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        adminCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        favoriteCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userPreference {
          id
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          selectedEventId
          userIdFilter
          timeFilter
          grouping
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userPreferenceUserId
          owner
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserPreferenceId
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateUserSession = /* GraphQL */ `
  subscription OnUpdateUserSession(
    $filter: ModelSubscriptionUserSessionFilterInput
    $owner: String
  ) {
    onUpdateUserSession(filter: $filter, owner: $owner) {
      id
      eventId
      event {
        id
        name
        startDate
        endDate
        venueId
        venue {
          id
          name
          address
          info
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        info
        avatar
        isPublic
        isSponsored
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      sessionId
      session {
        id
        eventId
        event {
          id
          name
          startDate
          endDate
          venueId
          venue {
            id
            name
            address
            info
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          info
          avatar
          isPublic
          isSponsored
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        locationId
        location {
          id
          eventId
          event {
            id
            name
            startDate
            endDate
            venueId
            venue {
              id
              name
              address
              info
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            info
            avatar
            isPublic
            isSponsored
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          name
          info
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        name
        startDate
        endDate
        info
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      userId
      user {
        id
        email
        name
        avatar
        crews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        adminCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        favoriteCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userPreference {
          id
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          selectedEventId
          userIdFilter
          timeFilter
          grouping
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userPreferenceUserId
          owner
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserPreferenceId
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteUserSession = /* GraphQL */ `
  subscription OnDeleteUserSession(
    $filter: ModelSubscriptionUserSessionFilterInput
    $owner: String
  ) {
    onDeleteUserSession(filter: $filter, owner: $owner) {
      id
      eventId
      event {
        id
        name
        startDate
        endDate
        venueId
        venue {
          id
          name
          address
          info
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        info
        avatar
        isPublic
        isSponsored
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      sessionId
      session {
        id
        eventId
        event {
          id
          name
          startDate
          endDate
          venueId
          venue {
            id
            name
            address
            info
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          info
          avatar
          isPublic
          isSponsored
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        locationId
        location {
          id
          eventId
          event {
            id
            name
            startDate
            endDate
            venueId
            venue {
              id
              name
              address
              info
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            info
            avatar
            isPublic
            isSponsored
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          name
          info
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        name
        startDate
        endDate
        info
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      userId
      user {
        id
        email
        name
        avatar
        crews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        adminCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        favoriteCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userPreference {
          id
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          selectedEventId
          userIdFilter
          timeFilter
          grouping
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userPreferenceUserId
          owner
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserPreferenceId
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateJoinCrewCode = /* GraphQL */ `
  subscription OnCreateJoinCrewCode(
    $filter: ModelSubscriptionJoinCrewCodeFilterInput
    $owner: String
  ) {
    onCreateJoinCrewCode(filter: $filter, owner: $owner) {
      id
      crewId
      crew {
        id
        name
        avatar
        members {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        admins {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        favoritedByUsers {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      code
      expireDate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateJoinCrewCode = /* GraphQL */ `
  subscription OnUpdateJoinCrewCode(
    $filter: ModelSubscriptionJoinCrewCodeFilterInput
    $owner: String
  ) {
    onUpdateJoinCrewCode(filter: $filter, owner: $owner) {
      id
      crewId
      crew {
        id
        name
        avatar
        members {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        admins {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        favoritedByUsers {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      code
      expireDate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteJoinCrewCode = /* GraphQL */ `
  subscription OnDeleteJoinCrewCode(
    $filter: ModelSubscriptionJoinCrewCodeFilterInput
    $owner: String
  ) {
    onDeleteJoinCrewCode(filter: $filter, owner: $owner) {
      id
      crewId
      crew {
        id
        name
        avatar
        members {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        admins {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        favoritedByUsers {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      code
      expireDate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateAllowedUser = /* GraphQL */ `
  subscription OnCreateAllowedUser(
    $filter: ModelSubscriptionAllowedUserFilterInput
  ) {
    onCreateAllowedUser(filter: $filter) {
      id
      email
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateAllowedUser = /* GraphQL */ `
  subscription OnUpdateAllowedUser(
    $filter: ModelSubscriptionAllowedUserFilterInput
  ) {
    onUpdateAllowedUser(filter: $filter) {
      id
      email
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteAllowedUser = /* GraphQL */ `
  subscription OnDeleteAllowedUser(
    $filter: ModelSubscriptionAllowedUserFilterInput
  ) {
    onDeleteAllowedUser(filter: $filter) {
      id
      email
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateCrewMember = /* GraphQL */ `
  subscription OnCreateCrewMember(
    $filter: ModelSubscriptionCrewMemberFilterInput
    $owner: String
  ) {
    onCreateCrewMember(filter: $filter, owner: $owner) {
      id
      userId
      crewId
      user {
        id
        email
        name
        avatar
        crews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        adminCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        favoriteCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userPreference {
          id
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          selectedEventId
          userIdFilter
          timeFilter
          grouping
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userPreferenceUserId
          owner
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserPreferenceId
        owner
        __typename
      }
      crew {
        id
        name
        avatar
        members {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        admins {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        favoritedByUsers {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateCrewMember = /* GraphQL */ `
  subscription OnUpdateCrewMember(
    $filter: ModelSubscriptionCrewMemberFilterInput
    $owner: String
  ) {
    onUpdateCrewMember(filter: $filter, owner: $owner) {
      id
      userId
      crewId
      user {
        id
        email
        name
        avatar
        crews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        adminCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        favoriteCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userPreference {
          id
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          selectedEventId
          userIdFilter
          timeFilter
          grouping
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userPreferenceUserId
          owner
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserPreferenceId
        owner
        __typename
      }
      crew {
        id
        name
        avatar
        members {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        admins {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        favoritedByUsers {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteCrewMember = /* GraphQL */ `
  subscription OnDeleteCrewMember(
    $filter: ModelSubscriptionCrewMemberFilterInput
    $owner: String
  ) {
    onDeleteCrewMember(filter: $filter, owner: $owner) {
      id
      userId
      crewId
      user {
        id
        email
        name
        avatar
        crews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        adminCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        favoriteCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userPreference {
          id
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          selectedEventId
          userIdFilter
          timeFilter
          grouping
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userPreferenceUserId
          owner
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserPreferenceId
        owner
        __typename
      }
      crew {
        id
        name
        avatar
        members {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        admins {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        favoritedByUsers {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateCrewAdmin = /* GraphQL */ `
  subscription OnCreateCrewAdmin(
    $filter: ModelSubscriptionCrewAdminFilterInput
    $owner: String
  ) {
    onCreateCrewAdmin(filter: $filter, owner: $owner) {
      id
      userId
      crewId
      user {
        id
        email
        name
        avatar
        crews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        adminCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        favoriteCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userPreference {
          id
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          selectedEventId
          userIdFilter
          timeFilter
          grouping
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userPreferenceUserId
          owner
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserPreferenceId
        owner
        __typename
      }
      crew {
        id
        name
        avatar
        members {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        admins {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        favoritedByUsers {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateCrewAdmin = /* GraphQL */ `
  subscription OnUpdateCrewAdmin(
    $filter: ModelSubscriptionCrewAdminFilterInput
    $owner: String
  ) {
    onUpdateCrewAdmin(filter: $filter, owner: $owner) {
      id
      userId
      crewId
      user {
        id
        email
        name
        avatar
        crews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        adminCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        favoriteCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userPreference {
          id
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          selectedEventId
          userIdFilter
          timeFilter
          grouping
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userPreferenceUserId
          owner
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserPreferenceId
        owner
        __typename
      }
      crew {
        id
        name
        avatar
        members {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        admins {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        favoritedByUsers {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteCrewAdmin = /* GraphQL */ `
  subscription OnDeleteCrewAdmin(
    $filter: ModelSubscriptionCrewAdminFilterInput
    $owner: String
  ) {
    onDeleteCrewAdmin(filter: $filter, owner: $owner) {
      id
      userId
      crewId
      user {
        id
        email
        name
        avatar
        crews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        adminCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        favoriteCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userPreference {
          id
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          selectedEventId
          userIdFilter
          timeFilter
          grouping
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userPreferenceUserId
          owner
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserPreferenceId
        owner
        __typename
      }
      crew {
        id
        name
        avatar
        members {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        admins {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        favoritedByUsers {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateFavoriteCrew = /* GraphQL */ `
  subscription OnCreateFavoriteCrew(
    $filter: ModelSubscriptionFavoriteCrewFilterInput
    $owner: String
  ) {
    onCreateFavoriteCrew(filter: $filter, owner: $owner) {
      id
      userId
      crewId
      user {
        id
        email
        name
        avatar
        crews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        adminCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        favoriteCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userPreference {
          id
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          selectedEventId
          userIdFilter
          timeFilter
          grouping
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userPreferenceUserId
          owner
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserPreferenceId
        owner
        __typename
      }
      crew {
        id
        name
        avatar
        members {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        admins {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        favoritedByUsers {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateFavoriteCrew = /* GraphQL */ `
  subscription OnUpdateFavoriteCrew(
    $filter: ModelSubscriptionFavoriteCrewFilterInput
    $owner: String
  ) {
    onUpdateFavoriteCrew(filter: $filter, owner: $owner) {
      id
      userId
      crewId
      user {
        id
        email
        name
        avatar
        crews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        adminCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        favoriteCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userPreference {
          id
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          selectedEventId
          userIdFilter
          timeFilter
          grouping
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userPreferenceUserId
          owner
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserPreferenceId
        owner
        __typename
      }
      crew {
        id
        name
        avatar
        members {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        admins {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        favoritedByUsers {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteFavoriteCrew = /* GraphQL */ `
  subscription OnDeleteFavoriteCrew(
    $filter: ModelSubscriptionFavoriteCrewFilterInput
    $owner: String
  ) {
    onDeleteFavoriteCrew(filter: $filter, owner: $owner) {
      id
      userId
      crewId
      user {
        id
        email
        name
        avatar
        crews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        adminCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        favoriteCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userPreference {
          id
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          selectedEventId
          userIdFilter
          timeFilter
          grouping
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userPreferenceUserId
          owner
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserPreferenceId
        owner
        __typename
      }
      crew {
        id
        name
        avatar
        members {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        admins {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        favoritedByUsers {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
