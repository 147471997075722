import { FilterAltOutlined } from '@mui/icons-material';
import { Badge, IconButton, Tooltip } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { SessionStore, UserStore } from '../../state';

interface CalendarFilterButtonProps {
    userStore: UserStore;
    sessionStore: SessionStore;
    onOpenFiltersDrawer: () => void;
}

const CalendarFilterButton = (props: CalendarFilterButtonProps) => {
    const isSettingsBadgeVisible = () => {
        return (props.userStore.selectedCrew ||
            props.sessionStore.userIdFilter);
    }

    const getBadgeContent = () => {
        let count = 0;
        if (props.userStore.selectedCrew) {
            count++;
        }
        if (props.sessionStore.userIdFilter) {
            count++;
        }
        return count;
    }
    return (
        <Tooltip title='Calendar Filters'>
            <IconButton
                size='large'
                edge='start'
                aria-label='calendar-filters-button'
                onClick={props.onOpenFiltersDrawer}
            >
                <Badge
                    color='primary'
                    badgeContent={getBadgeContent()}
                    invisible={!isSettingsBadgeVisible()}>
                    <FilterAltOutlined />
                </Badge>
            </IconButton>
        </Tooltip>
    );
};

export default observer(CalendarFilterButton);
