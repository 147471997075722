import {
    FormControl,
    Menu
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { EventStore } from '../../state';
import { AutoCompleteWithAvatars } from '../autoCompleteWithAvatars';

interface EventFilterProps {
    id: string;
    anchorEl?: Element | null;
    handleClose?: (event) => void;
    eventStore: EventStore;
}

const InnerDiv = observer((props: EventFilterProps) => {
    const handleSelectedEventChange = (e, value) => {
        props.eventStore.setSelectedEventId(value?.id.toString());
    }
    return (
        <FormControl style={{ width: '100%' }}>
            <AutoCompleteWithAvatars
                id='events-autocomplete-dropdown'
                label='Event'
                aria-label='events-autocomplete-dropdown'
                placeholder='Start Typing'
                multiple={false}
                items={props.eventStore.events}
                selectedItems={props.eventStore.selectedEvent}
                handleChange={handleSelectedEventChange}
                required={true}
            />
        </FormControl>);
});

const EventFilter = (props: EventFilterProps) => {
    return (
        props.anchorEl !== undefined ?
            <Menu
                id={props.id}
                anchorEl={props.anchorEl}
                keepMounted
                open={Boolean(props.anchorEl)}
                onClose={props.handleClose}
            >
                <InnerDiv {...props} />
            </Menu> :
            <InnerDiv {...props} />
    );
}

export default EventFilter;
