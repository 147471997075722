//import { Star, StarBorder } from '@mui/icons-material';
import { Chip, ImageList, ImageListItem, ImageListItemBar } from '@mui/material';
import { memo } from 'react';
import { Crew } from '../../models';

interface CrewImageListProps {
    crews: Crew[];
    onToggleFavorite: (crew: Crew) => void;
    onCrewClicked: (crew: Crew) => void;
}

const areCrewsEqual = (prevProps: CrewImageListProps, nextProps: CrewImageListProps): boolean => {
    const prevCrewIds = prevProps.crews.map(u => u.id);
    const nextCrewIds = nextProps.crews.map(u => u.id);
    const areCrewsEqual = prevProps.crews.length === nextProps.crews.length &&
        prevCrewIds.every(crewId => nextCrewIds.includes(crewId)) &&
        nextCrewIds.every(crewId => prevCrewIds.includes(crewId));
    return areCrewsEqual;
}
export const CrewImageList = memo((props: CrewImageListProps) => {
    const size = 116;
    // const onToggleFavorite = (e, crew: Crew) => {
    //     e.stopPropagation();
    //     props.onToggleFavorite(crew);
    // }
    return (
        <ImageList
            gap={12}
            sx={{
                margin: '5px 0px',
                gridAutoFlow: 'column',
                gridTemplateColumns: `repeat(minmax(${size}px,1fr)) !important`
                //gridAutoColumns: 'minmax(100px, 1fr)',
            }}
        >
            {props.crews.map((crew) => (
                <ImageListItem
                    key={crew.id}
                    style={{ minHeight: `${size}px`, minWidth: `${size}px`, marginBottom: '10px' }}
                    onClick={() => props.onCrewClicked(crew)}
                >
                    <img key={`${crew.id}-avatar`} style={{ minWidth: size, minHeight: size }}
                        alt={`Avatar image for crew ${crew.name}`}
                        src={crew.avatar} />
                    {/* <ImageListItemBar
                        key={`${crew.id}-favorite-icon`}
                        sx={{
                            background:
                                'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                                'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                        }}
                        // TODO: Uncomment when favoriting
                        // actionIcon={
                        //     <IconButton
                        //         sx={{ color: 'white' }}
                        //         aria-label={`star ${crew.name}`}
                        //     >
                        //         <StarBorder />
                        //     </IconButton>
                        // }
                        // actionPosition='left'
                        position='top'
                    /> */}
                    <ImageListItemBar
                        key={`${crew.id}-name`}
                        title={crew.name}
                        position='below'
                    />
                    {/* {crew.isAdmin &&
                        <ImageListItemBar
                            key={`${crew.id}-admin-indicator`}
                            position='bottom'
                            sx={{ backgroundColor: 'transparent' }}
                            actionIcon={
                                <Chip color='primary' label='Admin' />
                            }
                        >
                        </ImageListItemBar>
                    } */}
                </ImageListItem>
            ))}
        </ImageList >
    );

}, areCrewsEqual);


// interface CrewSingleRowProps {
//     crew: Crew;
// }

// const areCrewsEqual = (prevProps: CrewSingleRowProps, nextProps: CrewSingleRowProps): boolean => {
//     // TODO
//     return false;
// }

// const CrewSingleRow = memo((props: CrewSingleRowProps) => {
//     return (
//         <Paper sx={{ width: '100%' }}>
//             <div className='flex-row'>
//                 <div>{props.crew.name}</div>
//                 <AvatarsDiv
//                     id={`${props.crew.id}-members-avatars`}
//                     users={[...props.crew.admins, ...props.crew.members]}
//                     isSessionSelected={true}
//                     isSessionTooltipShownForQueryParam={false}
//                     onTooltipForSessionFromQueryParamsShown={() => { }}
//                     color='blue'
//                 />
//                 {/* users: User[];
//                 id: string | number | undefined;
//                 isSessionSelected: boolean;
//                 isSessionTooltipShownForQueryParam: boolean;
//     onTooltipForSessionFromQueryParamsShown: () => void;
//                 color: string | Color; */}
//             </div>
//         </Paper>
//     );
// }, areCrewsEqual);
