/* tslint:disable */
/* eslint-disable */
export const getCrewsByMemberId = /* GraphQL */ `
  query GetCrewsByMemberId($userId: ID!) {
    listCrewMembers(filter: { userId: { eq: $userId }, ,
                _deleted: {
                    ne: true
                } }) {
    items {
      id,
      crew {
        avatar
        id
        name
        _version
        _lastChangedAt
        members {
          items {
            user {
              avatar
              email
              id
              name
            }
          }
        }
        admins {
          items {
            user {
              avatar
              email
              id
              name
            }
          }
        }
      }
    }
  }
}
`;

export const getCrewsByAdminId = /* GraphQL */ `
  query GetCrewsByAdminId($userId: ID!) {
    listCrewAdmins(filter: { userId: { eq: $userId }, ,
                _deleted: {
                    ne: true
                } }) {
    items {
      id,
      crew {
        avatar
        id
        name
        _version
        _lastChangedAt
        members {
          items {
            user {
              avatar
              email
              id
              name
            }
          }
        }
        admins {
          items {
            user {
              avatar
              email
              id
              name
            }
          }
        }
      }
    }
  }
}
`;