import { makeAutoObservable } from 'mobx';
import { Subject, takeUntil, combineLatestWith, switchMap, ReplaySubject } from 'rxjs';
import { Crew, CrewResult, Message, Result, User } from '../models';
import { UserService } from '../services';
import { AppState } from './appState';

export class UserStore {
    currentUser: User | null | undefined = undefined;
    currentToken: any | null | undefined = undefined;
    errorMessage: string | undefined = undefined;
    myMemberCrews: Crew[] = [];
    myAdminCrews: Crew[] = [];
    selectedCrew: Crew | null = null;
    usersWithoutMe: User[] = [];
    allMyCrews: Crew[] = [];
    myFavoriteFriends: User[] = [];
    errors$ = new ReplaySubject<string>();
    private myFavoriteFriendIds: string[] = [];
    isUserAdmin: boolean | undefined = undefined;

    constructor(private userService: UserService, private appState: AppState, private stop$: Subject<boolean>) {
        makeAutoObservable(this, {
            sendMessage: false,
            toggleFriendAsFavorite: false,
            toggleFriendsAsFavorite: false,
            addAdminToCrew: false,
        });

        this.userService.currentToken$.pipe(
            takeUntil(this.stop$)
        ).subscribe(token => {
            if (!token) {
                this.appState.decrementWaitCounter();
            }
            this.setCurrentToken(token);
        });

        this.userService.currentUser$.pipe(
            takeUntil(this.stop$)
        ).subscribe(user => {
            this.setCurrentUser(user);
        });

        this.userService.isUserAdmin$.pipe(
            takeUntil(this.stop$)
        ).subscribe(this.setIsUserAdmin);

        this.userService.currentUser$.pipe(
            switchMap((currentUser) =>
                this.userService.myAdminCrews$.pipe(
                    combineLatestWith(this.userService.myMemberCrews$),
                    combineLatestWith(this.userService.myFavoriteFriends$)
                )), takeUntil(this.stop$))
            .subscribe(v => {
                console.log('userStore: There were changes to collections, updating...');
                const admins = v[0][0];
                const members = v[0][1];
                const favorites = v[1];
                this.setMyFavoriteFriends(favorites);
                this.setMyAdminCrews(admins);
                this.setMyMemberCrews(members);
                this.updateCalculatedCollections();
            });

        this.userService.errors$.pipe(
            takeUntil(this.stop$)
        ).subscribe(errorMessage => {
            this.setCurrentErrorMessage(errorMessage);
        });
    }

    login = () => {
        this.appState.incrementWaitCounter();
        this.userService.login();
    }

    createCrew = async (crewName: string, crewAvatar?: string): Promise<CrewResult> => {
        return await this.userService.createCrew(crewName, crewAvatar);
    }

    updateCrewName = async (crew: Crew, newName: string): Promise<Result> => {
        return await this.userService.updateCrewName(crew, newName);
    }

    private setCurrentUser = (user: User | null | undefined) => {
        if (user?.id === this.currentUser?.id) {
            return;
        }
        this.currentUser = user;
    }

    private setMyAdminCrews = (crews: Crew[]) => {
        this.myAdminCrews = crews;
    }

    private setMyMemberCrews = (crews: Crew[]) => {
        this.myMemberCrews = crews;
    }

    private setMyFavoriteFriends = (friends: User[]) => {
        this.myFavoriteFriends = friends;
        this.myFavoriteFriendIds = friends.map(f => f.id);
    }

    private updateCalculatedCollections = () => {
        const combinedCrews = [...this.myMemberCrews, ...this.myAdminCrews];
        const combinedUsers = combinedCrews.flatMap(crew => crew.members);
        const usersWithoutMe = [...new Map(combinedUsers.map((user: User) => [user.id, user])).values()]
            .filter(user => user.id !== this.currentUser?.id)
            .map(user => ({
                ...user,
                isFavorite: this.myFavoriteFriendIds.includes(user.id)
            }))
            .sort(this.sortUsers);
        const uniqueCrewsWithFavoriteUsers = [...new Map(combinedCrews.map((crew: Crew) => [crew.id, crew])).values()]
            // Replace the users under crew members and admins with a list that contains whether they are user favorites
            .map(crew => ({
                ...crew,
                members: crew.members.map(m => usersWithoutMe.find(u => u.id === m.id) || m).sort(this.sortUsers),
                admins: crew.admins.map(a => usersWithoutMe.find(u => u.id === a.id) || a).sort(this.sortUsers),
            })).sort(this.sortCrews);

        this.setAllMyCrews(uniqueCrewsWithFavoriteUsers);
        this.setUsersWithoutMe(usersWithoutMe);
    }

    private setUsersWithoutMe = (users: User[]) => {
        this.usersWithoutMe = users;
    }

    private setAllMyCrews = (crews: Crew[]) => {
        this.allMyCrews = crews;
    }

    sendMessage = (message: Message) => {
        this.userService.sendMessage(message);
    }

    setSelectedCrewId = (crewId: string | number | null) => {
        const foundCrew = this.allMyCrews.find(crew => crew.id === crewId);
        if (foundCrew) {
            this.selectedCrew = foundCrew;
        }
    }

    toggleFriendAsFavorite = (friend: User) => {
        this.userService.toggleFriendAsFavorite(friend.id);
        // Updating the state while waiting for the service so there is no delay for the user when toggling favorites
        const foundFriend = this.usersWithoutMe.find(u => u.id === friend.id);
        const indexOfFoundFriend = this.usersWithoutMe.indexOf(foundFriend!);
        const newUsers = [...this.usersWithoutMe];
        newUsers[indexOfFoundFriend] = {
            ...foundFriend!,
            isFavorite: !foundFriend!.isFavorite
        };
        this.setUsersWithoutMe(newUsers);
    }

    toggleFriendsAsFavorite = (friendIds: string[]) => {
        this.userService.toggleFriendsAsFavorite(friendIds);
    }

    addAdminToCrew = (crew: Crew, adminUserId: string) => {
        this.userService.addAdminToCrew(crew, adminUserId);
    }

    joinCrew = async (code: string): Promise<Result | undefined> => {
        return this.userService.joinCrewWithCode(code);
    }

    updateUser = async (user: User): Promise<Result | undefined> => {
        return this.userService.updateUser(user);
    }

    private sortUsers = (userA: User, userB: User) => {
        if (userA.isFavorite && !userB.isFavorite) {
            return -1;
        } else if (!userA.isFavorite && userB.isFavorite) {
            return 1;
        } else {
            return (userA.name && userB.name) ? userA.name > userB.name ? 1 : -1 : -1;
        }
    }

    private sortCrews = (crewA: Crew, crewB: Crew) => {
        if (crewA.isAdmin && !crewB.isAdmin) {
            return -1;
        } else if (!crewA.isAdmin && !crewB.isAdmin) {
            return 1;
        } else {
            return (crewA.name && crewB.name) ? crewA.name > crewB.name ? 1 : -1 : -1;
        }
    }

    private setCurrentToken = (token: any | null | undefined) => {
        this.currentToken = token;
    }
    private setCurrentErrorMessage = (errorMessage: string | undefined) => {
        this.errorMessage = errorMessage;
    }

    private setIsUserAdmin = (isAdmin: boolean) => {
        this.isUserAdmin = isAdmin;
    }
}
