import { AccessTimeOutlined, ChevronLeft, EventOutlined, LocationOnOutlined } from '@mui/icons-material';
import { Autocomplete, AutocompleteChangeReason, Card, Divider, Drawer, IconButton, TextField, Typography, useTheme } from '@mui/material';
import { observer } from 'mobx-react';
import { SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { AppState, SessionStore } from '../../state';
import './SessionSearch.scss';
import { Session } from '../../models';

interface SessionSearchProps {
    sessionStore: SessionStore;
    appState: AppState;
}

const SessionSearch = (props: SessionSearchProps) => {
    const [selectedSession, setSelectedSession] = useState<Session | null>(null);

    const textColor = useTheme().palette.text.secondary;
    const onCloseDrawer = useCallback(() => props.appState.setIsSessionSearchDrawerOpen(false), [props.appState.isSessionSearchDrawerOpen]);

    const handleChange = useCallback((e: SyntheticEvent<Element, Event>, session: Session | null, reason: AutocompleteChangeReason, details?: any) => {
        if (selectedSession?.id === session?.id) {
            return;
        }
        e.stopPropagation();
        props.appState.setIsSessionTooltipShownForQueryParam(false);
        setSelectedSession(selectedSession);
        props.sessionStore.setSessionId(session?.id || undefined);
        onCloseDrawer();
    }, [props.sessionStore.allSessions, selectedSession]);

    const sortedSessions = useMemo(() => props.sessionStore.allSessions.slice().sort((a, b) => (a.name.localeCompare(b.name))), [props.sessionStore.allSessions]);

    const isOptionEqualToValue = useCallback((option: Session, value: Session) => {
        return option.id === value.id;
    }, []);

    return (
        <Drawer
            sx={{
                minWidth: '240px',
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    minWidth: '240px',
                    boxSizing: 'border-box',
                },
            }}
            ModalProps={{ onBackdropClick: onCloseDrawer }}
            anchor='left'
            open={props.appState.isSessionSearchDrawerOpen}
            onClose={onCloseDrawer}
        >
            <div>
                <div id='session-search-drawer-header'
                    className='flex-row'
                    style={{ padding: '10px 10px 10px 20px', alignItems: 'center', justifyContent: 'center' }}
                >
                    <Typography
                        sx={{ flex: '2' }}
                        color={textColor}
                        fontSize='1.1rem'
                        fontWeight='bold'>
                        Search for Session
                    </Typography>
                    <IconButton
                        style={{ marginLeft: '5px' }}
                        color='default'
                        onClick={onCloseDrawer}>
                        <ChevronLeft />
                    </IconButton>
                </div>
                <Divider />
                <Autocomplete
                    sx={{ padding: '20px', minWidth: '300px', flex: '2' }}
                    id='session-search-autocomplete'
                    options={sortedSessions}
                    value={selectedSession}
                    blurOnSelect={true}
                    getOptionLabel={useCallback((item) => item?.name || '', [])}
                    isOptionEqualToValue={isOptionEqualToValue}
                    onChange={handleChange}
                    renderInput={useCallback((params) => <TextField {...params} label={`\u2002Session`} />, [])}
                    renderOption={useCallback((props, session) => (
                        <Card {...props} key={session.id} style={{ backgroundColor: '#272537', margin: '10px 0px', padding: '10px' }}>
                            <div className='flex-column'>
                                <Typography sx={{ marginBottom: '2px', fontSize: '1.05rem', fontWeight: 'bold' }}>{session.name}</Typography>
                                <div className='flex-row'>
                                    <EventOutlined className='session-search-icon' />
                                    <Typography className='session-search-typography'>
                                        {session.startDate.toLocaleDateString('en-us', { weekday: 'short', month: 'short', day: 'numeric' })}
                                    </Typography>
                                </div>
                                <div className='flex-row'>
                                    <AccessTimeOutlined className='session-search-icon' />
                                    <Typography className='session-search-typography'>
                                        {session.startDate.toLocaleString([], { timeStyle: 'short' })} - {session.endDate.toLocaleString([], { timeStyle: 'short' })}</Typography>
                                </div>
                                <div className='flex-row'>
                                    <LocationOnOutlined className='session-search-icon' />
                                    <Typography className='session-search-typography'>{session.location.name}</Typography>
                                </div>
                            </div>
                        </Card>
                    ), [props.sessionStore.allSessions])}
                    renderTags={useCallback((tagValue, getTagProps) =>
                        tagValue.map((item, index) => (
                            <div key={`selected-item${item.id}`} style={{ margin: '3px' }}>
                                <Typography sx={{ margin: '10px' }}>{item.name}</Typography>
                            </div>
                        )), [])}
                    ListboxProps={{ style: { minHeight: '500px' } }}
                />
            </div>
        </Drawer>
    );
};

export default observer(SessionSearch);
