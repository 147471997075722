import { observer } from 'mobx-react-lite';
import { UserStore } from '../../state';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import './Login.scss';
import { Typography } from '@mui/material';

interface LoginProps {
    userStore: UserStore;
}

const Login = (props: LoginProps) => {
    // const history = useHistory();
    // Hub.listen('auth', (data) => {
    //     switch (data.payload.event) {
    //         case 'signUp':
    //             {
    //                 console.log('New sign up');
    //                 history.push('/profile');
    //                 break;
    //             }
    //     }
    // });
    // const { signIn } = useGoogleLogin({
    //     onSuccess: props.userStore.onLoginSuccess,
    //     onFailure: props.userStore.onLoginFailure,
    //     clientId: props.userStore.clientId,
    //     isSignedIn: false,
    //     accessType: 'offline'
    // });
    // // TODO: Uncomment
    // // if (props.userStore.currentUser !== undefined) {
    // //     console.log('User already logged in, redirecting to /calendar');
    // //     return (<Redirect to='/calendar'></Redirect>);
    // // }
    // console.log('User not logged in yet');
    const handleLogin = () => {
        props.userStore.login();
    }

    return (
        <div className='flex-column'
            style={{
                backgroundImage: `url(${process.env.PUBLIC_URL}/festival_2796_1290.jpg)`,
                justifyContent: 'space-evenly',
                backgroundSize: 'cover',
                height: '100vh'
            }}>

            {/* <div className='flex-column' style={{ alignItems: 'center' }}>
                <img src='./logo.png' alt='logo' width='400px'></img>
            </div> */}
            {/* <div style={styles.container}>
                <Heading level={1}>Hello {user.username}</Heading>
                <Button onClick={signOut}>Sign out</Button>
            </div> */}

            {/* <div className='flex-row' style={{ width: '100%', padding: '30px', justifyContent: 'center' }}>
                <Button onClick={handleLogin}>
                    <img src='./btn_google_signin_dark_normal_web@2x.png' alt='Google Sign In button'
                        className='googleSignIn'
                        style={{ height: '45px', width: '190px' }} />
                </Button>
            </div>
             */}
            <div style={{ marginTop: '200px', padding: '10px' }}>
                <Authenticator>
                    {({ signOut, user }) => (
                        <main>
                            {/* <h1>Hello {user?.username}</h1>
                            <button onClick={signOut}>Sign out</button> */}
                        </main>
                    )}
                </Authenticator>
            </div>
            {props.userStore.errorMessage &&
                <Typography textAlign={'center'} sx={{ backgroundColor: 'error.dark', margin: '6px 20px', padding: '5px' }}>{props.userStore.errorMessage}</Typography>
            }
            {/* <GoogleLogin
                clientId={props.userStore.clientId}
                buttonText='Login'
                onSuccess={props.userStore.onLoginSuccess}
                onFailure={props.userStore.onLoginFailure}
                cookiePolicy='single_host_origin'
                isSignedIn={true}
            /> */}
        </div>
    )
};

export default observer((Login));
