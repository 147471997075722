import { Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { Result, Session, User, UserSession } from '../models';
import { EventService, UserService } from './';
import { createUserSession, deleteUserSession, getSessionsForEventId, getUserSessionsForEventId } from './graphqlOperations';
import { AppState } from '../state';
import { API, graphqlOperation } from 'aws-amplify';
import * as subscriptions from '../graphql/subscriptions';

export class SessionService {
    private allUserSessionsSubject$ = new ReplaySubject<UserSession[]>();
    private allSessionsSubject$ = new ReplaySubject<Session[]>();
    private currentUser: User | null | undefined;
    private selectedEventId: string | null = null;
    private sessionsChangesSubscriptions$: Subscription[] = [];
    private userSessionsChangesSubscriptions$: Subscription[] = [];
    private isJoiningOrLeaving$ = new ReplaySubject<boolean>();

    constructor(private appState: AppState, private userService: UserService, private eventService: EventService, private stop$: Subject<boolean>) {
        this.userService.currentUser$.subscribe(user => {
            this.currentUser = user;
            this.setupAndFetch();
        });
        this.eventService.selectedEventId$.subscribe(selectedEventId => {
            this.selectedEventId = selectedEventId;
            this.setupAndFetch();
        });
        // TODO
        // Is this needed?
        // this.userService.crewChanges$.subscribe(() => {
        //     this.setupAndFetch();
        // });
    }

    get shouldWait$(): Observable<boolean> {
        return this.isJoiningOrLeaving$;
    };

    private setupAndFetch = () => {
        if (this.currentUser && this.selectedEventId) {
            this.setupSessionsSubscriptionsForEvent(this.selectedEventId);
            this.setupUserSessionsSubscriptionsForEvent(this.selectedEventId);
            this.fetchSessions();
            this.fetchUserSessions();
        }
    }

    private delay = ms => new Promise(res => setTimeout(res, ms));

    private fetchSessions = async (shouldSpin: boolean = true) => {
        if (this.currentUser && this.selectedEventId) {
            try {
                if (shouldSpin) {
                    this.appState.incrementWaitCounter();
                }
                const allSessions = await getSessionsForEventId(this.selectedEventId);
                // await this.delay(5000);
                this.allSessionsSubject$.next(allSessions);
            } catch (err) {
                console.log(err);
            } finally {
                if (shouldSpin) {
                    this.appState.decrementWaitCounter();
                }
            }
        }
    }

    private fetchUserSessions = async (shouldSpin: boolean = true) => {
        if (this.currentUser && this.selectedEventId) {
            try {
                if (shouldSpin) {
                    this.appState.incrementWaitCounter();
                }
                const allUserSessions = await getUserSessionsForEventId(this.selectedEventId);
                // await this.delay(5000);
                this.allUserSessionsSubject$.next(allUserSessions);
            } catch (err) {
                console.log(err);
            } finally {
                if (shouldSpin) {
                    this.appState.decrementWaitCounter();
                }
                this.isJoiningOrLeaving$.next(false);
            }
        }
    }

    get allUserSessions$(): Observable<UserSession[]> {
        return this.allUserSessionsSubject$;
    };

    get allSessions$(): Observable<Session[]> {
        return this.allSessionsSubject$;
    };

    joinSession = async (sessionId: string): Promise<Result | undefined> => {
        if (!this.selectedEventId) {
            return;
        }
        try {
            this.isJoiningOrLeaving$.next(true);
            const createdUserSession = await createUserSession(this.selectedEventId, sessionId, this.currentUser!.id);
            if (!createdUserSession) {
                return {
                    message: 'Could not join session',
                    status: 'error'
                }
            } else {
                return {
                    message: 'Added to session',
                    status: 'success'
                }
            }
        } catch (err) {
            console.log(err);
            return {
                message: JSON.stringify(err),
                status: 'error'
            };
        }
    }

    leaveSession = async (userSession: UserSession): Promise<Result> => {
        try {
            this.isJoiningOrLeaving$.next(true);
            const deletedUserSession = await deleteUserSession(userSession.id, userSession._version);
            if (!deletedUserSession) {
                return {
                    message: 'Could not leave session',
                    status: 'error'
                }
            } else {
                return {
                    message: 'Left session',
                    status: 'success'
                }
            }
        } catch (err) {
            console.log(err);
            return {
                message: JSON.stringify(err),
                status: 'error'
            };
        }
    }

    private handleSessionChanges(provider: any, value: any) {
        console.log('Sessions for this event changed, server is sending new values...');
        this.fetchSessions(false);
        this.fetchUserSessions(false);
    }

    private handleUserSessionChanges(provider: any, value: any) {
        console.log('User sessions for this event changed, server is sending new values...');
        this.fetchUserSessions(false);
    }

    private setupSessionsSubscriptionsForEvent = (eventId: string) => {
        this.sessionsChangesSubscriptions$.forEach(s => s.unsubscribe);
        this.sessionsChangesSubscriptions$ = [];

        this.sessionsChangesSubscriptions$.push((API.graphql(graphqlOperation(
            subscriptions.onCreateSession, {
            filter: {
                eventId: {
                    eq: eventId
                }
            }
        }
        )) as unknown as Observable<any>)
            .subscribe({
                next: ({ provider, value }) => this.handleSessionChanges(provider, value),
                error: (error) => console.warn(error)
            }));
        this.sessionsChangesSubscriptions$.push((API.graphql(graphqlOperation(subscriptions.onUpdateSession, {
            filter: {
                eventId: {
                    eq: eventId
                }
            }
        })) as unknown as Observable<any>)
            .subscribe({
                next: ({ provider, value }) => this.handleSessionChanges(provider, value),
                error: (error) => console.warn(error)
            }));
        this.sessionsChangesSubscriptions$.push((API.graphql(graphqlOperation(subscriptions.onDeleteSession, {
            filter: {
                eventId: {
                    eq: eventId
                }
            }
        })) as unknown as Observable<any>)
            .subscribe({
                next: ({ provider, value }) => this.handleSessionChanges(provider, value),
                error: (error) => console.warn(error)
            }));
    }

    private setupUserSessionsSubscriptionsForEvent = (eventId: string) => {
        this.userSessionsChangesSubscriptions$.forEach(s => s.unsubscribe);
        this.userSessionsChangesSubscriptions$ = [];

        this.userSessionsChangesSubscriptions$.push((API.graphql(graphqlOperation(
            subscriptions.onCreateUserSession, {
            filter: {
                eventId: {
                    eq: eventId
                }
            }
        }
        )) as unknown as Observable<any>)
            .subscribe({
                next: ({ provider, value }) => this.handleUserSessionChanges(provider, value),
                error: (error) => console.warn(error)
            }));
        this.userSessionsChangesSubscriptions$.push((API.graphql(graphqlOperation(subscriptions.onUpdateUserSession, {
            filter: {
                eventId: {
                    eq: eventId
                }
            }
        })) as unknown as Observable<any>)
            .subscribe({
                next: ({ provider, value }) => this.handleUserSessionChanges(provider, value),
                error: (error) => console.warn(error)
            }));
        this.userSessionsChangesSubscriptions$.push((API.graphql(graphqlOperation(subscriptions.onDeleteUserSession, {
            filter: {
                eventId: {
                    eq: eventId
                }
            }
        })) as unknown as Observable<any>)
            .subscribe({
                next: ({ provider, value }) => this.handleUserSessionChanges(provider, value),
                error: (error) => console.warn(error)
            }));
    }

    // TEST
    // private test = (currentUser: User) => {
    //     zip(this.userService.myMemberCrews$,
    //         this.allSessions$).subscribe(observer => {
    //             const allSessions = observer[1];
    //             this.setupOtherUserSessions(users, allSessions, currentUser);
    //             // setInterval(() => {
    //             //     this.setupOtherUserSessions(users, allSessions, currentUser);
    //             // }, 5000);
    //         });
    // }

    // private setupOtherUserSessions = (users: User[], allSessions: Session[], currentUser: User) => {
    //     this.otherUsersSessions = [];
    //     allSessions.forEach(session => {
    //         const sessionUsers = this.getRandomUsers(users).filter(u => u.id !== currentUser.id);
    //         sessionUsers.forEach(user => {
    //             const userSession: UserSession = {
    //                 userID: user.id,
    //                 sessionID: session.id,
    //                 id: `${session.id}${user.id}`
    //             };
    //             this.otherUsersSessions.push(userSession);
    //         })
    //     });
    //     this.userSessionsSubject$.next([...this.mySessions, ...this.otherUsersSessions]);
    // }

    // private getRandomUsers = (users: User[]): User[] => {
    //     let arr: number[] = [];
    //     for (let i = 0; i < 50; i++) {
    //         const secondRandomNum = Math.floor(Math.random() * users.length);
    //         if (arr.indexOf(secondRandomNum) === -1) {
    //             arr.push(secondRandomNum);
    //         }
    //     }
    //     return arr.map(num => users[num])
    //         .sort((userA, userB) => userA.name! > userB.name! ? 1 : -1);
    // }
}
