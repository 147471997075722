import { observer } from 'mobx-react-lite';
import { UserStore } from '../../state';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Alert, AlertColor, Avatar, Button, Snackbar, TextField } from '@mui/material';
import { useState } from 'react';
import { getInitials } from '../../util';

interface ProfilePageProps {
    userStore: UserStore;
}

const ProfilePage = (props: ProfilePageProps) => {
    const [userName, setUserName] = useState<string | undefined>(props.userStore.currentUser?.name || '');
    const [avatarUrl, setAvatarUrl] = useState<string | undefined>(props.userStore.currentUser?.avatar || '');
    const [isToastOpen, setIsToastOpen] = useState<boolean>(false);
    const [toastMessage, setToastMessage] = useState<string>('');
    const [toastSeverity, setToastSeverity] = useState<AlertColor>('success');
    const bottomAppBarHeight = 56;
    const titleHeight = 55;

    const handleToastClose = () => {
        setIsToastOpen(false);
    }

    const save = async () => {
        const response = await props.userStore.updateUser({
            id: props.userStore.currentUser!.id,
            email: props.userStore.currentUser!.email,
            name: userName,
            avatar: avatarUrl,
            _version: props.userStore.currentUser!._version + 1,
            _lastChangedAt: Date.now()
        });
        if (response) {
            setIsToastOpen(true);
            setToastMessage(response.message);
            setToastSeverity(response.status as AlertColor);
        }
    }

    const cancel = () => {
        setUserName(props.userStore.currentUser?.name);
        setAvatarUrl(props.userStore.currentUser?.avatar);
    }

    return (
        <>
            {!props.userStore.currentUser
                ? <div>You are not logged in. Please refresh the page and try again.</div>
                :
                <div className='flex-column' style={{ height: `calc(var(--app-height) - ${bottomAppBarHeight + titleHeight}px)` }} >
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                        open={isToastOpen}
                        autoHideDuration={4000}
                        onClose={handleToastClose}
                        style={{ top: 'calc(var(--app-height)/2)' }}
                    >
                        <Alert
                            onClose={handleToastClose}
                            severity={toastSeverity}
                            sx={{ boxShadow: 10, opacity: 1, fontSize: '1.2rem', padding: '20px' }}>
                            {toastMessage}
                        </Alert>
                    </Snackbar>
                    <div style={{ padding: '10px 20px' }}>
                        {/* <Paper sx={{ padding: '10px' }}> */}
                        <div className='flex-row' style={{ flexWrap: 'wrap', width: '100%' }}>
                            <div className='flex-row' style={{ gap: '10px', padding: '20px 10px', width: '100%' }}>
                                {props.userStore.currentUser?.avatar ?
                                    <Avatar src={props.userStore.currentUser!.avatar}
                                        imgProps={{ referrerPolicy: 'no-referrer' }}
                                        sx={{
                                            height: 64,
                                            width: 64
                                        }} />
                                    :
                                    <Avatar
                                        sx={{
                                            height: 64,
                                            width: 64
                                        }}>{getInitials(props.userStore.currentUser.name)}</Avatar>
                                }
                                <TextField
                                    value={userName}
                                    style={{ margin: '10px', flex: '1' }}
                                    onChange={(event) => setUserName(event.target.value)}
                                    label='User Name'
                                />
                            </div>
                            <div className='flex-row' style={{ width: '100%', flex: '1', gap: '15px' }}>
                                <Button
                                    onClick={save}
                                    style={{ flex: '1' }}
                                    variant='contained'
                                    color='success'>
                                    Save
                                </Button>
                                <Button
                                    onClick={cancel}
                                    style={{ flex: '1' }}
                                    variant='outlined'
                                    color='info'>
                                    Cancel
                                </Button>
                            </div>
                        </div>
                        {/* </Paper> */}
                    </div>
                    <div>
                        <Authenticator>
                            {({ signOut, user }) => (
                                <main>
                                    <div className='flex-row buttons-container' style={{ padding: '20px' }}>
                                        <Button className='action-button' onClick={signOut}>Sign out</Button>
                                    </div>
                                </main>
                            )}
                        </Authenticator>
                    </div>
                </div>
            }
        </>
    );
};

export default observer((ProfilePage));
