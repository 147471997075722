/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:45a25ca1-696b-4ee1-acb8-76b7b03a285f",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_04RrGUEWy",
    "aws_user_pools_web_client_id": "1rv478n43jd4augd0c0esuid1a",
    "oauth": {
        "domain": "crewcal-dev.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://localhost:3000/,http://localhost:3000/,https://crewcal.link/,https://crewcal.app/,https://www.crewcal.app/,https://www.crewcal.link/",
        "redirectSignOut": "https://localhost:3000/,http://localhost:3000/,https://crewcal.app/,https://crewcal.link/,https://www.crewcal.app/,https://www.crewcal.link/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://ymjs6gfuafe4hku7xlu7udwdne.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
