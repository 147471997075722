import {
    FormControl,
    FormControlLabel, Menu, Radio, RadioGroup
} from '@mui/material';
import { observer } from 'mobx-react';
import { SessionStore } from '../../state';
import './ScheduleDisplaySelector.scss';

interface ScheduleDisplaySelectorProps {
    id: string;
    anchorEl?: Element | null;
    handleClose?: (event) => void;
    sessionStore: SessionStore,
    selectedTimeType?: string;
}

const InnerDiv = observer((props: ScheduleDisplaySelectorProps) => {
    const handleChange = (event: any) => {
        const selection = event.target.value;
        props.sessionStore.setGrouping(selection);
        if (props.handleClose) {
            props.handleClose(null);
        }
    }
    return (
        <FormControl>
            <RadioGroup
                name='controlled-radio-buttons-group'
                value={props.sessionStore.grouping}
                onChange={handleChange}
            >
                <FormControlLabel value='location' control={<Radio />} label='Default (sessions grouped by locations)' />
                <FormControlLabel value='user' control={<Radio />} label='My Day View (your sessions grouped by day)' />
            </RadioGroup>
        </FormControl>
    );
});

const ScheduleDisplaySelector = (props: ScheduleDisplaySelectorProps) => {
    return (
        props.anchorEl ?
            <Menu
                id={props.id}
                anchorEl={props.anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(props.anchorEl)}
                onClose={props.handleClose}
            >
                <InnerDiv {...props} />
            </Menu>
            :
            <InnerDiv {...props} />
    );
}

export default ScheduleDisplaySelector;
