import {
  AppointmentModel,
  GroupingState,
  IntegratedGrouping,
  Resource,
  ViewState
} from '@devexpress/dx-react-scheduler';
import {
  Appointments,
  AppointmentTooltip, CurrentTimeIndicator, DateNavigator, DayView,
  GroupingPanel, Resources, Scheduler as DXScheduler, TodayButton, Toolbar
} from '@devexpress/dx-react-scheduler-material-ui';
import { CssBaseline, IconButton, ThemeProvider } from '@material-ui/core';
import { CalendarToday } from '@material-ui/icons';
import { SearchOutlined, SettingsOutlined } from '@mui/icons-material';
import { IconButton as IconButtonMui, TableContainer, Tooltip, Typography, colors } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { Fragment, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Location, Session, User } from '../../models';
import { AppState, EventStore, SessionStore, TimeFilterType, UserStore } from '../../state';
import { AppointmentCard } from '../appointment';
import { AppointmentTooltipCard } from '../appointmentTooltip';
import { CalendarFilterButton } from '../calendarFilterButton';
import './Scheduler.scss';

interface SchedulerProps {
  eventStore: EventStore;
  sessionStore: SessionStore;
  userStore: UserStore;
  appState: AppState;
  onOpenSettingsDrawer: () => void;
  onOpenFiltersDrawer: () => void;
  onOpenSessionSearchDrawer: () => void;
}

const {
  red, pink, purple, deepPurple, indigo, blue, lightBlue, cyan, teal,
  green, lightGreen, lime, yellow, amber, orange, deepOrange,
} = colors;

const DEFAULT_PALETTE = [
  red, pink, purple, deepPurple, indigo, blue, lightBlue, cyan, teal,
  green, lightGreen, lime, yellow, amber, orange, deepOrange,
];

const LEVEL = 300;
const BOTTOM_APP_BAR_HEIGHT = 56;

const groupByDate = viewName => viewName === 'Week' || viewName === 'Day';

const getAppointments = (sessions: Session[], sessionStore: SessionStore, currentUser: User): AppointmentModel[] => {
  const mySessions = sessionStore.getUserSessions(currentUser.id);
  const sessionsAsAppointments = sessions.map(session => (
    {
      title: session.name,
      startDate: session.startDate,
      endDate: session.endDate,
      id: session.id,
      location: session.location.id,
      user: 'Others'
    }));
  mySessions.forEach(mySession => {
    const session = sessionsAsAppointments.find(s => s.id === mySession.id);
    if (session) {
      session.user = currentUser.id;
    }
  });
  return sessionsAsAppointments;
}
const getResources = (locations: Location[], currentUser: User): Resource[] => {
  return [
    {
      fieldName: 'location',
      title: 'Location',
      instances:
        locations.map((l, i) => {
          return {
            id: l.id,
            text: l.name,
            color: DEFAULT_PALETTE[i]
          }
        })
    },
    {
      fieldName: 'user',
      title: 'User',
      //allowMultiple: true,
      instances: [
        {
          id: currentUser.id,
          text: currentUser.name
        },
        {
          id: 'Others',
          text: 'Others'
        }
      ]
    }
  ];
}

interface AppointmentTooltipLayoutParentProps {
  appState: AppState;
  layoutProps: AppointmentTooltip.LayoutProps;
}

const AppointmentTooltipLayoutParent = observer((props: AppointmentTooltipLayoutParentProps) => {
  return (
    <AppointmentTooltip.Layout {...props.layoutProps}
      visible={props.appState.isAppointmentTooltipVisible}
    />
  );
});

const Scheduler = (schedulerProps: SchedulerProps) => {
  const [currentDate, setCurrentDate] = useState<Date>(new Date());

  useEffect(() => {
    console.log('Scheduler created');
    return () => {
      console.debug('Scheduler unmounting');
    }
  }, []);

  const getSessionFromQueryParam = useCallback((sessionId): Session | undefined => {
    if (!sessionId) {
      return undefined;
    }
    return schedulerProps.sessionStore.allSessions.find(s => s.id.toString() === schedulerProps.sessionStore.sessionId);
  }, [schedulerProps.sessionStore.sessionId, schedulerProps.sessionStore.allSessions]);

  useEffect(() => {
    if (schedulerProps.sessionStore.sessionId) {
      const session = getSessionFromQueryParam(schedulerProps.sessionStore.sessionId);
      if (session) {
        schedulerProps.eventStore.setSelectedEventId(session.event.id.toString());
        if (currentDate !== session.startDate) {
          setCurrentDate(session.startDate);
        }
      }
    } else if (schedulerProps.eventStore.selectedEvent && schedulerProps.eventStore.selectedEvent.startDate > currentDate
      && currentDate !== schedulerProps.eventStore.selectedEvent.startDate) {
      setCurrentDate(schedulerProps.eventStore.selectedEvent.startDate);
    }
  }, [schedulerProps.sessionStore.sessionId, schedulerProps.appState.isSessionTooltipShownForQueryParam, schedulerProps.eventStore.selectedEventId, schedulerProps.sessionStore.allSessions]);

  const handleOnCurrentDateChange = useCallback((date: Date) => {
    if (date !== currentDate) {
      setCurrentDate(date);
    }
  }, [currentDate]);

  const getStartDayHour = useCallback((): number => {
    if (schedulerProps.sessionStore.timeFilter === TimeFilterType.TwentyFourHours) {
      return 0;
    }
    const firstEventDate = schedulerProps.sessionStore.getDateOfFirstSession(currentDate);
    let startHour = 24;
    if (firstEventDate) {
      if (firstEventDate.toLocaleDateString() === currentDate.toLocaleDateString()) {
        startHour = firstEventDate.getHours();
      } else {
        startHour = currentDate.getHours();
      }
    }
    if (new Date().toLocaleDateString() === currentDate.toLocaleDateString()) {
      return Math.min(currentDate.getHours(), firstEventDate ? startHour : 9);
    }
    return firstEventDate ? startHour : 9;
  }, [schedulerProps.sessionStore.timeFilter, schedulerProps.sessionStore.sessions, currentDate]);

  const getEndDayHour = useCallback((startDayHour: number): number => {
    if (schedulerProps.sessionStore.timeFilter === TimeFilterType.TwentyFourHours) {
      return 24;
    }
    const endDateOfLastEvent = schedulerProps.sessionStore.getDateOfLastSession(currentDate);
    let endHour = 24;
    if (endDateOfLastEvent) {
      if (endDateOfLastEvent.toLocaleDateString() === currentDate.toLocaleDateString()) {
        endHour = endDateOfLastEvent.getHours();
      } else {
        // TODO TEST ADD MORE THAN 24 HRS
        // endHour = endDateOfLastEvent.getHours() + 24;
        endHour = 23;
      }
    }
    if (new Date().toLocaleDateString() === currentDate.toLocaleDateString()) {
      return Math.max(startDayHour + 1, Math.min(24, endDateOfLastEvent ? endHour + 1 : currentDate.getHours() + 5));
      //return Math.max(startDayHour + 1, endDateOfLastEvent ? endHour + 1 : currentDate.getHours() + 5);
    }
    return Math.min(24, endDateOfLastEvent ? endHour + 1 : startDayHour + 1);
  }, [schedulerProps.sessionStore.timeFilter, schedulerProps.sessionStore.sessions, currentDate])

  const onAppointmentTooltipClose = useCallback(() => schedulerProps.appState.setIsAppointmentTooltipVisible(false), [schedulerProps.appState]);

  const getAppointmentTooltipContentComponent = useCallback((props: AppointmentTooltip.ContentProps) => (
    <AppointmentTooltipCard
      contentProps={props}
      appState={schedulerProps.appState}
      userStore={schedulerProps.userStore}
      sessionStore={schedulerProps.sessionStore}
      onClose={onAppointmentTooltipClose} />
  ), []);

  const appointmentTooltipHeader = useMemo(() => (props: AppointmentTooltip.HeaderProps, { }) =>
  (<AppointmentTooltip.Header {...props}>
    <div style={{ marginTop: '22px', backgroundColor: '#272537' }}></div>
    {props.children}
  </AppointmentTooltip.Header>), []);

  const openButtonComponent = useMemo(() => (props: DateNavigator.OpenButtonProps, { }) => {
    return (
      <IconButton
        onClick={props.onVisibilityToggle}
      >
        <CalendarToday />
      </IconButton>
    );
  }, []);

  const dateNavigatorRootComponent = useMemo(() => (props: DateNavigator.RootProps, { }) => {
    const date = new Date(props.navigatorText || '');
    return (
      <div id='scheduler-top-date-picker-div' className='flex-row'
        style={{
          //position: 'sticky', left: '80px', 
          alignItems: 'center',
        }}
      >
        <Typography
          alignContent='center'
          color={
            currentDate.toLocaleDateString() === new Date().toLocaleDateString() ?
              'primary' :
              'default'
          }
          style={{ fontWeight: 'bold', marginLeft: '6px' }}>
          {`${date.toLocaleDateString([], { weekday: 'short' })} ${date.toLocaleString([], { month: 'short' })} ${date.getDate()}`}
        </Typography>
        <DateNavigator.Root {...props} />
      </div>
    )
  }, [currentDate]);

  const toolbarRootComponent = useMemo(() => (props: Toolbar.RootProps, { style }) => {
    return (
      <Toolbar.Root {...props} style={{ ...style, minHeight: '56px' }}>
        <div className='flex-row'
          style={{
            position: 'fixed',
            left: '8px',
            gap: '2px'
          }}>
          <Tooltip title='Calendar Settings'>
            <IconButtonMui
              size='large'
              edge='start'
              aria-label='calendar-settings-button'
              onClick={schedulerProps.onOpenSettingsDrawer}
            >
              <SettingsOutlined />
              {/* <Badge color='primary' badgeContent={getBadgeContent()} invisible={!isSettingsBadgeVisible()}>
              <SettingsOutlined />
            </Badge> */}
            </IconButtonMui>
          </Tooltip>
          <CalendarFilterButton
            userStore={schedulerProps.userStore}
            sessionStore={schedulerProps.sessionStore}
            onOpenFiltersDrawer={schedulerProps.onOpenFiltersDrawer} />
          <Tooltip title='Session Search'>
            <IconButtonMui
              size='large'
              edge='start'
              aria-label='session-search-open-button'
              style={{ transform: 'scale(1.2)', marginLeft: '-12px' }}
              onClick={schedulerProps.onOpenSessionSearchDrawer}
            >
              <SearchOutlined />
            </IconButtonMui>
          </Tooltip>
        </div>
        <div style={{
          position: 'fixed',
          right: '10px',
          display: 'flex',
          alignItems: 'baseline',
          flexDirection: 'row-reverse'
        }}>
          {props.children}
        </div>
      </Toolbar.Root>
    );
  }, [schedulerProps.onOpenSettingsDrawer, schedulerProps.userStore, schedulerProps.sessionStore, schedulerProps.onOpenFiltersDrawer]);

  const todayButtonComponent = useCallback((props: TodayButton.ButtonProps) => {
    return (
      <TodayButton.Button
        {...props}
        style={{
          marginLeft: '6px',
          fontSize: '0.9rem',
          alignSelf: 'center'
        }}
      />
    );
  }, []);

  // const appointmentComponent = AppointmentCard({
  //   appState: schedulerProps.appState,
  //   sessionStore: schedulerProps.sessionStore,
  //   sessionIdQueryParam
  // });

  const dayScaleRowComponent = useMemo(() => () => <Fragment></Fragment>, []);

  const getMinWidth = useCallback((): string => {
    if (schedulerProps.sessionStore.grouping === 'location') {
      return `${schedulerProps.eventStore.locations.length * 190}px`;
    }
    return `700px`;
  }, [schedulerProps.sessionStore.grouping, schedulerProps.eventStore.locations]);

  const getGrouping = useCallback((): any => {
    return [
      {
        resourceName: schedulerProps.sessionStore.grouping,
      }
    ]
  }, [schedulerProps.sessionStore.grouping]);

  interface testProps {
    eventStore: EventStore;
    layoutProps: GroupingPanel.CellProps;
  }

  const ResourceHeaderComponent = useMemo(() => (props: testProps) => {
    const foundLocation = props.eventStore.locations.find(l => l.id === props.layoutProps.group.id);
    return (
      <GroupingPanel.Cell {...props.layoutProps}
        className='resource-header'
        style={{
          backgroundColor: foundLocation ? DEFAULT_PALETTE[props.eventStore.locations.indexOf(foundLocation)][LEVEL] : 'transparent'
        }}>
      </GroupingPanel.Cell>
    )
  }, []);

  return (
    <ThemeProvider theme={schedulerProps.appState.legacyTheme}>
      <CssBaseline />
      <TableContainer>
        <div style={{
          minWidth: getMinWidth(),
          height: `calc(var(--app-height) - ${BOTTOM_APP_BAR_HEIGHT}px)`,
          marginLeft: '-12px'
        }}>
          <DXScheduler
            data={getAppointments(schedulerProps.sessionStore.sessions, schedulerProps.sessionStore, schedulerProps.userStore.currentUser!)}>
            <ViewState
              currentDate={currentDate}
              onCurrentDateChange={handleOnCurrentDateChange}
            />
            <DayView
              dayScaleRowComponent={dayScaleRowComponent} // Hiding the day row
              startDayHour={getStartDayHour()}
              endDayHour={getEndDayHour(getStartDayHour())}
            />
            <Appointments
              //appointmentComponent={appointmentComponent}
              appointmentComponent={useCallback((props) =>
                <AppointmentCard appointmentProps={props}
                  appState={schedulerProps.appState}
                  sessionStore={schedulerProps.sessionStore}
                />, [])
              } />
            <AppointmentTooltip
              layoutComponent={useCallback((props) => <AppointmentTooltipLayoutParent layoutProps={props} appState={schedulerProps.appState} />, [])}
              headerComponent={appointmentTooltipHeader}
              contentComponent={getAppointmentTooltipContentComponent}
              //visible={schedulerProps.appState.isAppointmentTooltipVisible}
              onVisibilityChange={schedulerProps.appState.toggleAppointmentTooltipVisible}
            />
            <GroupingState
              grouping={getGrouping()}
              groupByDate={groupByDate}
            />
            <Resources
              data={getResources(schedulerProps.eventStore.locations, schedulerProps.userStore.currentUser!)}
              mainResourceName={schedulerProps.sessionStore.grouping} />
            <IntegratedGrouping />
            <GroupingPanel
              cellComponent={(props) => <ResourceHeaderComponent layoutProps={props} eventStore={schedulerProps.eventStore} />}
            />
            <Toolbar rootComponent={toolbarRootComponent} />
            <DateNavigator
              openButtonComponent={openButtonComponent}
              rootComponent={dateNavigatorRootComponent} />
            <TodayButton buttonComponent={todayButtonComponent} />
            <CurrentTimeIndicator updateInterval={5 * 1000 * 60} />
          </DXScheduler>
        </div>
      </TableContainer>
    </ThemeProvider>
  );
}
//(Scheduler as any).whyDidYouRender = true;
export default observer(Scheduler);
