import { AddCircle, Delete } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';
import './AddOrRemove.scss';

export enum AddOrRemoveButtonType {
    Icons,
    Buttons
}

interface AddOrRemoveProps {
    buttonType: AddOrRemoveButtonType
    isAdded: boolean;
    onAdd: () => void;
    onRemove: () => void;
    disabled?: boolean;
}

export const AddOrRemove = (props: AddOrRemoveProps) => {
    const onClick = () => {
        if (!props.isAdded) {
            props.onAdd();
        } else {
            props.onRemove();
        }
    }
    const buttonsEl = (
        <Button
            disabled={props.disabled}
            style={{ flex: 1 }}
            variant='contained'
            onClick={onClick}
            color={props.isAdded ? 'error' : 'success'}
            startIcon={props.isAdded ? <Delete /> : <AddCircle />}>
            {props.isAdded ? 'Leave' : 'Join'}
        </Button>);

    const iconsEl = (
        <div className='add-or-delete-icon'>
            <IconButton sx={{ opacity: 1 }} onClick={onClick} color='default' className='less-padding' disabled={props.disabled}>
                {props.isAdded ?
                    <Delete className='less-padding' /> :
                    <AddCircle className='less-padding' />
                }
            </IconButton>
        </div >);

    return props.buttonType === AddOrRemoveButtonType.Buttons ? buttonsEl : iconsEl;
}