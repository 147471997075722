/// <reference types="@welldone-software/why-did-you-render" />
// Turn on to debug react rendering
//import './wdyr';
import React from 'react';
import ReactDOM from 'react-dom';
import { Subject } from 'rxjs';
import App from './App';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import { EventService, SessionService, UserService } from './services';
import { AppState, EventStore, SessionStore, UserStore } from './state';
import { getMobileOS } from './util';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/900.css';
import { Amplify, Auth } from 'aws-amplify';
import config from './aws-exports';

const stop$ = new Subject<boolean>();

const appState = new AppState();
const userService = new UserService(appState, stop$);
const eventService = new EventService(appState, userService, stop$);
const sessionService = new SessionService(appState, userService, eventService, stop$);
const userStore = new UserStore(userService, appState, stop$);
const sessionStore = new SessionStore(userService, sessionService, stop$);
const eventStore = new EventStore(eventService, stop$);

const mobileType = getMobileOS();
console.log(`Mobile type: ` + mobileType.toString());
appState.setMobileType(mobileType);

// This is needed for OAuth redirect to work
//const currentUri = window.location.origin + window.location.pathname;
const currentUri = `${window.location.origin}/`;

//console.log(config);
const updatedAwsConfig = {
  ...config,
  oauth: {
    ...config.oauth,
    redirectSignIn: currentUri,
    redirectSignOut: currentUri
  }
  ,
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  graphql_headers: async () => {
    const currentSession = await Auth.currentSession();
    return { Authorization: currentSession.getIdToken().getJwtToken() };
  }
}
Amplify.configure(updatedAwsConfig);

ReactDOM.render(
  <React.StrictMode>
    <App
      appState={appState}
      userStore={userStore}
      sessionStore={sessionStore}
      eventStore={eventStore}
    />
  </React.StrictMode>,
  document.getElementById('root')
);

const appHeightAndWidth = () => {
  const doc = document.documentElement;
  doc.style.setProperty('--app-height', `${window.innerHeight}px`);
  doc.style.setProperty('--app-width', `${window.innerWidth}px`);
}
window.addEventListener('resize', appHeightAndWidth);
appHeightAndWidth();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
