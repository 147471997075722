import { IconButton } from '@material-ui/core';
import { CssBaseline, TableContainer, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { observer } from 'mobx-react-lite';
import {
  BrowserRouter as Router, Redirect, Route, Switch,
} from 'react-router-dom';
import './App.scss';
import { BottomAppBar } from './components/bottomAppBar';
import { Calendar } from './components/calendar';
import { EventsPage } from './components/eventsPage';
import { CrewsPage } from './components/crewsPage';
//import { FriendsPage } from './components/friendsPage';
import { Login } from './components/login';
import { AppState, EventStore, SessionStore, UserStore } from './state';
import { bottomAppBarHeight, pageHeaderHeight } from './constants';
import './Styles.scss';
import { ProfilePage } from './components/profilePage';
import { Spinner } from './components/spinner';
import { useEffect } from 'react';

interface AppProps {
  userStore: UserStore;
  sessionStore: SessionStore;
  eventStore: EventStore;
  appState: AppState;
}

const HomeComponent = (props: AppProps) => {
  return (
    <div style={{ flex: 1, padding: '20px' }}>
      {/* <Typography variant='h6'>Mode</Typography>
      <RadioGroup
        name='controlled-radio-buttons-group'
        value={props.appState.mode}
        onChange={(e) => props.appState.setMode(e.target.value as Mode)}
        sx={{ width: '100%' }}
      >
        <FormControlLabel value='dark' control={<Radio />} label='Dark' />
        <FormControlLabel value='light' control={<Radio />} label='Light' />
      </RadioGroup> */}
    </div>
  );
}

const App = (props: AppProps) => {
  useEffect(() => {
    const searchParams = new URLSearchParams(document.location.search);
    if (searchParams) {
      const sessionIdFromQuery = searchParams.get('sessionId');
      if (sessionIdFromQuery) {
        props.sessionStore.setSessionId(sessionIdFromQuery)
      }
    }

    return () => {
      console.debug('App component is unmounting');
    }
  }, []);
  return (
    <Router>
      <ThemeProvider theme={props.appState.theme}>
        <CssBaseline />
        <div
          className='flex-column'
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: 'var(--app-height)',
            overflowX: 'scroll',
            top: '0',
            left: '0',
            width: '100vw'
          }}>
          <svg width='0' height='0'>
            <linearGradient id='orange-gradient' gradientTransform='rotate(259.45)'>
              <stop stopColor='#F86343' offset='17.64%' />
              <stop stopColor='#F319B6' offset='94.22%' />
              {/* <stop stopColor='#df6c92' offset='17.64%' />
                        <stop stopColor='#9f05f2' offset='94.22%' /> */}
            </linearGradient>
            <linearGradient id='purple-gradient' gradientTransform='rotate(45)'>
              <stop stopColor='#f319b6' offset='17.64%' />
              <stop stopColor='#9343f8' offset='94.22%' />
              {/* <stop stopColor='#df6c92' offset='17.64%' />
                        <stop stopColor='#9f05f2' offset='94.22%' /> */}
            </linearGradient>
          </svg>
          {/* HACK!
          This is a horrible, hacky way to get React to load the css file for
          @material-ui (used by DevExpress) before @mui so the former does not
          override the styles of the latter. NOT PROUD OF IT BUT DEAL WITH IT! */}
          <IconButton hidden style={{ display: 'none' }} />
          <Switch>
            <Route exact path='/'>
              {props.appState.isLoading ?
                <Spinner /> :
                props.userStore.currentUser
                  ? <Redirect to='/calendar'></Redirect>
                  : <Login userStore={props.userStore} />
              }
              {/* <Redirect to='/home'></Redirect> */}
            </Route>
            <Route exact path='/home'>
              <div style={{
                flex: '1'
              }}>
                {props.appState.isLoading ?
                  <Spinner /> :
                  props.userStore.currentUser
                    ? <HomeComponent {...props} />
                    : <Redirect to='/'></Redirect>
                }
              </div>
            </Route>
            {/* <Route path='/login'>
              <div style={{
                flex: '1'
              }}>
                <Login userStore={props.userStore} />
              </div>
            </Route> */}
            <Route path='/calendar'>
              <div style={{
                flex: '1'
              }}>
                {props.appState.isLoading ?
                  <Spinner /> :
                  props.userStore.currentUser
                    ?
                    props.userStore.allMyCrews.length === 0
                      ?
                      <Redirect to='/crews'></Redirect>
                      // : !props.eventStore.selectedEvent
                      //   ?
                      //   <div className='flex-column' style={{ padding: '20px', gap: '10px' }}>
                      //     <Typography fontWeight={'900'} letterSpacing={'0.16em'} fontSize={'1.2em'} textAlign={'center'} style={{ marginTop: '20px' }}>CALENDAR</Typography>
                      //     <Typography>Select an event to get started</Typography>
                      //     <EventFilter
                      //       eventStore={props.eventStore}
                      //       id='landing-event-filter'
                      //     />
                      //   </div>
                      :
                      props.appState.isLoading ?
                        <Spinner /> :
                        <Calendar
                          appState={props.appState}
                          sessionStore={props.sessionStore}
                          eventStore={props.eventStore}
                          userStore={props.userStore}
                        />
                    : <Redirect to='/'></Redirect>
                }
              </div>
            </Route>
            <Route path='/events'>
              <div style={{
                flex: '1'
              }}>
                {props.appState.isLoading ?
                  <Spinner /> :
                  props.userStore.currentUser
                    ?
                    <>
                      <Typography fontWeight={'900'} letterSpacing={'0.16em'} fontSize={'1.2em'} textAlign={'center'} style={{ marginTop: '20px' }}>EVENTS</Typography>
                      <TableContainer style={{ maxHeight: `calc(var(--app-height) - ${bottomAppBarHeight + pageHeaderHeight}px)`, height: '100%' }}>
                        <EventsPage
                          eventStore={props.eventStore}
                        />
                      </TableContainer>
                    </>
                    : <Redirect to='/'></Redirect>
                }
              </div>
            </Route>
            <Route path='/crews'>
              <div style={{
                flex: '1',
                paddingBottom: `${bottomAppBarHeight}px`
              }}>
                {props.appState.isLoading ?
                  <Spinner /> :
                  props.userStore.currentUser
                    ?
                    <>
                      <Typography fontWeight={'900'} letterSpacing={'0.16em'} fontSize={'1.2em'} textAlign={'center'} style={{ marginTop: '20px' }}>CREWS</Typography>
                      <TableContainer style={{
                        // maxHeight: `calc(var(--app-height) - ${bottomAppBarHeight + pageHeaderHeight}px)`, 
                        height: '100%'
                      }}>
                        <CrewsPage
                          appState={props.appState}
                          userStore={props.userStore}
                          eventStore={props.eventStore}
                          sessionStore={props.sessionStore}
                        />
                      </TableContainer>
                    </>
                    : <Redirect to='/'></Redirect>
                }
              </div>
            </Route>
            <Route path='/profile'>
              <div style={{
                flex: '1'
              }}>
                {props.appState.isLoading ?
                  <Spinner /> :
                  props.userStore.currentUser
                    ?
                    <>
                      <Typography fontWeight={'900'} letterSpacing={'0.16em'} fontSize={'1.2em'} textAlign={'center'} style={{ marginTop: '20px' }}>PROFILE</Typography>
                      <TableContainer style={{ maxHeight: `calc(var(--app-height) - ${bottomAppBarHeight + pageHeaderHeight}px)`, height: '100%' }}>
                        <ProfilePage userStore={props.userStore} />
                      </TableContainer>
                    </>
                    : <Redirect to='/'></Redirect>
                }
              </div>
            </Route>
            {/* <Route path='/friends'>
              <div style={{
                flex: '1'
              }}>
                <TableContainer style={{ maxHeight: `calc(var(--app-height) - ${bottomAppBarHeight}px)` }}>
                  <FriendsPage
                    appState={props.appState}
                    userStore={props.userStore}
                    eventStore={props.eventStore}
                    sessionStore={props.sessionStore}
                  />
                </TableContainer>
              </div>
            </Route> */}
          </Switch>
          {props.userStore.currentUser && <BottomAppBar />}
        </div>
      </ThemeProvider>
    </Router>
  );
}

export default observer(App);
