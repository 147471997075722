import { Star, StarBorder } from '@mui/icons-material';
import { Avatar, IconButton, Typography } from '@mui/material';
import { Fragment } from 'react';
import { User } from '../../models';
import { UserStore } from '../../state';
import './AvatarItem.scss';
import { getInitials } from '../../util';

interface UserItemProps {
    userStore?: UserStore;
    user?: User;
    name?: string;
    avatar?: string;
    size?: 'small' | 'medium' | 'large' | 'xlarge' | 'giant';
    showFavorites?: boolean;
}

const styles = {
    small: {
        height: 24,
        width: 24
    },
    medium: {
        height: 32,
        width: 32
    },
    large: {
        height: 38,
        width: 38
    },
    xlarge: {
        height: 64,
        width: 64
    },
    giant: {
        height: 128,
        width: 128
    }
}

const AvatarItem = (props: UserItemProps) => {
    const onToggleFavorite = (e) => {
        e.stopPropagation();
        props.userStore!.toggleFriendAsFavorite(props.user!);
    }
    return (
        <div className='item-div'>
            {props.user ?
                <Fragment>
                    {props.showFavorites &&
                        <IconButton onClick={(e) => onToggleFavorite(e)}
                            sx={{ marginRight: '-3px', marginLeft: '-14px' }}>
                            {Boolean(props.user.isFavorite) ?
                                <Star color='primary' /> :
                                <StarBorder />
                            }
                        </IconButton>}
                    {
                        props.user.avatar ?
                            <Avatar src={props.user.avatar} imgProps={{ referrerPolicy: 'no-referrer' }}
                                sx={{
                                    height: styles[props.size || 'medium'].height,
                                    width: styles[props.size || 'medium'].width
                                }} />
                            :
                            <Avatar
                                sx={{
                                    height: styles[props.size || 'medium'].height,
                                    width: styles[props.size || 'medium'].width
                                }}>{getInitials(props.name)}</Avatar>
                    }
                    <Typography>{props.user.name}</Typography>
                </Fragment> :
                <Fragment>
                    {
                        props.avatar ?
                            <Avatar src={props.avatar} imgProps={{ referrerPolicy: 'no-referrer' }}
                                sx={{
                                    height: styles[props.size || 'medium'].height,
                                    width: styles[props.size || 'medium'].width
                                }} />
                            :
                            <Avatar
                                sx={{
                                    height: styles[props.size || 'medium'].height,
                                    width: styles[props.size || 'medium'].width
                                }}>{getInitials(props.name)}</Avatar>
                    }
                    <Typography>{props.name}</Typography>
                </Fragment>
            }
        </div>
    );
}

export default AvatarItem;
