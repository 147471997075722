import { AccessTimeOutlined, ChevronLeft, ExpandMore, LocalActivityOutlined, TodayOutlined } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Divider, Drawer, IconButton, List, Typography, useTheme } from '@mui/material';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { AppState, EventStore, SessionStore, UserStore } from '../../state';
import { EventFilter } from '../eventFilter';
import { TimeFilter } from '../timeFilter';
import './CalendarSettingsDrawer.scss';
import { ScheduleDisplaySelector } from '../scheduleDisplaySelector';

interface CalendarSettingsDrawerProps {
    eventStore: EventStore;
    userStore: UserStore;
    sessionStore: SessionStore;
    appState: AppState;
}

const CalendarSettingsDrawer = (props: CalendarSettingsDrawerProps) => {
    const textColor = useTheme().palette.text.secondary;
    const [expanded, setExpanded] = useState<string | false>('schedule-view');
    const onCloseDrawer = () => props.appState.setIsCalendarSettingsDrawerOpen(false);
    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Drawer
            sx={{
                minWidth: '240px',
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    minWidth: '240px',
                    boxSizing: 'border-box',
                }
            }}
            ModalProps={{ onBackdropClick: onCloseDrawer }}
            anchor='left'
            open={props.appState.isCalendarSettingsDrawerOpen}
            onClose={onCloseDrawer}
        >
            <div>
                <div id='calendar-settings-drawer-header'
                    className='flex-row'
                    style={{ padding: '10px 10px 10px 20px', alignItems: 'center', justifyContent: 'center' }}
                >
                    <Typography
                        sx={{ flex: '2' }}
                        color={textColor}
                        fontSize='1.1rem'
                        fontWeight='bold'>
                        Calendar Settings
                    </Typography>
                    <IconButton
                        style={{ marginLeft: '5px' }}
                        color='default'
                        onClick={onCloseDrawer}>
                        <ChevronLeft />
                    </IconButton>
                </div>
                <Divider />
                <List style={{ marginTop: '10px' }}>
                    <Accordion elevation={0} expanded={expanded === 'schedule-view'} onChange={handleChange('schedule-view')}>
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls='schedule-display-accordion-content'
                            id='schedule-display-accordion'>
                            <TodayOutlined style={{ marginRight: '10px' }} />
                            <Typography>Schedule Display</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ marginLeft: '10px' }}>
                            <ScheduleDisplaySelector
                                id='schedule-display-selector'
                                sessionStore={props.sessionStore}
                            />
                        </AccordionDetails>
                    </Accordion>
                    <Divider />
                    <Accordion elevation={0} expanded={expanded === 'time-display'} onChange={handleChange('time-display')}>
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls='time-display-accordion-content'
                            id='time-display-accordion'>
                            <AccessTimeOutlined style={{ marginRight: '10px' }} />
                            <Typography>Time Display</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ marginLeft: '10px' }}>
                            <TimeFilter
                                id='time-display-filter'
                                sessionStore={props.sessionStore}
                            />
                        </AccordionDetails>
                    </Accordion>
                    <Divider />
                    <Accordion elevation={0} expanded={expanded === 'event-selection'} onChange={handleChange('event-selection')}>
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls='event-selection-accordion-content'
                            id='event-selection-accordion'>
                            <LocalActivityOutlined style={{ marginRight: '10px' }} />
                            <Typography>Change Event</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ marginLeft: '10px' }}>
                            <EventFilter
                                eventStore={props.eventStore}
                                id='event-filter'
                            />
                        </AccordionDetails>
                    </Accordion>
                </List>
            </div>
        </Drawer>
    );
};

export default observer(CalendarSettingsDrawer);
