export enum MobileOSType {
    Android = 'Android',
    IOS = 'iOS',
    Other = 'Other'
}

export const getMobileOS = (): MobileOSType => {
    const ua = navigator.userAgent
    if (/android/i.test(ua)) {
        return MobileOSType.Android;
    } else if (/iPad|iPhone|iPod/.test(ua) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
        return MobileOSType.IOS;
    }
    return MobileOSType.Other;
}