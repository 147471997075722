import { Appointments } from '@devexpress/dx-react-scheduler-material-ui';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo } from 'react';
import { AppState, SessionStore } from '../../state';
import { AddOrRemove, AddOrRemoveButtonType } from '../addOrRemove';
import { AvatarsDiv } from './';
import './AppointmentCard.scss';

interface AppointmentCardProps {
    appState: AppState,
    sessionStore: SessionStore;
    appointmentProps: Appointments.AppointmentProps;
}

const AppointmentCard = (props: AppointmentCardProps, { style }) => {
    useEffect(() => {
        console.debug('AppointmentCard useEffect');

        return () => {
            console.debug('AppointmentCard component is unmounting');
        }
    }, [])
    const locationField = props.appointmentProps.resources.find(r => r.fieldName === 'location')!;
    const userField = props.appointmentProps.resources.find(r => r.fieldName === 'user')!;
    locationField.isMain = true;
    userField.isMain = false;

    const updatedAppointmentProps = {
        ...props.appointmentProps,
        resources: [
            locationField,
            userField
        ]
    };

    const isUserInSession = props.sessionStore.isUserInSession(props.appointmentProps.data.id!);
    const addUserToSession = useCallback(
        () => props.sessionStore.joinSession(props.appointmentProps.data.id!.toString())
        , [props.appointmentProps.data.id]
    );
    const removeUserFromSession = useCallback(
        () => props.sessionStore.leaveSession(props.appointmentProps.data.id!.toString())
        , [props.appointmentProps.data.id]
    );
    const onAppointmentClick =
        (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            if ((event.target as any).classList.contains('appointment-users')) {
                props.appState.setCanToggleAppointmentTooltopVisibility(true);
            }
            if ((event.target as any).closest('.add-or-delete-icon')) {
                props.appState.setCanToggleAppointmentTooltopVisibility(false);
            } else {
                props.appState.setCanToggleAppointmentTooltopVisibility(true);
            }
            props.appointmentProps.onClick!(event!);
        };

    const isSessionSelected = useMemo(() =>
        props.sessionStore.sessionId !== null &&
        props.sessionStore.sessionId !== undefined &&
        props.appointmentProps.data.id !== undefined &&
        props.sessionStore.sessionId!.toString() === props.appointmentProps.data.id.toString()
        , [props.appointmentProps.data.id, props.sessionStore.sessionId]);

    const getDates = useMemo(() => (): string => {
        if (props.appointmentProps.data.startDate instanceof Date && props.appointmentProps.data.endDate instanceof Date) {
            const options: Intl.DateTimeFormatOptions = {
                timeStyle: 'short'
            };
            return `${props.appointmentProps.data.startDate.toLocaleTimeString([], options)} - ${props.appointmentProps.data.endDate.toLocaleTimeString([], options)}`;
        } else {
            return `${props.appointmentProps.data.startDate.toLocaleString()} - ${props.appointmentProps.data.endDate.toLocaleString()}`;
        }
    }, [props.appointmentProps.data.startDate, props.appointmentProps.data.endDate]);

    const getLocation = useMemo(() => (): string => {
        if (props.sessionStore.grouping === 'user') {
            return ` @${locationField.text}`;
        }
        return '';
    }, [props.sessionStore.grouping, locationField.text]);

    return (
        <Appointments.Appointment
            {
            ...updatedAppointmentProps
            }
            onClick={onAppointmentClick}
            style={!isUserInSession ? {
                ...style,
                backgroundColor:
                    props.appState.mode === 'light' ?
                        'rgba(0, 0, 0, 0.54)' :
                        'rgba(191, 191, 191, 0.54)'
            } : {
                ...style
            }
            }
        >
            <div id={`div-appointment-card-${props.appointmentProps.data.id}`}>
                {/* <CircularProgress size='10rem' color='inherit' /> */}
                <div style={{ display: 'flex' }}>
                    <div className='appointment-title'>
                        {props.appointmentProps.data.title}
                    </div>
                    {useMemo(() => <AddOrRemove
                        buttonType={AddOrRemoveButtonType.Icons}
                        disabled={props.sessionStore.shouldWait}
                        isAdded={isUserInSession}
                        onAdd={addUserToSession}
                        onRemove={removeUserFromSession} />, [isUserInSession, props.sessionStore.shouldWait])}
                </div>
                <div className='appointment-text'>
                    <span>{getDates()}</span>
                    <span style={{ fontWeight: 'bold' }}>{getLocation()}</span>
                </div>
                <AvatarsDiv
                    id={props.appointmentProps.data.id}
                    users={props.sessionStore.getUsersForSession(props.appointmentProps.data.id!)}
                    isSessionSelected={isSessionSelected}
                    isSessionTooltipShownForQueryParam={props.appState.isSessionTooltipShownForQueryParam}
                    onTooltipForSessionFromQueryParamsShown={props.appState.setIsSessionTooltipShownForQueryParamToTrue}
                    color={props.appointmentProps.resources[0].color}
                />
            </div>
        </Appointments.Appointment >
    )
};

//(AppointmentCard as any).whyDidYouRender = true;
//const MemoizedComponent = memo(AppointmentCard) as typeof AppointmentCard;
export default observer(AppointmentCard);
