import { Add, AddCircle, Search } from '@mui/icons-material';
import { Alert, AlertColor, Button, IconButton, Popover, Snackbar, TextField, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Crew, CrewResult, Result } from '../../models';
import { AppState, EventStore, SessionStore, UserStore } from '../../state';
import { CrewsSearch } from '../crewsSearch';
import { bottomAppBarHeight, pageHeaderHeight } from '../../constants';
import './CrewsPage.scss';
import { CrewImageList } from '../crewImageList';
import CrewDetail from '../crewDetail/CrewDetail';
import { useLocation } from 'react-router-dom';
import { CreateCrewStepper } from '../createCrewStepper';

interface CrewsPageProps {
    userStore: UserStore,
    eventStore: EventStore,
    sessionStore: SessionStore,
    appState: AppState;
}

const CrewsPage = (props: CrewsPageProps) => {
    // const [friendsSearchAnchorEl, setFriendsSearchAnchorEl] = useState<HTMLButtonElement | null>(null);
    // const [friendsDetailAnchorEl, setFriendsDetailAnchorEl] = useState<HTMLElement | null>(null);
    // const [friendsFavoritesAnchorEl, setFriendsFavoritesAnchorEl] = useState<HTMLElement | null>(null);
    const [crewsSearchAnchorEl, setCrewsSearchAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [crewDetailAnchorEl, setCrewDetailAnchorEl] = useState<HTMLElement | null>(null);
    // const [selectedFriend, setSelectedFriend] = useState<User | undefined>(undefined);
    const [selectedCrew, setSelectedCrew] = useState<Crew | undefined>(undefined);
    const [joinCode, setJoinCode] = useState<string>('');
    const [isToastOpen, setIsToastOpen] = useState<boolean>(false);
    const [toastMessage, setToastMessage] = useState<string>('');
    const [toastSeverity, setToastSeverity] = useState<AlertColor>('success');
    const [isCreateWizardOpen, setIsCreateWizardOpen] = useState<boolean>(false);

    const queryParams = new URLSearchParams(useLocation().search);
    const joinCodeQueryParam = queryParams.get('joinCode');

    // const friendsRef = useRef(null);
    const crewsRef = useRef(null);

    // const handleFriendsSearchClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    //     setFriendsSearchAnchorEl(event.currentTarget);
    // };

    // const handleFriendsSearchClose = () => {
    //     setFriendsSearchAnchorEl(null);
    // };

    // const handleFriendsDetailOpen = () => {
    //     setFriendsDetailAnchorEl(friendsRef.current);
    // };

    // const handleFriendsFavoritesOpen = () => {
    //     setFriendsFavoritesAnchorEl(friendsRef.current);
    // };

    // const handleFriendsDetailClose = () => {
    //     setFriendsDetailAnchorEl(null);
    // };

    // const handleFriendsFavoritesClose = () => {
    //     setFriendsFavoritesAnchorEl(null);
    // };

    const handleCrewsSearchClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setCrewsSearchAnchorEl(event.currentTarget)
    }, []);

    const handleCrewsSearchClose = useCallback(() => {
        setCrewsSearchAnchorEl(null);
    }, []);

    const handleCrewDetailOpen = useCallback(() => {
        setCrewDetailAnchorEl(crewsRef.current);
    }, [crewsRef.current]);

    const handleCrewDetailClose = useCallback(() => {
        setCrewDetailAnchorEl(null);
    }, []);

    // const onHandleResult = (result: Result) => {
    //     setToastMessage(result.message);
    //     setToastSeverity(result.status as AlertColor);
    //     setIsToastOpen(true);
    // }

    const handleToastClose = () => {
        setIsToastOpen(false);
    }

    const joinCrew = useCallback(async () => {
        const result = await props.userStore.joinCrew(joinCode);
        if (result) {
            setIsToastOpen(true);
            setToastMessage(result!.message);
            setToastSeverity(result.status as AlertColor);
        }
    }, [joinCode]);

    // const isFriendsSearchOpen = Boolean(friendsSearchAnchorEl);
    // const isFriendsDetailOpen = Boolean(friendsDetailAnchorEl);
    // const isFriendsFavoritesOpen = Boolean(friendsFavoritesAnchorEl);
    const isCrewsSearchOpen = useMemo(() => Boolean(crewsSearchAnchorEl), [crewsSearchAnchorEl]);
    const isCrewDetailOpen = useMemo(() => Boolean(crewDetailAnchorEl), [crewDetailAnchorEl]);
    // const friendsSearchPopoverId = isFriendsSearchOpen ? 'search-friends-popover' : undefined;
    // const friendsDetailPopoverId = isFriendsDetailOpen ? 'friends-detail-popover' : undefined;
    // const friendsFavoritesPopoverId = isFriendsFavoritesOpen ? 'friends-favorites-popover' : undefined;
    const crewsSearchPopoverId = useMemo(() => isCrewsSearchOpen ? 'search-crews-popover' : undefined, [isCrewsSearchOpen]);
    const crewDetailPopoverId = useMemo(() => isCrewDetailOpen ? 'crew-detail-popover' : undefined, [isCrewDetailOpen]);

    const isAPopoverOpen = useCallback(() =>
        // isFriendsSearchOpen || isFriendsDetailOpen || isFriendsFavoritesOpen || 
        isCrewsSearchOpen || isCrewDetailOpen, [isCrewsSearchOpen, isCrewDetailOpen]);

    // const handleSelectedFriend = (friend: User) => {
    //     setSelectedFriend(friend);
    //     handleFriendsSearchClose();
    //     handleFriendsDetailOpen();
    // }

    const handleSelectedCrew = useCallback((crew: Crew) => {
        if (crew.id !== selectedCrew?.id) {
            setSelectedCrew(crew);
        }
        // To test layout with a lot of items to see how it scrolls
        // setSelectedCrew({
        //     ...crew,
        //     members: new Array(120).fill(crew.members[0]),
        //     admins: new Array(4).fill(crew.members[0]),
        // })

        handleCrewsSearchClose();
        handleCrewDetailOpen();
    }, []);

    useEffect(() => {

    });

    const onJoinCodeChange = useCallback((event) => {
        const setsOfFour = (event.target.value as string).replaceAll('-', '').match(/.{1,4}/g);
        const val = setsOfFour?.join('-').toLocaleUpperCase();
        setJoinCode(val || event.target.value);
    }, []);

    const createNewCrew = useCallback(() => {
        setIsCreateWizardOpen(true);
    }, []);

    const onCloseStepper = useCallback(() => {
        setIsCreateWizardOpen(false);
    }, []);

    const onCreateCrew = useCallback(async (): Promise<CrewResult> => {
        // TODO
        return {
            message: '',
            status: 'success',
            joinCode: '123'
        }
    }, []);

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                open={isToastOpen}
                autoHideDuration={4000}
                onClose={handleToastClose}
                style={{ top: 'calc(var(--app-height)/2)' }}
            >
                <Alert
                    onClose={handleToastClose}
                    severity={toastSeverity}
                    sx={{ boxShadow: 10, opacity: 1, fontSize: '1.2rem', padding: '20px' }}>
                    {toastMessage}
                </Alert>
            </Snackbar>
            <div id='crews-page-top-div' className='flex-column top-div'
                style={{
                    padding: '20px',
                    gap: '20px',
                    //height: '100%',
                    height: `calc(var(--app-height) - ${bottomAppBarHeight}`,
                    filter: isAPopoverOpen() ? 'blur(2px)' : '',
                    WebkitFilter: isAPopoverOpen() ? 'blur(2px)' : '',
                    opacity: isAPopoverOpen() ? '0.2' : '1'
                }}>

                {/* <div className='bottom-fade-div' /> */}
                {isCreateWizardOpen ?
                    <CreateCrewStepper userStore={props.userStore} onCloseStepper={onCloseStepper}></CreateCrewStepper>
                    :
                    <div id='crews-div' className='flex-column' ref={crewsRef}>
                        <div className='section-button-group' style={{ position: 'absolute', right: '10px' }}>
                            <IconButton onClick={handleCrewsSearchClick}>
                                <Search />
                            </IconButton>
                            <Popover
                                id={crewsSearchPopoverId}
                                open={isCrewsSearchOpen}
                                anchorEl={crewsSearchAnchorEl}
                                onClose={handleCrewsSearchClose}
                                anchorReference='anchorPosition'
                                anchorPosition={{ top: pageHeaderHeight + 20, left: 0 }}
                                PaperProps={{
                                    style: { width: '100%', borderRadius: '20px' },
                                }}
                            >
                                <div style={{
                                    padding: '16px'
                                }}>
                                    <CrewsSearch userStore={props.userStore} onCrewSelected={handleSelectedCrew} />
                                </div>
                            </Popover>
                            <Popover
                                id={crewDetailPopoverId}
                                open={isCrewDetailOpen}
                                anchorEl={crewDetailAnchorEl}
                                onClose={handleCrewDetailClose}
                                anchorReference='anchorPosition'
                                anchorPosition={{ top: pageHeaderHeight + 20, left: 0 }}
                                PaperProps={{
                                    style: { width: '100%' },
                                }}
                            >
                                <div style={{
                                    padding: '16px'
                                }}>
                                    <CrewDetail userStore={props.userStore} selectedCrew={selectedCrew} />
                                </div>
                            </Popover>
                            <IconButton onClick={createNewCrew}>
                                <AddCircle />
                            </IconButton>
                        </div>
                        <div className='flex-column'>
                            <div className='flex-column'>
                                <Typography fontWeight={'700'} fontSize={'1.2em'}>Crews You Run</Typography>

                                <div id='your-admin-crews-list' className='flex-column' style={{ overflow: 'auto' }}>
                                    {/* <TableContainer> */}
                                    <CrewImageList crews={props.userStore.myAdminCrews} onToggleFavorite={() => { }} onCrewClicked={(crew: Crew) => handleSelectedCrew(crew)} />
                                    {/* {new Array(25).fill(0).map(i => <div>Test</div>)} */}
                                    {/* </TableContainer> */}
                                </div>
                            </div>
                            <div className='flex-column'>
                                <Typography fontWeight={'700'} fontSize={'1.2em'}>Crews You Are a Member of</Typography>
                                <div id='your-user-crews-list' className='flex-column' style={{ overflow: 'auto' }}>
                                    {/* <TableContainer> */}
                                    <CrewImageList crews={props.userStore.myMemberCrews} onToggleFavorite={() => { }} onCrewClicked={(crew: Crew) => handleSelectedCrew(crew)} />
                                    {/* {new Array(25).fill(0).map(i => <div>Test</div>)} */}
                                    {/* </TableContainer> */}
                                </div>
                            </div>
                            <div id='crews-buttons' className='flex-row buttons-container'>
                                <Button className='action-button' onClick={createNewCrew}>Create New</Button>
                                {/* <Button className='action-button'>Discover</Button> */}
                            </div>
                            {props.userStore.allMyCrews.length === 0 &&
                                (<>
                                    {/* <Typography fontWeight={'900'} letterSpacing={'0.16em'} fontSize={'1.2em'} textAlign={'center'} style={{ marginTop: '20px' }}>JOIN A CREW</Typography> */}
                                    {/* <TableContainer style={{ maxHeight: `calc(var(--app-height) - ${bottomAppBarHeight + pageHeaderHeight}px)`, height: '100%', padding: '20px' }}> */}
                                    <Typography>You are not part of any crews yet. Enter the join code below to join your first crew!</Typography>
                                    {/* </TableContainer> */}
                                </>)
                            }
                            <div className='flex-row' style={{ gap: '10px', marginTop: '20px' }}>
                                <TextField
                                    value={joinCode}
                                    label='Enter Join Code'
                                    style={{ flex: '1' }}
                                    onChange={onJoinCodeChange}
                                />
                                <Button className='action-button' style={{ flex: 1, alignSelf: 'center', maxWidth: '80px' }} onClick={joinCrew} disabled={joinCode.length < 19}>JOIN</Button>
                                {/* {props.userStore.errorMessage &&
                                    <Typography textAlign={'center'} sx={{ backgroundColor: 'error.dark', margin: '20px', padding: '10px' }}>{props.userStore.errorMessage}</Typography>
                                }
                                {successMessage &&
                                    <Typography textAlign={'center'} sx={{ backgroundColor: 'success.dark', margin: '20px', padding: '10px' }}>{successMessage}</Typography>
                                } */}
                            </div>
                        </div>

                        {/* <div id='your-friends-div' className='flex-column half-container' ref={friendsRef}
                        style={{
                            maxHeight: '40vh'
                        }}>
                        <div className='flex-row'>
                            <Typography fontWeight={'700'} fontSize={'1.2em'} style={{ alignSelf: 'center' }}>Your Friends</Typography>
                            <div className='section-button-group'>
                                <IconButton onClick={handleFriendsSearchClick}>
                                    <Search />
                                </IconButton>
                                <Popover
                                    id={friendsSearchPopoverId}
                                    open={isFriendsSearchOpen}
                                    anchorEl={friendsSearchAnchorEl}
                                    onClose={handleFriendsSearchClose}
                                    anchorReference='anchorPosition'
                                    anchorPosition={{ top: pageHeaderHeight + 20, left: 0 }}
                                    PaperProps={{
                                        style: { width: '100%', borderRadius: '20px' },
                                    }}
                                >
                                    <div style={{
                                        marginTop: '6px'
                                    }}>
                                        <FriendsSearch userStore={props.userStore} onFriendSelected={handleSelectedFriend} />
                                    </div>
                                </Popover>
                                <Popover
                                    id={friendsDetailPopoverId}
                                    open={isFriendsDetailOpen}
                                    anchorEl={friendsDetailAnchorEl}
                                    onClose={handleFriendsDetailClose}
                                    anchorReference='anchorPosition'
                                    anchorPosition={{ top: pageHeaderHeight + 20, left: 0 }}
                                    PaperProps={{
                                        style: { width: '100%' },
                                    }}
                                >
                                    <div style={{
                                        padding: '16px'
                                    }}>
                                        <FriendsDetail userStore={props.userStore} selectedFriend={selectedFriend} />
                                    </div>
                                </Popover>
                                <Popover
                                    id={friendsFavoritesPopoverId}
                                    open={isFriendsFavoritesOpen}
                                    anchorEl={friendsFavoritesAnchorEl}
                                    onClose={handleFriendsFavoritesClose}
                                    anchorReference='anchorPosition'
                                    anchorPosition={{ top: pageHeaderHeight + 20, left: 0 }}
                                    PaperProps={{
                                        style: { width: '100%' },
                                    }}
                                >
                                    <div style={{
                                        padding: '16px'
                                    }}>
                                        <FriendsFavorites userStore={props.userStore} />
                                    </div>
                                </Popover>
                                {/* <IconButton>
                                    <Add />
                                </IconButton> */}
                        {/* </div>
                        </div>
                        <div id='your-friends-list' className='flex-column' style={{ overflow: 'auto', marginBottom: '-5px' }}> */}
                        {/* <FriendsDetail userStore={props.userStore} selectedFriend={selectedFriend} /> */}
                        {/* <FriendsGrid
                        friends={props.userStore.usersWithoutMe}
                        onToggleFavorite={(friend) => props.userStore.toggleFriendAsFavorite(friend)}
                        onFriendClicked={(friend) => handleSelectedFriend(friend)}
                    />*/}
                        {/* {new Array(25).fill(0).map(i => <div>Test</div>)} */}
                        {/* <FriendsImageList friends={props.userStore.allFriendsFromCrews}/> */}
                        {/* </div>
                        <div id='friends-buttons' className='flex-row buttons-container'>
                            <Button className='action-button' onClick={handleFriendsFavoritesOpen}>Manage Favorites</Button>
                            <Button className='action-button'>Invite</Button>
                        </div> */}
                        {/* </div> */}
                    </div>
                }
            </div>

        </>
    );
};

export default observer(CrewsPage);
