import { Backdrop, CircularProgress } from '@mui/material';

export const Spinner = () => (
    <div className='full-size-div'
        style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/festival_2796_1290.jpg)`,
            alignItems: 'center',
            backgroundSize: 'cover'
        }}>
        <Backdrop
            sx={{ zIndex: 10000 }}
            open={true}
        >
            <CircularProgress thickness={6} size={'10rem'} />
        </Backdrop>
        {/* <CircularProgress size='10rem' color='inherit' /> */}
    </div>
);
