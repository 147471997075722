import {
    FormControl,
    FormControlLabel, Menu, Radio, RadioGroup
} from '@mui/material';
import { observer } from 'mobx-react';
import { SessionStore, TimeFilterType } from '../../state';
import './TimeFilter.scss';

interface TimeFilterProps {
    id: string;
    anchorEl?: Element | null;
    handleClose?: (event) => void;
    sessionStore: SessionStore,
    selectedTimeType?: string;
}

const InnerDiv = observer((props: TimeFilterProps) => {
    const handleChange = (event: any) => {
        const selection = event.target.value;
        props.sessionStore.setTimeFilter(selection);
        if (props.handleClose) {
            props.handleClose(null);
        }
    }
    return (
        <FormControl>
            <RadioGroup
                name='controlled-radio-buttons-group'
                value={props.sessionStore.timeFilter}
                onChange={handleChange}
            >
                <FormControlLabel value={TimeFilterType.Default} control={<Radio />} label='Default (time slots with events)' />
                <FormControlLabel value={TimeFilterType.TwentyFourHours} control={<Radio />} label='24 Hrs (all time slots)' />
            </RadioGroup>
        </FormControl>
    );
});

const TimeFilter = (props: TimeFilterProps) => {
    return (
        props.anchorEl ?
            <Menu
                id={props.id}
                anchorEl={props.anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(props.anchorEl)}
                onClose={props.handleClose}
            >
                <InnerDiv {...props} />
            </Menu>
            :
            <InnerDiv {...props} />
    );
}

export default TimeFilter;
