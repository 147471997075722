import {
    FormControl,
    Menu
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { UserStore } from '../../state';
import { AutoCompleteWithAvatars } from '../autoCompleteWithAvatars';

interface CrewFilterProps {
    id: string;
    anchorEl?: Element | null;
    handleClose?: (event) => void;
    userStore: UserStore;
}

const InnerDiv = observer((props: CrewFilterProps) => {
    const allCrews = {
        id: 'All',
        name: 'All',
        avatar: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/c4/Globe_icon.svg/630px-Globe_icon.svg.png'
    };

    const handleSelectedCrewChange = (e, value) => {
        if (value === null || value?.id === 'All') {
            props.userStore.setSelectedCrewId(null);
        } else {
            props.userStore.setSelectedCrewId(value?.id.toString());
        }
    }

    return (
        <FormControl style={{ width: '100%' }}>
            <AutoCompleteWithAvatars
                id='crews-autocomplete-dropdown'
                label='Crew'
                aria-label='crews-autocomplete-dropdown'
                placeholder='Start Typing'
                multiple={false}
                items={[allCrews, ...props.userStore.allMyCrews]}
                selectedItems={props.userStore.selectedCrew || allCrews}
                handleChange={handleSelectedCrewChange}
            />
        </FormControl>);
});

const CrewFilter = (props: CrewFilterProps) => {
    return (
        props.anchorEl !== undefined ?
            <Menu
                id={props.id}
                anchorEl={props.anchorEl}
                keepMounted
                open={Boolean(props.anchorEl)}
                onClose={props.handleClose}
            >
                <InnerDiv {...props} />
            </Menu> :
            <InnerDiv {...props} />
    );
}

export default CrewFilter;
