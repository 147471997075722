import { makeAutoObservable } from 'mobx';
import { Subject, takeUntil } from 'rxjs';
import { GroupingType, Result, Session, User, UserSession } from '../models';
import { SessionService, UserService } from '../services';

export enum TimeFilterType {
    Default = 'Default',
    TwentyFourHours = 'TwentyFourHours'
}

export class SessionStore {
    // otherSessionUsers: { [key: string]: User[] } = {};
    // otherUsersSessions: { [key: string]: Session[] } = {};
    userIdFilter?: string = undefined;
    allSessions: Session[] = [];
    allUserSessions: UserSession[] = [];
    currentUser: User | null | undefined;
    timeFilter: TimeFilterType = localStorage.getItem('timeFilter') as TimeFilterType || TimeFilterType.Default;
    grouping: GroupingType = 'location';
    shouldWait: boolean = false;
    sessionId: string | undefined;

    constructor(private userService: UserService, private sessionService: SessionService, private stop$: Subject<boolean>) {
        makeAutoObservable(this, {
            getDateOfFirstSession: false,
            getDateOfLastSession: false,
            getOtherUserSessions: false,
            getUserSessions: false,
            getUsersForSession: false,
            findCurrentUserSession: false,
            isUserInSession: false,
            joinSession: false,
            leaveSession: false
        });
        this.userService.currentUser$.pipe(takeUntil(stop$)).subscribe(cu => this.setCurrentUser(cu));
        // TODO Filter by selected crew
        // this.userService.selectedCrew$.pipe(takeUntil(stop$)).subscribe(this.setCurrentUser);
        this.sessionService.allSessions$.pipe(takeUntil(stop$)).subscribe(sessions => this.setAllSessions(sessions));
        this.sessionService.allUserSessions$.pipe(takeUntil(stop$)).subscribe(sessions => this.setAllUserSessions(sessions));
        this.sessionService.shouldWait$.pipe(takeUntil(stop$)).subscribe(shouldWait => this.setShouldWait(shouldWait));
        //this.allSessionsSubject$.next(this.allSessions);
        // this.userService.currentUser$.subscribe(user => {
        //     if (user) {
        //         // TODO
        //         //this.mySessions = getMySessions(user);
        //         this.mySessions = [];
        //         // console.log('My Sessions: ');
        //         // console.log(this.mySessions.map(s => s.session.name));
        //         this.test(user);
        //         this.userSessionsSubject$.next([...this.mySessions, ...this.otherUsersSessions]);
        //     }
        // });
    }

    setSessionId = (sessionId: string | undefined) => {
        if (sessionId && sessionId !== this.sessionId) {
            this.sessionId = sessionId;
        }
    }

    setTimeFilter = (selectedTimeFilter: TimeFilterType) => {
        this.timeFilter = selectedTimeFilter;
        localStorage['timeFilter'] = selectedTimeFilter.toString();
    }

    setGrouping = (grouping: GroupingType) => {
        if (this.grouping !== grouping) {
            this.grouping = grouping;
        }
    }

    setCurrentUser = (user: User | null | undefined) => {
        this.currentUser = user;
    }

    setAllSessions = (sessions: Session[]) => {
        this.allSessions = sessions;
    }

    setAllUserSessions = (userSessions: UserSession[]) => {
        this.allUserSessions = userSessions;
    }

    setShouldWait = (shouldWait: boolean) => {
        this.shouldWait = shouldWait;
    }

    public get sessions(): Session[] {
        if (this.userIdFilter) {
            return this.getUserSessions(this.userIdFilter);
        } else {
            return this.allSessions;
        }
    }

    setUserIdFilter = (userId: string | undefined) => {
        this.userIdFilter = userId;
    }

    findCurrentUserSession = (sessionId: number | string): UserSession | undefined => {
        return this.currentUser ?
            this.allUserSessions.find(
                bs => bs.userID === this.currentUser!.id && bs.sessionID === sessionId
            ) : undefined;
    }

    isUserInSession = (sessionId: number | string): boolean => {
        return Boolean(this.findCurrentUserSession(sessionId));
    }

    getUserSessions = (userId: number | string): Session[] => {
        const sessionIds = this.allUserSessions.filter(userSession => userSession.userID === userId)
            .map(userSession => userSession.sessionID);
        return this.allSessions.filter(session => sessionIds.includes(session.id));
    }

    getUsersForSession = (sessionId: number | string): User[] => {
        return this.allUserSessions.filter(userSession => userSession.sessionID === sessionId)
            .map(userSession => userSession.user);
    }

    getOtherUserSessions = (userId: string | number): Session[] => {
        return this.allUserSessions.filter(userSession => userSession.user.id === userId)
            .map(userSession => userSession.session);
    }

    // TODO
    // getCrewUsersForSession = (sessionId: string) => {
    //     return this.allUserSessions
    // }

    joinSession = async (sessionId: string): Promise<Result | undefined> => {
        if (!this.isUserInSession(sessionId) && this.currentUser !== undefined) {
            const res = await this.sessionService.joinSession(sessionId)
            return res;
        }
    }

    leaveSession = async (sessionId: string): Promise<Result | undefined> => {
        const userSession = this.findCurrentUserSession(sessionId);
        if (userSession) {
            const res = await this.sessionService.leaveSession(userSession);
            return res;
        }
    }

    getDateOfFirstSession = (day: Date): Date | undefined => {
        const dates = this.allSessions.filter(session =>
            session.startDate.toLocaleDateString() === day.toLocaleDateString() ||
            session.endDate.toLocaleDateString() === day.toLocaleDateString())
            .map(session => session.startDate);
        if (dates.length > 0) {
            return dates.reduce((a, b) => a < b ? a : b);
        } else {
            return undefined;
        }
    }

    getDateOfLastSession = (day: Date): Date | undefined => {
        const todaysSessions = this.allSessions.filter(session => session.startDate.toLocaleDateString() === day.toLocaleDateString());
        if (todaysSessions.length) {
            const lastSession = todaysSessions.reduce((s1, s2) => s1.endDate > s2.endDate ? s1 : s2);
            return lastSession.endDate;
        } else {
            return undefined;
        }
        // // if (lastSession.endDate.toLocaleDateString() === day.toLocaleDateString()) {
        // //     return lastSession.endDate;
        // // } else {
        // //     // TODO: FIX THIS
        // //     return 
        // // }

        // const endDates = this.allSessions.filter(session => session.endDate.toLocaleDateString() === day.toLocaleDateString())
        //     .map(session => session.endDate);
        // const startDates =     
        // if (dates.length > 0) {
        //     return dates.reduce((a, b) => a > b ? a : b);
        // } else {
        //     return undefined;
        // }
    }
}
