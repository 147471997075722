import { ImageList, ImageListItem, ImageListItemBar } from '@mui/material';
import { memo } from 'react';
import { Event } from '../../models';

interface EventImageListProps {
    events: Event[];
    // onToggleFavorite: (event: Crew) => void;
    onEventClicked: (event: Event) => void;
}

const areEventsEqual = (prevProps: EventImageListProps, nextProps: EventImageListProps): boolean => {
    //TODO
    return false;
}
export const EventImageList = memo((props: EventImageListProps) => {
    const size = 116;
    // const onToggleFavorite = (e, crew: Crew) => {
    //     e.stopPropagation();
    //     props.onToggleFavorite(crew);
    // }
    return (
        <ImageList
            gap={12}
            sx={{
                margin: '5px 0px',
                gridAutoFlow: 'column',
                gridTemplateColumns: `repeat(minmax(${size}px,1fr)) !important`
                //gridAutoColumns: 'minmax(100px, 1fr)',
            }}
        >
            {props.events.map((event) => (
                <ImageListItem
                    key={event.id}
                    style={{ minHeight: `${size}px`, minWidth: `${size}px`, marginBottom: '10px' }}
                    onClick={() => props.onEventClicked(event)}
                >
                    <img alt={`Avatar image for event ${event.name}`} key={`${event.id}-avatar`} style={{ minWidth: size, minHeight: size }}
                        src={event.avatar} />
                    <ImageListItemBar
                        key={`${event.id}-favorite-icon`}
                        sx={{
                            background:
                                'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                                'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                        }}
                        // TODO: Uncomment when favoriting
                        // actionIcon={
                        //     <IconButton
                        //         sx={{ color: 'white' }}
                        //         aria-label={`star ${crew.name}`}
                        //     >
                        //         <StarBorder />
                        //     </IconButton>
                        // }
                        // actionPosition='left'
                        position='top'
                    />
                    <ImageListItemBar
                        key={`${event.id}-name`}
                        title={event.name}
                        position='below'
                    />
                </ImageListItem>
            ))}
        </ImageList >
    );

}, areEventsEqual);
