import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import { Crew, CrewResult } from '../../models';
import { useCallback, useEffect, useState } from 'react';
import { CircularProgress, IconButton, Paper, StepContent, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { isValidImage } from '../../util';
import { UserStore } from '../../state';
import { CopyAll, CopyAllOutlined } from '@mui/icons-material';

const steps = ['Name your crew', 'Link an avatar (Optional)', 'Confirm'];

interface CreateCrewStepperProps {
    userStore: UserStore;
    onCloseStepper: () => void;
}

const CreateCrewStepper = (props: CreateCrewStepperProps) => {
    const [activeStep, setActiveStep] = useState<number>(0);
    const [skipped, setSkipped] = useState<Set<number>>(new Set<number>());
    const [result, setResult] = useState<CrewResult | undefined>(undefined);
    const [crewName, setCrewName] = useState<string>('');
    const [crewNameErrorString, setCrewNameErrorString] = useState<string>('');
    const [crewNameError, setCrewNameError] = useState<boolean>(false);
    const [crewAvatar, setCrewAvatar] = useState<string>('');
    const [crewAvatarErrorString, setCrewAvatarErrorString] = useState<string>('');
    const [crewAvatarError, setCrewAvatarError] = useState<boolean>(false);
    const [isCreating, setIsCreating] = useState<boolean>(false);

    const theme = useTheme();

    useEffect(() => {

    }, [])

    const isStepOptional = useCallback((step: number) => {
        return step === 1;
    }, []);

    const isStepSkipped = useCallback((step: number) => {
        return skipped.has(step);
    }, []);

    const handleNext = useCallback(async () => {
        if (activeStep === 2) {
            setIsCreating(true);
            const result = await props.userStore.createCrew(crewName, crewAvatar);
            setResult(result);
            setIsCreating(false);
        }
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    }, [activeStep]);

    const handleBack = useCallback(() => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }, [activeStep]);

    const handleSkip = useCallback(() => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    }, [activeStep]);

    const handleReset = useCallback(() => {
        setActiveStep(0);
    }, []);

    const onCrewNameChange = useCallback((event: any) => {
        const newCrewName = event.target.value;
        if (newCrewName !== crewName) {
            setCrewName(newCrewName);
            const isInError = newCrewName.length === 0;
            setCrewNameError(isInError);
            setCrewNameErrorString(isInError ? 'Crew name cannot be blank' : '');
        }
    }, [crewName]);

    const onCrewAvatarChange = useCallback((event: any) => {
        const newCrewAvatar = event.target.value;
        if (newCrewAvatar !== crewAvatar) {
            setCrewAvatar(newCrewAvatar);
            const isInError = newCrewAvatar.length > 0 && !isValidImage(newCrewAvatar);
            setCrewAvatarError(isInError);
            setCrewAvatarErrorString(isInError ? 'Not a valid image' : '');
        }
    }, [crewAvatar]);

    const isButtonDisabled = useCallback((index: number): boolean => {
        if (index === 0) {
            return crewName.length === 0;
        } else if (index === 1) {
            return crewAvatarError;
        }
        return isCreating;
    }, [crewName, isCreating, crewAvatarError]);

    const copyCodeToClipboard = useCallback(async () => {
        if (!result || result.joinCode) {
            return;
        }
        try {
            await navigator.clipboard.writeText(result!.joinCode!);
        } catch (error) {
            console.log(error);
        }
    }, [result]);

    return (
        <>
            <Typography fontWeight={'900'} letterSpacing={'0.16em'} fontSize={'1.2em'} textAlign={'center'} style={{ marginTop: '20px' }}>CREATE CREW</Typography>
            <div className='flex-column'>
                <Box sx={{ maxWidth: 400 }}>
                    <Stepper activeStep={activeStep} orientation="vertical">
                        {steps.map((step, index) => (
                            <Step key={step}>
                                <StepLabel
                                // optional={
                                //     index === 2 ? (
                                //         <Typography variant="caption">Last step</Typography>
                                //     ) : null
                                // }
                                >
                                    {step}
                                </StepLabel>
                                <StepContent>
                                    {/* <Typography>{step}</Typography> */}
                                    {index === 0 && <>
                                        <TextField
                                            style={{ width: '100%', marginBottom: '10px' }}
                                            value={crewName}
                                            onChange={onCrewNameChange}
                                            error={crewNameError}
                                            helperText={crewNameErrorString}
                                        />
                                    </>}
                                    {index === 1 && <>
                                        <Typography>Paste the link of an image that represents your crew's avatar. The image must be available publicly and in the following format: .png, .svg, .jpeg, or .jpg.</Typography>
                                        <TextField
                                            style={{ width: '100%', marginBottom: '10px', marginTop: '10px' }}
                                            value={crewAvatar}
                                            onChange={onCrewAvatarChange}
                                            error={crewAvatarError}
                                            helperText={crewAvatarErrorString}
                                        />
                                        {crewAvatar && !crewAvatarError &&
                                            <img style={{ width: '128px', height: '128px', margin: '20px' }}
                                                alt={`Avatar image for crew`}
                                                src={crewAvatar} />
                                        }
                                    </>}
                                    {index === 2 && <span>
                                        <Typography>By clicking Finish, you'll create a new crew:</Typography>
                                        <Typography color={theme.palette.secondary.dark} fontWeight='bold'> {crewName} </Typography>
                                        <Typography>Once created, you can use the generated join code to invite people to your crew. You can promote other members to be admins alongside you. You can also change the crew name, avatar, or delete the crew from the Crews menu.</Typography>
                                    </span>}
                                    <Box sx={{ mb: 2 }}>
                                        <div>
                                            <Button
                                                variant="contained"
                                                onClick={handleNext}
                                                sx={{ mt: 1, mr: 1 }}
                                                disabled={isButtonDisabled(index)}
                                            >
                                                {index === steps.length - 1 ? 'Finish' : 'Continue'}
                                            </Button>
                                            <Button
                                                disabled={index === 0}
                                                onClick={handleBack}
                                                sx={{ mt: 1, mr: 1 }}
                                            >
                                                Back
                                            </Button>
                                        </div>
                                    </Box>
                                </StepContent>
                            </Step>
                        ))}
                    </Stepper>
                    {activeStep === steps.length && (
                        <Paper square elevation={0} sx={{ p: 3 }}>
                            {isCreating ?
                                <Typography>'Creating crew...'</Typography>
                                :
                                <>
                                    <Typography display='inline'>Created crew:</Typography>
                                    <Typography display='inline' color={theme.palette.secondary.dark} fontWeight='bold'> {crewName} </Typography>
                                    <Typography>Use the join code below to invite members!</Typography>
                                </>
                            }
                            {isCreating &&
                                <div className='flex-row' style={{ width: '100%', marginTop: '10px', justifyContent: 'center' }}>
                                    <CircularProgress thickness={6} size={'6rem'} />
                                </div>
                            }
                            {!isCreating && result && result.joinCode &&
                                <div className='flex-row' style={{ marginTop: '10px' }}>
                                    <Typography color='#29b6f6' fontSize='1.2rem'>{result.joinCode}</Typography>
                                    <IconButton onClick={copyCodeToClipboard}>
                                        <CopyAllOutlined />
                                    </IconButton>
                                </div>
                            }
                            {!isCreating &&
                                <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                                    Reset Form
                                </Button>
                            }
                        </Paper>
                    )}
                </Box>
                {/* <Box sx={{ width: '100%' }}>
                    <Stepper activeStep={activeStep}>
                        {steps.map((label, index) => {
                            const stepProps: { completed?: boolean } = {};
                            const labelProps: {
                                optional?: React.ReactNode;
                            } = {};
                            if (isStepOptional(index)) {
                                labelProps.optional = (
                                    <Typography variant="caption">(Optional)</Typography>
                                );
                            }
                            if (isStepSkipped(index)) {
                                stepProps.completed = false;
                            }
                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                    {activeStep === steps.length ? (
                        <>
                            <Typography sx={{ mt: 2, mb: 1 }}>
                                All steps completed - you&apos;re finished
                            </Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                <Box sx={{ flex: '1 1 auto' }} />
                                <Button onClick={handleReset}>Reset</Button>
                            </Box>
                        </>
                    ) : (
                        <>
                            <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                <Button
                                    color="inherit"
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    sx={{ mr: 1 }}
                                >
                                    Back
                                </Button>
                                <Box sx={{ flex: '1 1 auto' }} />
                                {isStepOptional(activeStep) && (
                                    <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                                        Skip
                                    </Button>
                                )}
                                <Button onClick={handleNext}>
                                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                </Button>
                            </Box>
                        </>
                    )}
                </Box> */}
                <div className='flex-row' style={{ width: '100%', flex: '1', gap: '15px', marginTop: '20px' }}>
                    {result && result.status === 'success' &&
                        <Button
                            onClick={props.onCloseStepper}
                            style={{ flex: '1' }}
                            variant='contained'
                            disabled={result === undefined}
                            color='success'>
                            Done
                        </Button>
                    }
                    <Button
                        onClick={props.onCloseStepper}
                        style={{ flex: '1' }}
                        variant='outlined'
                        color='info'>
                        Cancel
                    </Button>
                </div>
            </div >
        </>
    );
}

export default observer(CreateCrewStepper);