/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      email
      name
      avatar
      crews {
        items {
          id
          userId
          crewId
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          crew {
            id
            name
            avatar
            members {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            admins {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoritedByUsers {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      adminCrews {
        items {
          id
          userId
          crewId
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          crew {
            id
            name
            avatar
            members {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            admins {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoritedByUsers {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      favoriteCrews {
        items {
          id
          userId
          crewId
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          crew {
            id
            name
            avatar
            members {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            admins {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoritedByUsers {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      userPreference {
        id
        user {
          id
          email
          name
          avatar
          crews {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          adminCrews {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          favoriteCrews {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          userPreference {
            id
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            selectedEventId
            userIdFilter
            timeFilter
            grouping
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userPreferenceUserId
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserPreferenceId
          owner
          __typename
        }
        selectedEventId
        userIdFilter
        timeFilter
        grouping
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userPreferenceUserId
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userUserPreferenceId
      owner
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        name
        avatar
        crews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        adminCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        favoriteCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userPreference {
          id
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          selectedEventId
          userIdFilter
          timeFilter
          grouping
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userPreferenceUserId
          owner
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserPreferenceId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUsers = /* GraphQL */ `
  query SyncUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        email
        name
        avatar
        crews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        adminCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        favoriteCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userPreference {
          id
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          selectedEventId
          userIdFilter
          timeFilter
          grouping
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userPreferenceUserId
          owner
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserPreferenceId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUserPreference = /* GraphQL */ `
  query GetUserPreference($id: ID!) {
    getUserPreference(id: $id) {
      id
      user {
        id
        email
        name
        avatar
        crews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        adminCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        favoriteCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userPreference {
          id
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          selectedEventId
          userIdFilter
          timeFilter
          grouping
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userPreferenceUserId
          owner
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserPreferenceId
        owner
        __typename
      }
      selectedEventId
      userIdFilter
      timeFilter
      grouping
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userPreferenceUserId
      owner
      __typename
    }
  }
`;
export const listUserPreferences = /* GraphQL */ `
  query ListUserPreferences(
    $filter: ModelUserPreferenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserPreferences(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user {
          id
          email
          name
          avatar
          crews {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          adminCrews {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          favoriteCrews {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          userPreference {
            id
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            selectedEventId
            userIdFilter
            timeFilter
            grouping
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userPreferenceUserId
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserPreferenceId
          owner
          __typename
        }
        selectedEventId
        userIdFilter
        timeFilter
        grouping
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userPreferenceUserId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUserPreferences = /* GraphQL */ `
  query SyncUserPreferences(
    $filter: ModelUserPreferenceFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserPreferences(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        user {
          id
          email
          name
          avatar
          crews {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          adminCrews {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          favoriteCrews {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          userPreference {
            id
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            selectedEventId
            userIdFilter
            timeFilter
            grouping
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userPreferenceUserId
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserPreferenceId
          owner
          __typename
        }
        selectedEventId
        userIdFilter
        timeFilter
        grouping
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userPreferenceUserId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getCrew = /* GraphQL */ `
  query GetCrew($id: ID!) {
    getCrew(id: $id) {
      id
      name
      avatar
      members {
        items {
          id
          userId
          crewId
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          crew {
            id
            name
            avatar
            members {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            admins {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoritedByUsers {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      admins {
        items {
          id
          userId
          crewId
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          crew {
            id
            name
            avatar
            members {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            admins {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoritedByUsers {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      favoritedByUsers {
        items {
          id
          userId
          crewId
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          crew {
            id
            name
            avatar
            members {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            admins {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoritedByUsers {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listCrews = /* GraphQL */ `
  query ListCrews(
    $filter: ModelCrewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCrews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        avatar
        members {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        admins {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        favoritedByUsers {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncCrews = /* GraphQL */ `
  query SyncCrews(
    $filter: ModelCrewFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCrews(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        avatar
        members {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        admins {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        favoritedByUsers {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getVenue = /* GraphQL */ `
  query GetVenue($id: ID!) {
    getVenue(id: $id) {
      id
      name
      address
      info
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listVenues = /* GraphQL */ `
  query ListVenues(
    $filter: ModelVenueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVenues(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        address
        info
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncVenues = /* GraphQL */ `
  query SyncVenues(
    $filter: ModelVenueFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncVenues(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        address
        info
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      name
      startDate
      endDate
      venueId
      venue {
        id
        name
        address
        info
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      info
      avatar
      isPublic
      isSponsored
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        startDate
        endDate
        venueId
        venue {
          id
          name
          address
          info
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        info
        avatar
        isPublic
        isSponsored
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncEvents = /* GraphQL */ `
  query SyncEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEvents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        startDate
        endDate
        venueId
        venue {
          id
          name
          address
          info
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        info
        avatar
        isPublic
        isSponsored
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getLocation = /* GraphQL */ `
  query GetLocation($id: ID!) {
    getLocation(id: $id) {
      id
      eventId
      event {
        id
        name
        startDate
        endDate
        venueId
        venue {
          id
          name
          address
          info
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        info
        avatar
        isPublic
        isSponsored
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      name
      info
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listLocations = /* GraphQL */ `
  query ListLocations(
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        eventId
        event {
          id
          name
          startDate
          endDate
          venueId
          venue {
            id
            name
            address
            info
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          info
          avatar
          isPublic
          isSponsored
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        name
        info
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncLocations = /* GraphQL */ `
  query SyncLocations(
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLocations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        eventId
        event {
          id
          name
          startDate
          endDate
          venueId
          venue {
            id
            name
            address
            info
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          info
          avatar
          isPublic
          isSponsored
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        name
        info
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getSession = /* GraphQL */ `
  query GetSession($id: ID!) {
    getSession(id: $id) {
      id
      eventId
      event {
        id
        name
        startDate
        endDate
        venueId
        venue {
          id
          name
          address
          info
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        info
        avatar
        isPublic
        isSponsored
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      locationId
      location {
        id
        eventId
        event {
          id
          name
          startDate
          endDate
          venueId
          venue {
            id
            name
            address
            info
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          info
          avatar
          isPublic
          isSponsored
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        name
        info
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      name
      startDate
      endDate
      info
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listSessions = /* GraphQL */ `
  query ListSessions(
    $filter: ModelSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSessions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        eventId
        event {
          id
          name
          startDate
          endDate
          venueId
          venue {
            id
            name
            address
            info
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          info
          avatar
          isPublic
          isSponsored
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        locationId
        location {
          id
          eventId
          event {
            id
            name
            startDate
            endDate
            venueId
            venue {
              id
              name
              address
              info
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            info
            avatar
            isPublic
            isSponsored
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          name
          info
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        name
        startDate
        endDate
        info
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncSessions = /* GraphQL */ `
  query SyncSessions(
    $filter: ModelSessionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSessions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        eventId
        event {
          id
          name
          startDate
          endDate
          venueId
          venue {
            id
            name
            address
            info
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          info
          avatar
          isPublic
          isSponsored
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        locationId
        location {
          id
          eventId
          event {
            id
            name
            startDate
            endDate
            venueId
            venue {
              id
              name
              address
              info
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            info
            avatar
            isPublic
            isSponsored
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          name
          info
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        name
        startDate
        endDate
        info
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUserSession = /* GraphQL */ `
  query GetUserSession($id: ID!) {
    getUserSession(id: $id) {
      id
      eventId
      event {
        id
        name
        startDate
        endDate
        venueId
        venue {
          id
          name
          address
          info
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        info
        avatar
        isPublic
        isSponsored
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      sessionId
      session {
        id
        eventId
        event {
          id
          name
          startDate
          endDate
          venueId
          venue {
            id
            name
            address
            info
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          info
          avatar
          isPublic
          isSponsored
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        locationId
        location {
          id
          eventId
          event {
            id
            name
            startDate
            endDate
            venueId
            venue {
              id
              name
              address
              info
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            info
            avatar
            isPublic
            isSponsored
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          name
          info
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        name
        startDate
        endDate
        info
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      userId
      user {
        id
        email
        name
        avatar
        crews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        adminCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        favoriteCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userPreference {
          id
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          selectedEventId
          userIdFilter
          timeFilter
          grouping
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userPreferenceUserId
          owner
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserPreferenceId
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listUserSessions = /* GraphQL */ `
  query ListUserSessions(
    $filter: ModelUserSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserSessions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        eventId
        event {
          id
          name
          startDate
          endDate
          venueId
          venue {
            id
            name
            address
            info
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          info
          avatar
          isPublic
          isSponsored
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        sessionId
        session {
          id
          eventId
          event {
            id
            name
            startDate
            endDate
            venueId
            venue {
              id
              name
              address
              info
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            info
            avatar
            isPublic
            isSponsored
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          locationId
          location {
            id
            eventId
            event {
              id
              name
              startDate
              endDate
              venueId
              venue {
                id
                name
                address
                info
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              info
              avatar
              isPublic
              isSponsored
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            name
            info
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          name
          startDate
          endDate
          info
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        userId
        user {
          id
          email
          name
          avatar
          crews {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          adminCrews {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          favoriteCrews {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          userPreference {
            id
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            selectedEventId
            userIdFilter
            timeFilter
            grouping
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userPreferenceUserId
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserPreferenceId
          owner
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUserSessions = /* GraphQL */ `
  query SyncUserSessions(
    $filter: ModelUserSessionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserSessions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        eventId
        event {
          id
          name
          startDate
          endDate
          venueId
          venue {
            id
            name
            address
            info
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          info
          avatar
          isPublic
          isSponsored
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        sessionId
        session {
          id
          eventId
          event {
            id
            name
            startDate
            endDate
            venueId
            venue {
              id
              name
              address
              info
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            info
            avatar
            isPublic
            isSponsored
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          locationId
          location {
            id
            eventId
            event {
              id
              name
              startDate
              endDate
              venueId
              venue {
                id
                name
                address
                info
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              info
              avatar
              isPublic
              isSponsored
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            name
            info
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          name
          startDate
          endDate
          info
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        userId
        user {
          id
          email
          name
          avatar
          crews {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          adminCrews {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          favoriteCrews {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          userPreference {
            id
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            selectedEventId
            userIdFilter
            timeFilter
            grouping
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userPreferenceUserId
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserPreferenceId
          owner
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getJoinCrewCode = /* GraphQL */ `
  query GetJoinCrewCode($id: ID!) {
    getJoinCrewCode(id: $id) {
      id
      crewId
      crew {
        id
        name
        avatar
        members {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        admins {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        favoritedByUsers {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      code
      expireDate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listJoinCrewCodes = /* GraphQL */ `
  query ListJoinCrewCodes(
    $filter: ModelJoinCrewCodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJoinCrewCodes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        crewId
        crew {
          id
          name
          avatar
          members {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          admins {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          favoritedByUsers {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        code
        expireDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncJoinCrewCodes = /* GraphQL */ `
  query SyncJoinCrewCodes(
    $filter: ModelJoinCrewCodeFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncJoinCrewCodes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        crewId
        crew {
          id
          name
          avatar
          members {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          admins {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          favoritedByUsers {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        code
        expireDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAllowedUser = /* GraphQL */ `
  query GetAllowedUser($id: ID!) {
    getAllowedUser(id: $id) {
      id
      email
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAllowedUsers = /* GraphQL */ `
  query ListAllowedUsers(
    $filter: ModelAllowedUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAllowedUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAllowedUsers = /* GraphQL */ `
  query SyncAllowedUsers(
    $filter: ModelAllowedUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAllowedUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        email
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getCrewMember = /* GraphQL */ `
  query GetCrewMember($id: ID!) {
    getCrewMember(id: $id) {
      id
      userId
      crewId
      user {
        id
        email
        name
        avatar
        crews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        adminCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        favoriteCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userPreference {
          id
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          selectedEventId
          userIdFilter
          timeFilter
          grouping
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userPreferenceUserId
          owner
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserPreferenceId
        owner
        __typename
      }
      crew {
        id
        name
        avatar
        members {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        admins {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        favoritedByUsers {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listCrewMembers = /* GraphQL */ `
  query ListCrewMembers(
    $filter: ModelCrewMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCrewMembers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        crewId
        user {
          id
          email
          name
          avatar
          crews {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          adminCrews {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          favoriteCrews {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          userPreference {
            id
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            selectedEventId
            userIdFilter
            timeFilter
            grouping
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userPreferenceUserId
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserPreferenceId
          owner
          __typename
        }
        crew {
          id
          name
          avatar
          members {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          admins {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          favoritedByUsers {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncCrewMembers = /* GraphQL */ `
  query SyncCrewMembers(
    $filter: ModelCrewMemberFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCrewMembers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userId
        crewId
        user {
          id
          email
          name
          avatar
          crews {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          adminCrews {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          favoriteCrews {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          userPreference {
            id
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            selectedEventId
            userIdFilter
            timeFilter
            grouping
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userPreferenceUserId
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserPreferenceId
          owner
          __typename
        }
        crew {
          id
          name
          avatar
          members {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          admins {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          favoritedByUsers {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const crewMembersByUserId = /* GraphQL */ `
  query CrewMembersByUserId(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCrewMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    crewMembersByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        crewId
        user {
          id
          email
          name
          avatar
          crews {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          adminCrews {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          favoriteCrews {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          userPreference {
            id
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            selectedEventId
            userIdFilter
            timeFilter
            grouping
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userPreferenceUserId
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserPreferenceId
          owner
          __typename
        }
        crew {
          id
          name
          avatar
          members {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          admins {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          favoritedByUsers {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const crewMembersByCrewId = /* GraphQL */ `
  query CrewMembersByCrewId(
    $crewId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCrewMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    crewMembersByCrewId(
      crewId: $crewId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        crewId
        user {
          id
          email
          name
          avatar
          crews {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          adminCrews {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          favoriteCrews {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          userPreference {
            id
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            selectedEventId
            userIdFilter
            timeFilter
            grouping
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userPreferenceUserId
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserPreferenceId
          owner
          __typename
        }
        crew {
          id
          name
          avatar
          members {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          admins {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          favoritedByUsers {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getCrewAdmin = /* GraphQL */ `
  query GetCrewAdmin($id: ID!) {
    getCrewAdmin(id: $id) {
      id
      userId
      crewId
      user {
        id
        email
        name
        avatar
        crews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        adminCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        favoriteCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userPreference {
          id
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          selectedEventId
          userIdFilter
          timeFilter
          grouping
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userPreferenceUserId
          owner
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserPreferenceId
        owner
        __typename
      }
      crew {
        id
        name
        avatar
        members {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        admins {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        favoritedByUsers {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listCrewAdmins = /* GraphQL */ `
  query ListCrewAdmins(
    $filter: ModelCrewAdminFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCrewAdmins(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        crewId
        user {
          id
          email
          name
          avatar
          crews {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          adminCrews {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          favoriteCrews {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          userPreference {
            id
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            selectedEventId
            userIdFilter
            timeFilter
            grouping
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userPreferenceUserId
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserPreferenceId
          owner
          __typename
        }
        crew {
          id
          name
          avatar
          members {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          admins {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          favoritedByUsers {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncCrewAdmins = /* GraphQL */ `
  query SyncCrewAdmins(
    $filter: ModelCrewAdminFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCrewAdmins(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userId
        crewId
        user {
          id
          email
          name
          avatar
          crews {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          adminCrews {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          favoriteCrews {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          userPreference {
            id
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            selectedEventId
            userIdFilter
            timeFilter
            grouping
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userPreferenceUserId
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserPreferenceId
          owner
          __typename
        }
        crew {
          id
          name
          avatar
          members {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          admins {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          favoritedByUsers {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const crewAdminsByUserId = /* GraphQL */ `
  query CrewAdminsByUserId(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCrewAdminFilterInput
    $limit: Int
    $nextToken: String
  ) {
    crewAdminsByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        crewId
        user {
          id
          email
          name
          avatar
          crews {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          adminCrews {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          favoriteCrews {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          userPreference {
            id
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            selectedEventId
            userIdFilter
            timeFilter
            grouping
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userPreferenceUserId
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserPreferenceId
          owner
          __typename
        }
        crew {
          id
          name
          avatar
          members {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          admins {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          favoritedByUsers {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const crewAdminsByCrewId = /* GraphQL */ `
  query CrewAdminsByCrewId(
    $crewId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCrewAdminFilterInput
    $limit: Int
    $nextToken: String
  ) {
    crewAdminsByCrewId(
      crewId: $crewId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        crewId
        user {
          id
          email
          name
          avatar
          crews {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          adminCrews {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          favoriteCrews {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          userPreference {
            id
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            selectedEventId
            userIdFilter
            timeFilter
            grouping
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userPreferenceUserId
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserPreferenceId
          owner
          __typename
        }
        crew {
          id
          name
          avatar
          members {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          admins {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          favoritedByUsers {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getFavoriteCrew = /* GraphQL */ `
  query GetFavoriteCrew($id: ID!) {
    getFavoriteCrew(id: $id) {
      id
      userId
      crewId
      user {
        id
        email
        name
        avatar
        crews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        adminCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        favoriteCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userPreference {
          id
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          selectedEventId
          userIdFilter
          timeFilter
          grouping
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userPreferenceUserId
          owner
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserPreferenceId
        owner
        __typename
      }
      crew {
        id
        name
        avatar
        members {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        admins {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        favoritedByUsers {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listFavoriteCrews = /* GraphQL */ `
  query ListFavoriteCrews(
    $filter: ModelFavoriteCrewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFavoriteCrews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        crewId
        user {
          id
          email
          name
          avatar
          crews {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          adminCrews {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          favoriteCrews {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          userPreference {
            id
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            selectedEventId
            userIdFilter
            timeFilter
            grouping
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userPreferenceUserId
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserPreferenceId
          owner
          __typename
        }
        crew {
          id
          name
          avatar
          members {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          admins {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          favoritedByUsers {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncFavoriteCrews = /* GraphQL */ `
  query SyncFavoriteCrews(
    $filter: ModelFavoriteCrewFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFavoriteCrews(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userId
        crewId
        user {
          id
          email
          name
          avatar
          crews {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          adminCrews {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          favoriteCrews {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          userPreference {
            id
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            selectedEventId
            userIdFilter
            timeFilter
            grouping
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userPreferenceUserId
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserPreferenceId
          owner
          __typename
        }
        crew {
          id
          name
          avatar
          members {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          admins {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          favoritedByUsers {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const favoriteCrewsByUserId = /* GraphQL */ `
  query FavoriteCrewsByUserId(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelFavoriteCrewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    favoriteCrewsByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        crewId
        user {
          id
          email
          name
          avatar
          crews {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          adminCrews {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          favoriteCrews {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          userPreference {
            id
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            selectedEventId
            userIdFilter
            timeFilter
            grouping
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userPreferenceUserId
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserPreferenceId
          owner
          __typename
        }
        crew {
          id
          name
          avatar
          members {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          admins {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          favoritedByUsers {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const favoriteCrewsByCrewId = /* GraphQL */ `
  query FavoriteCrewsByCrewId(
    $crewId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelFavoriteCrewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    favoriteCrewsByCrewId(
      crewId: $crewId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        crewId
        user {
          id
          email
          name
          avatar
          crews {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          adminCrews {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          favoriteCrews {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          userPreference {
            id
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            selectedEventId
            userIdFilter
            timeFilter
            grouping
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userPreferenceUserId
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserPreferenceId
          owner
          __typename
        }
        crew {
          id
          name
          avatar
          members {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          admins {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          favoritedByUsers {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
