import { Crew, CrewUser, Location, User, Venue, Event, Session, UserSession, JoinCode } from '../models';
import * as subscriptions from '../graphql/subscriptions';
import * as queries from '../graphql/queries';
import * as customQueries from '../graphql/customQueries';
import * as mutations from '../graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';
import { Observable } from 'rxjs';
import * as GraphQLAPI from '../API';

export interface CrewResult {
    id: string;
    crew: {
        avatar?: string,
        id: string,
        name: string,
        members: {
            items: [
                {
                    user: GraphQLAPI.User
                }
            ]
        }
        admins: {
            items: [
                {
                    user: GraphQLAPI.User
                }
            ]
        },
        _version: number,
        _lastChangedAt: number
    }
}

export interface FavoriteFriendResult {
    id: string;
    favoriteFriendId: string;
    userId: string;
    favoriteFriend: User;
}

export interface ListUsersQueryResult {
    data: {
        listUsers: {
            items: User[] | null
        }
    }
}

export interface ListCrewsQueryResult {
    data: {
        listCrews: {
            items: Crew[] | null
        }
    }
}

export interface ListCrewMembersQueryResult {
    data: {
        listCrewMembers: {
            items: CrewResult[]
        }
    }
}

export interface ListCrewAdminsQueryResult {
    data: {
        listCrewAdmins: {
            items: CrewResult[]
        }
    }
}

export interface ListFavoriteFriendsQueryResult {
    data: {
        listFavoriteFriends: {
            items: FavoriteFriendResult[]
        }
    }
}

export interface CreateUserQueryResult {
    data: {
        createUser: User | null
    }
}

export interface CreateCrewQueryResult {
    data: {
        createCrew: Crew | null
    }
}

export interface CreateCrewAdminQueryResult {
    data: {
        createCrewAdmin: CrewUser | null
    }
}

export interface CreateCrewMemberQueryResult {
    data: {
        createCrewMember: CrewUser | null
    }
}

export interface CreateJoinCrewCodeResult {
    data: {
        createJoinCrewCode: JoinCode | null
    }
}

export interface ListJoinCrewQueryResult {
    data: {
        listJoinCrewCodes: {
            items: JoinCode[] | null
        }
    }
}

export interface CreateVenueQueryResult {
    data: {
        createVenue: Venue | null
    }
}

export interface GetVenueQueryResult {
    data: {
        getVenue: Venue | null
    }
}

export interface CreateEventQueryResult {
    data: {
        createEvent: Event | null
    }
}

export interface GetEventQueryResult {
    data: {
        getEvent: Event | null
    }
}

export interface CreateLocationQueryResult {
    data: {
        createLocation: Location | null
    }
}

export interface GetLocationQueryResult {
    data: {
        getLocation: Location | null
    }
}

export interface ListLocationsQueryResult {
    data: {
        listLocations: {
            items: Location[] | null
        }
    }
}

export interface ListEventsQueryResult {
    data: {
        listEvents: {
            items: GraphQLAPI.Event[] | null
        }
    }
}

export interface CreateSessionQueryResult {
    data: {
        createSession: GraphQLAPI.Session | null
    }
}

export interface GetSessionQueryResult {
    data: {
        getSession: GraphQLAPI.Session | null
    }
}

export interface ListSessionsQueryResult {
    data: {
        listSessions: {
            items: GraphQLAPI.Session[] | null
        }
    }
}

export interface ListUserSessionsQueryResult {
    data: {
        listUserSessions: {
            items: GraphQLAPI.UserSession[] | null
        }
    }
}

export interface DeleteUserSessionResult {
    data: {
        deleteUserSession: GraphQLAPI.UserSession | null
    }
}

export interface CreateUserSessionResult {
    data: {
        createUserSession: GraphQLAPI.UserSession | null
    }
}

export const findUser = async (email: string): Promise<User | undefined> => {
    const res = await (API.graphql(
        graphqlOperation(queries.listUsers, {
            filter: {
                email: {
                    eq: email
                }
            }
        })
    ) as unknown as Promise<ListUsersQueryResult>);
    const items = res.data.listUsers.items;
    if (items && items.length === 1) {
        return items[0];
    } else if (items && items.length > 1) {
        throw (new Error(`More than 1 user found with email: ` + email));
    } else {
        return undefined;
    }
}

export const getAllUsers = async (): Promise<User[]> => {
    const res = await (API.graphql(graphqlOperation(queries.listUsers)) as unknown as Promise<ListUsersQueryResult>);
    return res.data.listUsers.items || [];
}

export const createUser = async (user: GraphQLAPI.CreateUserInput): Promise<User | null> => {
    const res = await (API.graphql(
        graphqlOperation(mutations.createUser, {
            input: {
                email: user.email,
                name: user.name,
                avatar: user.avatar
            }
        })
    ) as unknown as Promise<CreateUserQueryResult>);
    return res.data.createUser;
}

export const createCrewMember = async (user: GraphQLAPI.CreateCrewMemberInput): Promise<CrewUser | null> => {
    const res = await (API.graphql(
        graphqlOperation(mutations.createCrewMember, {
            input: {
                userId: user.userId,
                crewId: user.crewId
            }
        })
    ) as unknown as Promise<CreateCrewMemberQueryResult>);
    return res.data.createCrewMember;
}

export const createCrew = async (crew: GraphQLAPI.CreateCrewInput): Promise<Crew | null> => {
    // Add user
    const res = await (API.graphql(
        graphqlOperation(mutations.createCrew, {
            input: {
                name: crew.name,
                avatar: crew.avatar
            }
        })
    ) as unknown as Promise<CreateCrewQueryResult>);
    return res.data.createCrew;
}

export const updateCrewName = async (updatedCrew: Crew) => {
    const res = await (API.graphql(
        graphqlOperation(mutations.updateCrew, {
            input: {
                id: updatedCrew.id,
                name: updatedCrew.name,
                _version: updatedCrew._version
            }
        })
    ) as unknown as Promise<any>);
    return res.data.updateCrew;
}

export const createJoinCode = async (joinCode: GraphQLAPI.CreateJoinCrewCodeInput): Promise<JoinCode | null> => {
    // Add user
    const res = await (API.graphql(
        graphqlOperation(mutations.createJoinCrewCode, {
            input: {
                crewId: joinCode.crewId,
                code: joinCode.code,
                expireDate: joinCode.expireDate,
                _version: 1
            }
        })
    ) as unknown as Promise<CreateJoinCrewCodeResult>);
    return res.data.createJoinCrewCode;
}

export const createCrewAdmin = async (crew: Crew, owner: User): Promise<CrewUser | null> => {
    const res = await (API.graphql(
        graphqlOperation(mutations.createCrewAdmin, {
            input: {
                userId: owner.id,
                crewId: crew.id
            }
        })
    ) as unknown as Promise<CreateCrewAdminQueryResult>);
    return res.data.createCrewAdmin;
}

export const getCrewsForMember = async (userId: string): Promise<Crew[]> => {
    const res = await (API.graphql({
        query: customQueries.getCrewsByMemberId,
        variables: {
            userId
        }
    }) as unknown as Promise<ListCrewMembersQueryResult>);
    return res.data.listCrewMembers.items?.map(val => transformCrew(val)) || [];
}

export const getCrewsForAdmin = async (userId: string): Promise<Crew[]> => {
    const res = await (API.graphql({
        query: customQueries.getCrewsByAdminId,
        variables: {
            userId
        }
    }) as unknown as Promise<ListCrewAdminsQueryResult>);
    return res.data.listCrewAdmins.items?.map(val => transformCrew(val)) || [];
}

// export const getFavoriteFriends = async (userId: string): Promise<User[]> => {
//     const res = await (API.graphql(
//         graphqlOperation(queries.listFavoriteFriends, {
//             filter: {
//                 userId: {
//                     eq: userId
//                 }
//             }
//         })
//     ) as unknown as Promise<ListFavoriteFriendsQueryResult>);
//     const items = res.data.listFavoriteFriends.items;
//     return items ? items.map(i => i.favoriteFriend) : [];
// }

// export const findFavoriteFriend = async (userId: string, friendUserId: string): Promise<FavoriteFriendResult | null> => {
//     const res = await (API.graphql(
//         graphqlOperation(queries.listFavoriteFriends, {
//             filter: {
//                 userId: {
//                     eq: userId
//                 },
//                 and:
//                 {
//                     favoriteFriendId:
//                     {
//                         eq: friendUserId
//                     }
//                 }
//             }
//         })
//     ) as unknown as Promise<ListFavoriteFriendsQueryResult>);
//     const items = res.data.listFavoriteFriends.items;
//     return items ? items.length > 0 ? items[0] : null : null;
// }

// export const createFavoriteFriend = async (userFavorite: GraphQLAPI.CreateFavoriteFriendInput): Promise<any> => {
//     try {
//         const res = await (API.graphql(
//             graphqlOperation(mutations.createFavoriteFriend, {
//                 input: userFavorite
//             })
//         ) as unknown as Promise<any>);
//         return res;
//     } catch (err) {
//         console.error(err);
//         return null;
//     }
// }

// export const deleteFavoriteFriend = async (userFavorite: GraphQLAPI.DeleteFavoriteFriendInput): Promise<any> => {
//     try {
//         const res = await (API.graphql(
//             graphqlOperation(mutations.deleteFavoriteFriend, {
//                 input: userFavorite
//             })
//         ) as unknown as Promise<any>);
//         return res;
//     } catch (err) {
//         console.error(err);
//         return null;
//     }
// }

export const createVenue = async (venue: GraphQLAPI.CreateVenueInput): Promise<Venue | null> => {
    const res = await (API.graphql(
        graphqlOperation(mutations.createVenue, {
            input: {
                name: venue.name,
                address: venue.address,
                info: venue.info
            }
        })
    ) as unknown as Promise<CreateVenueQueryResult>);
    return res.data.createVenue;
}

export const getVenue = async (venueId: string): Promise<Venue | null> => {
    const res = await (API.graphql({
        query: queries.getVenue,
        variables: {
            id: venueId
        }
    }) as unknown as Promise<GetVenueQueryResult>);
    return res.data.getVenue;
}

export const createEvent = async (event: GraphQLAPI.CreateEventInput): Promise<Event | null> => {
    const res = await (API.graphql(
        graphqlOperation(mutations.createEvent, {
            input: {
                name: event.name,
                avatar: event.avatar,
                startDate: event.startDate,
                endDate: event.endDate
            }
        })
    ) as unknown as Promise<CreateEventQueryResult>);
    return res.data.createEvent;
}

export const getEvent = async (eventId: string): Promise<Event | null> => {
    const res = await (API.graphql({
        query: queries.getEvent,
        variables: {
            id: eventId
        }
    }) as unknown as Promise<GetEventQueryResult>);
    return res.data.getEvent;
}

export const createLocation = async (location: GraphQLAPI.CreateLocationInput): Promise<Location | null> => {
    try {
        const res = await (API.graphql(
            graphqlOperation(mutations.createLocation, {
                input: {
                    eventId: location.eventId,
                    name: location.name,
                    info: location.info
                }
            })
        ) as unknown as Promise<CreateLocationQueryResult>);
        return res.data.createLocation;
    } catch (err) {
        console.log(err);
        return null;
    }
}

export const getLocation = async (locationId: string): Promise<Location | null> => {
    const res = await (API.graphql({
        query: queries.getLocation,
        variables: {
            id: locationId
        }
    }) as unknown as Promise<GetLocationQueryResult>);
    return res.data.getLocation;
}

export const getLocationsForVenueId = async (venueId: string): Promise<Location[]> => {
    const res = await (API.graphql(
        graphqlOperation(queries.listLocations, {
            filter: {
                venueId: {
                    eq: venueId
                }
            }
        })
    ) as unknown as Promise<ListLocationsQueryResult>);
    return res.data.listLocations.items || [];
}

export const getLocationsForEventId = async (eventId: string): Promise<Location[]> => {
    const res = await (API.graphql(
        graphqlOperation(queries.listLocations, {
            filter: {
                eventId: {
                    eq: eventId
                }
            }
        })
    ) as unknown as Promise<ListLocationsQueryResult>);
    return res.data.listLocations.items || [];
}

export const getEvents = async (): Promise<Event[]> => {
    const res = await (API.graphql(
        graphqlOperation(queries.listEvents)
    ) as unknown as Promise<ListEventsQueryResult>);
    return res.data.listEvents.items?.map(i => transformEvent(i)) || [];
}

export const createSession = async (session: GraphQLAPI.CreateSessionInput): Promise<Session | null> => {
    try {
        const sessionInput: GraphQLAPI.CreateSessionInput = {
            eventId: session.eventId,
            locationId: session.locationId,
            name: session.name,
            startDate: session.startDate,
            endDate: session.endDate,
            info: session.info
        }
        const res = await (API.graphql(
            graphqlOperation(mutations.createSession, {
                input: sessionInput
            })
        ) as unknown as Promise<CreateSessionQueryResult>);
        return res.data.createSession ? transformSession(res.data.createSession) : null;
    } catch (err) {
        console.log(err);
        return null;
    }
}

export const getSession = async (sessionId: string): Promise<Session | null> => {
    const res = await (API.graphql({
        query: queries.getSession,
        variables: {
            id: sessionId
        }
    }) as unknown as Promise<GetSessionQueryResult>);
    return res.data.getSession ? transformSession(res.data.getSession) : null;
}

export const getSessionsForEventId = async (eventId: string): Promise<Session[]> => {
    const res = await (API.graphql(
        graphqlOperation(queries.listSessions, {
            limit: 5000,
            filter: {
                eventId: {
                    eq: eventId
                }
            }
        })
    ) as unknown as Promise<ListSessionsQueryResult>);
    return res.data.listSessions.items?.map(i => transformSession(i)) || [];
}

// export const getAllSessions = async (): Promise<Session[]> => {
//     const res = await (API.graphql(graphqlOperation(queries.listSessions)) as unknown as Promise<ListSessionsQueryResult>);
//     return res.data.listSessions.items?.map(i => transformSession(i)) || [];
// }

export const getUserSessionsForCurrentUserAndEventId = async (userId: string, eventId: string): Promise<UserSession[]> => {
    const res = await (API.graphql(
        graphqlOperation(queries.listUserSessions, {
            limit: 20000,
            filter: {
                userId: {
                    eq: userId
                },
                eventId: {
                    eq: eventId
                }
            }
        })
    ) as unknown as Promise<ListUserSessionsQueryResult>);
    return res.data.listUserSessions.items?.map(i => transformUserSession(i)) || [];
}

export const getUserSessionsForEventId = async (eventId: string): Promise<UserSession[]> => {
    const res = await (API.graphql(
        graphqlOperation(queries.listUserSessions, {
            filter: {
                eventId: {
                    eq: eventId
                },
                _deleted: {
                    ne: true
                }
            }
        })
    ) as unknown as Promise<ListUserSessionsQueryResult>);
    return res.data.listUserSessions.items?.map(i => transformUserSession(i)) || [];
}

export interface UpdateUserQueryResult {
    data: {
        updateUser: User | null
    }
}

export const updateUser = async (user: GraphQLAPI.UpdateUserInput): Promise<User | null> => {
    const res = await (API.graphql(
        graphqlOperation(mutations.updateUser, {
            input: {
                id: user.id,
                email: user.email,
                name: user.name,
                avatar: user.avatar
            }
        })
    ) as unknown as Promise<UpdateUserQueryResult>);
    return res.data.updateUser;
}

export const findJoinCode = async (joinCode: string): Promise<JoinCode | undefined> => {
    const res = await (API.graphql(
        graphqlOperation(queries.listJoinCrewCodes, {
            filter: {
                code: {
                    eq: joinCode
                }
            }
        })
    ) as unknown as Promise<ListJoinCrewQueryResult>);
    const items = res.data.listJoinCrewCodes.items;
    if (items && items.length === 1) {
        return items[0];
    } else if (items && items.length > 1) {
        throw (new Error(`More than 1 join code found with code: ` + joinCode));
    } else {
        return undefined;
    }
}

export const getJoinCrewCodeForCrewIds = async (crewIds: string[]): Promise<JoinCode[] | null> => {
    const res = await (API.graphql(
        graphqlOperation(queries.listJoinCrewCodes, {
            filter: {
                or: crewIds.map(c => {
                    return {
                        crewId: {
                            eq: c
                        }
                    }
                })
            }
        })
    ) as unknown as Promise<ListJoinCrewQueryResult>);
    const items = res.data.listJoinCrewCodes.items;
    return items;
}

export const createUserSession = async (eventId: string, sessionId: string, userId: string): Promise<UserSession | null> => {
    const res = await (API.graphql(
        graphqlOperation(mutations.createUserSession, {
            input: {
                eventId: eventId,
                sessionId: sessionId,
                userId: userId
            }
        })
    ) as unknown as Promise<CreateUserSessionResult>);
    return res.data.createUserSession ? transformUserSession(res.data.createUserSession) : null;
}

export const deleteUserSession = async (userSessionId: string, version: number): Promise<GraphQLAPI.UserSession | null> => {
    const res = await (API.graphql(
        graphqlOperation(mutations.deleteUserSession, {
            input: {
                id: userSessionId,
                _version: version
            }
        })
    ) as unknown as Promise<DeleteUserSessionResult>);
    return res.data.deleteUserSession;
}

// Subscriptions
export const onCreateCrewAdmins = (userId: string): Observable<any> => {
    return (graphqlOperation(subscriptions.onCreateCrewAdmin, {
        filter: {
            userId: userId
        }
    }) as unknown as Observable<any>);
}

// Helpers
export const transformUser = (userApi: GraphQLAPI.User): User => {
    const user = {
        ...userApi,
        name: userApi.name || undefined,
        avatar: userApi.avatar || undefined
    };
    return user;
}

export const transformCrew = (crewResult: CrewResult): Crew => {
    return {
        id: crewResult.crew.id,
        avatar: crewResult.crew.avatar,
        name: crewResult.crew.name,
        members: crewResult.crew.members.items.map(i => transformUser(i.user)),
        admins: crewResult.crew.admins.items.map(i => transformUser(i.user)),
        _version: crewResult.crew._version,
        _lastChangedAt: crewResult.crew._lastChangedAt
    };
};

export const transformVenue = (venueApi: GraphQLAPI.Venue): Venue => {
    const venue: Venue = {
        ...venueApi,
        address: venueApi.address || undefined,
        info: venueApi.address || undefined
    }
    return venue;
}

export const transformEvent = (eventApi: GraphQLAPI.Event): Event => {
    const event: Event = {
        id: eventApi.id,
        name: eventApi.name,
        startDate: new Date(eventApi.startDate),
        endDate: new Date(eventApi.endDate),
        info: eventApi.info || undefined,
        venue: transformVenue(eventApi.venue),
        avatar: eventApi.avatar || undefined,
        _version: eventApi._version,
        _lastChangedAt: eventApi._lastChangedAt
    };
    return event;
}

export const transformLocation = (locationApi: GraphQLAPI.Location): Location => {
    const location: Location = {
        ...locationApi,
        event: transformEvent(locationApi.event),
        info: locationApi.info || undefined,
    };
    return location;
}

export const transformSession = (sessionApi: GraphQLAPI.Session): Session => {
    const session: Session = {
        id: sessionApi.id,
        name: sessionApi.name,
        event: transformEvent(sessionApi.event),
        location: transformLocation(sessionApi.location),
        startDate: new Date(sessionApi.startDate),
        endDate: new Date(sessionApi.endDate),
        info: sessionApi.info || undefined,
        _version: sessionApi._version,
        _lastChangedAt: sessionApi._lastChangedAt
    };
    return session;
};

export const transformUserSession = (sessionApi: GraphQLAPI.UserSession): UserSession => {
    const session: UserSession = {
        id: sessionApi.id,
        userID: sessionApi.userId,
        sessionID: sessionApi.sessionId,
        eventId: sessionApi.eventId,
        user: transformUser(sessionApi.user),
        session: transformSession(sessionApi.session),
        event: transformEvent(sessionApi.event),
        _version: sessionApi._version,
        _lastChangedAt: sessionApi._lastChangedAt,
        _deleted: sessionApi._deleted
    };
    return session;
};
