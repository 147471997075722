import { createTheme as legacyCreateTheme, Theme as LegacyTheme, ThemeOptions as LegacyThemeOptions } from '@material-ui/core';
import { createTheme, Theme, ThemeOptions } from '@mui/material';
import { makeAutoObservable } from 'mobx';
import { MobileOSType } from '../util';

export type Mode = 'dark' | 'light';

const darkThemeOptions: ThemeOptions = {
    palette: {
        mode: 'dark',
        primary: {
            main: '#FFFFFF',
            //main: '#f50057',
            light: '#9390A1'
        },
        secondary: {
            main: '#f50057',
        },
        background: {
            // default: '#08121A',
            // //paper: '#0a1721',
            // //paper: '#0c1c29',
            // paper: '#0c1924'
            default: '#19182F',
            //paper: '#32315C'
            paper: '#100F1F'
        },
        text: {
            primary: '#FFFFFF',
            secondary: '#9390A1'
        }
    },
    typography: {
        fontFamily: 'Roboto'
    }
};

const legacyDarkThemeOptions: LegacyThemeOptions = {
    palette: {
        ...darkThemeOptions.palette,
        type: 'dark'
    },
    typography: {
        fontFamily: 'Roboto'
    }
};

const lightThemeOptions: ThemeOptions = {
    palette: {
        mode: 'light',
        primary: {
            main: '#e17055'
        }
    }
};

const legacyLightThemeOptions: LegacyThemeOptions = {
    palette: {
        ...lightThemeOptions.palette,
        type: 'light'
    }
};

const darkTheme = createTheme(darkThemeOptions);
const lightTheme = createTheme(lightThemeOptions);

const legacyDarkTheme = legacyCreateTheme(legacyDarkThemeOptions);
const legacyLightTheme = legacyCreateTheme(legacyLightThemeOptions);

export class AppState {
    isSessionTooltipShownForQueryParam: boolean = false;
    isCalendarSettingsDrawerOpen: boolean = false;
    isCalendarFiltersDrawerOpen: boolean = false;
    isAppointmentTooltipVisible: boolean = false;
    isSessionSearchDrawerOpen: boolean = false;
    canToggleAppointmentTooltopVisibility: boolean = true;
    mobileType: MobileOSType = MobileOSType.Other;
    //mode: Mode = localStorage.getItem('mode') as Mode || 'dark';
    mode: Mode = 'dark';
    waitCounter = 0;

    constructor() {
        makeAutoObservable(this, {
            toggleAppointmentTooltipVisible: false,
        });
    }

    setIsSessionTooltipShownForQueryParamToTrue = () => {
        this.setIsSessionTooltipShownForQueryParam(true);
    }

    setIsSessionTooltipShownForQueryParam = (isShown: boolean) => {
        if (this.isSessionTooltipShownForQueryParam !== isShown) {
            this.isSessionTooltipShownForQueryParam = isShown;
        }
    }

    setIsCalendarSettingsDrawerOpen = (isOpen: boolean) => {
        if (this.isCalendarSettingsDrawerOpen !== isOpen) {
            this.isCalendarSettingsDrawerOpen = isOpen;
        }
    }

    setIsCalendarFiltersDrawerOpen = (isOpen: boolean) => {
        if (this.isCalendarFiltersDrawerOpen !== isOpen) {
            this.isCalendarFiltersDrawerOpen = isOpen;
        }
    }

    setIsSessionSearchDrawerOpen = (isOpen: boolean) => {
        if (this.isSessionSearchDrawerOpen !== isOpen) {
            this.isSessionSearchDrawerOpen = isOpen;
        }
    }

    toggleAppointmentTooltipVisible = () => {
        if (this.canToggleAppointmentTooltopVisibility) {
            this.setIsAppointmentTooltipVisible(!this.isAppointmentTooltipVisible);
        }
    }

    setCanToggleAppointmentTooltopVisibility = (canSet: boolean) => {
        if (this.canToggleAppointmentTooltopVisibility !== canSet) {
            this.canToggleAppointmentTooltopVisibility = canSet;
        }
    }

    setIsAppointmentTooltipVisible = (isVisible: boolean) => {
        if (this.isAppointmentTooltipVisible !== isVisible) {
            this.isAppointmentTooltipVisible = isVisible;
        }
    }

    setMobileType = (type: MobileOSType) => {
        if (this.mobileType !== type) {
            this.mobileType = type;
        }
    }

    setMode = (mode: Mode) => {
        if (this.mode !== mode) {
            this.mode = mode;
            localStorage.setItem('mode', mode);
        }
    }

    incrementWaitCounter = () => {
        this.waitCounter++;
    }

    decrementWaitCounter = () => {
        this.waitCounter--;
    }

    get isLoading(): boolean {
        return this.waitCounter > 0;
    }

    get theme(): Theme {
        return this.mode === 'dark' ? darkTheme : lightTheme;
    }

    get legacyTheme(): LegacyTheme {
        return this.mode === 'dark' ? legacyDarkTheme : legacyLightTheme;
    }
}

// const lightTheme = createTheme({
//     palette: {
//         mode: 'light',
//         // primary: {
//         //   main: '#004d40',
//         //   light: '#004d40',
//         //   dark: '#004d40',
//         //   contrastText: '#ffffff'
//         // },
//         // Rich Gardenia: #F97F51
//         // Quince Jelly: #f0932b
//         // Aqua: 58B19F
//         // Meditar. sea: #1289A7
//         // Turkish aqua: #006266
//         // Puffins red: #EE5A24
//         // Dark mountain meadow: #10ac84
//         // Jade dust: #00d2d3
//         // Double dragon skin: #ff9f43
//         // Lotus pink: #f368e0
//         // Orangeville: #e17055

//         // DARK THEME
//         // Dark blue bg: #08121A
//         // Dark paper bg: #101B2A
//         // Bright blue button color: #256AFE
//         // Hot pink: #C73D89
//         // Light blue: #73B7E2
//         // Mid blue: 00CAE5

//         // Orange
//         primary: {
//             main: '#e17055'
//         },
//         // primary: {
//         //   main: '#256AFE',
//         //   light: '#256AFE',
//         //   dark: '#256AFE',
//         //   contrastText: '#ffffff'
//         // },
//         // secondary: {
//         //   main: '#C73D89',
//         //   light: '#C73D89',
//         //   dark: '#C73D89',
//         //   contrastText: '#ffffff'
//         // },
//         // background: {
//         //   default: '#08121A',
//         //   paper: '#101B2A'
//         // },
//         // text: {
//         //   primary: '#ffffff',
//         //   secondary: '#ffffff',
//         //   disabled: '#eeeeee'
//         // },
//         // action: {
//         //   active: '#ffffff'
//         // },
//         // select: {
//         //   '&:before': {
//         //     borderColor: color,
//         //   },
//         //   '&:after': {
//         //     borderColor: color,
//         //   }
//         // },
//         // icon: {
//         //   fill: color,
//         // },
//         // active: string;
//         // hover: string;
//         // hoverOpacity: number;
//         // selected: string;
//         // selectedOpacity: number;
//         // disabled: string;
//         // disabledOpacity: number;
//         // disabledBackground: string;
//         // focus: string;
//         // focusOpacity: number;
//         // activatedOpacity: number;
//     }
// });
