import { Avatar, Card, IconButton, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { Crew } from '../../models';
import { UserStore } from '../../state';
import './CrewDetail.scss';
import { getInitials } from '../../util';
import { AvatarItem } from '../avatarItem';
import { CopyAllOutlined } from '@mui/icons-material';
import { useCallback } from 'react';
import { EditableField } from '../editableField';

interface CrewDetailProps {
    userStore: UserStore;
    selectedCrew?: Crew;
}

const CrewDetail = (props: CrewDetailProps) => {
    const copyCodeToClipboard = useCallback(async () => {
        if (!props.selectedCrew || props.selectedCrew.joinCode) {
            return;
        }
        try {
            await navigator.clipboard.writeText(props.selectedCrew.joinCode!);
        } catch (error) {
            console.log(error);
        }
    }, [props.selectedCrew]);

    const updateCrewName = useCallback(async (value: string) => {
        if (!props.selectedCrew) {
            return;
        }
        await props.userStore.updateCrewName(props.selectedCrew, value);
    }, [props.selectedCrew]);
    //const onToggleFavorite = () => props.userStore!.toggleCrewAsFavorite(props.selectedCrew!)
    return (
        <>
            {props.selectedCrew &&
                <div className='flex-column' style={{ gap: '10px', alignItems: 'center', padding: '10px 0px' }}>
                    {props.selectedCrew!.avatar ?
                        <Avatar src={props.selectedCrew!.avatar} imgProps={{ referrerPolicy: 'no-referrer' }}
                            sx={{
                                height: 128,
                                width: 128
                            }} />
                        :
                        <Avatar
                            sx={{
                                height: 128,
                                width: 128
                            }}>{getInitials(props.selectedCrew!.name)}</Avatar>
                    }
                    {
                        !props.selectedCrew?.isAdmin &&
                        <Typography fontWeight={'700'} fontSize={'1.2em'} sx={{ wordBreak: 'break-word', alignSelf: 'center', width: '100%', textAlign: 'center' }}>
                            {props.selectedCrew!.name}</Typography>
                    }
                    {
                        props.selectedCrew?.isAdmin &&
                        <EditableField fontSize='1.2rem' fontWeight='700' initialValue={props.selectedCrew.name} onValueChange={updateCrewName}></EditableField>
                    }
                    <Card sx={{ padding: '10px', background: '#272537', width: '100%' }}>
                        <Typography fontWeight={'600'} fontSize={'1.1em'} sx={{ marginBottom: '5px' }} >
                            Admins</Typography>
                        <div className='flex-row' style={{ gap: '10px', alignItems: 'center', flexWrap: 'wrap', maxHeight: '10vh', overflow: 'auto' }}>

                            {props.selectedCrew.admins.map(a =>
                                <AvatarItem user={a} key={'avatar-item-' + a.id} />
                            )}
                        </div>
                    </Card>
                    {/* <Tooltip title='Toggle Favorite'>
                                                    <IconButton
                                                        sx={{
                                                            width: '44px', height: '44px',
                                                        }}
                                                        // border: '1px solid' }}
                                                        onClick={onToggleFavorite}>
                                                        {Boolean(props.selectedFriend!.isFavorite) ?
                                                            <Star sx={{ width: '36px', height: '36px' }} color='primary' /> :
                                                            <StarBorder sx={{ width: '36px', height: '36px' }} />
                                                        }
                                                    </IconButton>
                                                </Tooltip> */}

                    <Card sx={{ padding: '10px', background: '#272537', width: '100%' }}>
                        <Typography fontWeight={'600'} fontSize={'1.1em'} sx={{ marginBottom: '5px' }}>
                            Members</Typography>
                        <div className='flex-row' style={{ gap: '10px', flexWrap: 'wrap', maxHeight: '40vh', overflow: 'auto' }}>
                            {props.selectedCrew.members.map(m =>
                                <AvatarItem user={m} key={'avatar-item-' + m.id} />
                            )}
                        </div>
                    </Card>
                    {
                        props.selectedCrew?.joinCode &&
                        <Card sx={{ padding: '10px', background: '#272537', width: '100%' }}>
                            <Typography>Use the join code below to invite members!</Typography>
                            <div className='flex-row' style={{ marginTop: '10px' }}>
                                <Typography color='#29b6f6' fontSize='1.2rem'>{props.selectedCrew!.joinCode}</Typography>
                                <IconButton onClick={copyCodeToClipboard}>
                                    <CopyAllOutlined />
                                </IconButton>
                            </div>
                        </Card>
                    }
                </div >
            }
        </>
    );
};

export default observer(CrewDetail);
