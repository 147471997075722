import { Search } from '@mui/icons-material';
import { Alert, AlertColor, IconButton, Popover, Snackbar, Typography } from '@mui/material';
import { useRef, useState } from 'react';
import { Event } from '../../models';
import { EventStore } from '../../state';
import { EventsSearch } from '../eventsSearch';
import { pageHeaderHeight } from '../../constants';
import './EventsPage.scss';
import { EventImageList } from '../eventImageList';
import { EventDetail } from '../eventDetail';

interface EventsPageProps {
    eventStore: EventStore
}

const EventsPage = (props: EventsPageProps) => {
    const [eventsSearchAnchorEl, setEventsSearchAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [eventDetailAnchorEl, setEventDetailAnchorEl] = useState<HTMLElement | null>(null);
    const [selectedEvent, setSelectedEvent] = useState<Event | undefined>(undefined);
    const [isToastOpen, setIsToastOpen] = useState<boolean>(false);
    const [toastMessage, setToastMessage] = useState<string>('');
    const [toastSeverity, setToastSeverity] = useState<AlertColor>('success');

    const eventsRef = useRef(null);

    const handleEventsSearchClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setEventsSearchAnchorEl(event.currentTarget);
    };

    const handleEventsSearchClose = () => {
        setEventsSearchAnchorEl(null);
    };

    const handleEventDetailOpen = () => {
        setEventDetailAnchorEl(eventsRef.current);
    };

    const handleEventDetailClose = () => {
        setEventDetailAnchorEl(null);
    };

    // const onHandleResult = (result: Result) => {
    //     setToastMessage(result.message);
    //     setToastSeverity(result.status as AlertColor);
    //     setIsToastOpen(true);
    // }

    const handleToastClose = () => {
        setIsToastOpen(false);
    }

    const isEventsSearchOpen = Boolean(eventsSearchAnchorEl);
    const isEventDetailOpen = Boolean(eventDetailAnchorEl);
    const eventsSearchPopoverId = isEventsSearchOpen ? 'search-events-popover' : undefined;
    const eventDetailPopoverId = isEventDetailOpen ? 'event-detail-popover' : undefined;

    const isAPopoverOpen = () => isEventsSearchOpen || isEventDetailOpen;

    const handleSelectedEvent = (event: Event) => {
        setSelectedEvent(event);
        handleEventsSearchClose();
        handleEventDetailOpen();
    }

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                open={isToastOpen}
                autoHideDuration={4000}
                onClose={handleToastClose}
                style={{ top: 'calc(var(--app-height)/2)' }}
            >
                <Alert
                    onClose={handleToastClose}
                    severity={toastSeverity}
                    sx={{ boxShadow: 10, opacity: 1, fontSize: '1.2rem', padding: '20px' }}>
                    {toastMessage}
                </Alert>
            </Snackbar>
            <div id='events-page-top-div' className='flex-column top-div'
                style={{
                    padding: '20px',
                    gap: '20px',
                    height: '100%',
                    filter: isAPopoverOpen() ? 'blur(2px)' : '',
                    WebkitFilter: isAPopoverOpen() ? 'blur(2px)' : '',
                    opacity: isAPopoverOpen() ? '0.2' : '1'
                }}>

                <div className='bottom-fade-div' />
                <>
                    <div id='events-div' className='flex-column half-container' ref={eventsRef}
                        style={{
                            maxHeight: '90vh'
                        }}>
                        <div className='flex-row'>
                            <Typography fontWeight={'700'} fontSize={'1.2em'} style={{ alignSelf: 'center' }}>Your Events</Typography>
                            <div className='section-button-group'>
                                <IconButton onClick={handleEventsSearchClick}>
                                    <Search />
                                </IconButton>
                                <Popover
                                    id={eventsSearchPopoverId}
                                    open={isEventsSearchOpen}
                                    anchorEl={eventsSearchAnchorEl}
                                    onClose={handleEventsSearchClose}
                                    anchorReference='anchorPosition'
                                    anchorPosition={{ top: pageHeaderHeight + 20, left: 0 }}
                                    PaperProps={{
                                        style: { width: '100%', borderRadius: '20px' },
                                    }}
                                >
                                    <div style={{
                                        padding: '16px'
                                    }}>
                                        <EventsSearch eventStore={props.eventStore} onEventSelected={handleSelectedEvent} />
                                    </div>
                                </Popover>
                                <Popover
                                    id={eventDetailPopoverId}
                                    open={isEventDetailOpen}
                                    anchorEl={eventDetailAnchorEl}
                                    onClose={handleEventDetailClose}
                                    anchorReference='anchorPosition'
                                    anchorPosition={{ top: pageHeaderHeight + 20, left: 0 }}
                                    PaperProps={{
                                        style: { width: '100%' },
                                    }}
                                >
                                    <div style={{
                                        padding: '16px'
                                    }}>
                                        <EventDetail eventStore={props.eventStore} selectedEvent={selectedEvent} />
                                    </div>
                                </Popover>
                                {/* <IconButton>
                                    <Add />
                                </IconButton> */}
                            </div>
                        </div>
                        <div id='your-events-list' className='flex-column' style={{ overflow: 'auto' }}>
                            {/* <TableContainer> */}
                            <EventImageList events={props.eventStore.events} onEventClicked={(event) => { handleSelectedEvent(event) }} />
                            {/* {new Array(25).fill(0).map(i => <div>Test</div>)} */}
                            {/* </TableContainer> */}
                        </div>
                        {/* <div id='events-buttons' className='flex-row buttons-container'>
                            <Button className='action-button'>Create New</Button>
                            <Button className='action-button'>Discover</Button>
                        </div> */}
                        {props.eventStore.events.length === 0 &&
                            (<>
                                {/* <Typography fontWeight={'900'} letterSpacing={'0.16em'} fontSize={'1.2em'} textAlign={'center'} style={{ marginTop: '20px' }}>JOIN A CREW</Typography> */}
                                {/* <TableContainer style={{ maxHeight: `calc(var(--app-height) - ${bottomAppBarHeight + pageHeaderHeight}px)`, height: '100%', padding: '20px' }}> */}
                                <Typography>You are not part of any events yet</Typography>
                                {/* </TableContainer> */}
                            </>)
                        }
                    </div>
                </>
            </div >
        </>
    );
};

export default EventsPage;