import React, { useState, useEffect } from 'react';
import {
    TextField,
    IconButton,
} from '@mui/material';
import {
    Edit as EditIcon,
    Save as SaveIcon,
    Cancel as CancelIcon,
} from '@mui/icons-material';

interface EditableFieldProps {
    initialValue: string;
    onValueChange: (newValue: string) => void;
    fontSize?: string;
    fontWeight?: string;
}

const EditableField: React.FC<EditableFieldProps> = ({ initialValue, onValueChange, fontSize, fontWeight }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [value, setValue] = useState(initialValue);
    const [originalValue, setOriginalValue] = useState(initialValue);

    const handleEditClick = () => {
        setOriginalValue(value); // Store the original value
        setIsEditing(true);
    };

    const handleSaveClick = () => {
        setIsEditing(false);
        onValueChange(value);
    };

    const handleCancelClick = () => {
        setIsEditing(false);
        setValue(originalValue); // Revert to the original value
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
    };

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {isEditing ? (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <TextField
                            value={value}
                            onChange={handleInputChange}
                            fullWidth
                            variant="outlined"
                        />
                        <IconButton onClick={handleSaveClick} size="small">
                            <SaveIcon />
                        </IconButton>
                        <IconButton onClick={handleCancelClick} size="small">
                            <CancelIcon />
                        </IconButton>
                    </div>
                ) : (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ fontSize: fontSize || '1rem', fontWeight: fontWeight || 'normal' }}>{value}</span>
                        <IconButton onClick={handleEditClick} size="small">
                            <EditIcon />
                        </IconButton>
                    </div>
                )}
            </div>
        </div>
    );
};

export default EditableField;
