import { FormControl } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Event } from '../../models';
import { EventStore } from '../../state';
import { AutoCompleteWithAvatars } from '../autoCompleteWithAvatars';

interface EventsSearchProps {
    eventStore: EventStore;
    onEventSelected: (event: Event) => void;
    events?: Event[];
    label?: string;
}

const EventsSearch = (props: EventsSearchProps) => {
    const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);
    const handleSelectedEventChange = (e, event: Event) => {
        setSelectedEvent(event);
        props.onEventSelected(event);
    };
    useEffect(() => {
        if (selectedEvent) {
            const foundEvent = props.eventStore.events.find(c => c.id === selectedEvent?.id);
            if (foundEvent) {
                setSelectedEvent(foundEvent);
            } else {
                setSelectedEvent(null);
            }
        }
    }, [props.eventStore.events, selectedEvent]);

    const getAllMyEvents = () => props.events || props.eventStore.events;

    return (
        <FormControl sx={{ width: '100%' }}>
            <AutoCompleteWithAvatars
                id='events-autocomplete-dropdown'
                label={props.label || 'Search Event'}
                aria-label='events-autocomplete-dropdown'
                placeholder='Start Typing'
                multiple={false}
                items={getAllMyEvents()}
                selectedItems={selectedEvent}
                handleChange={handleSelectedEventChange} />
        </FormControl>
    );
};

export default observer(EventsSearch);
