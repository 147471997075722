import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import { Autocomplete, AutocompleteRenderInputParams, Avatar, Checkbox, Chip, TextField } from '@mui/material';
import { CSSProperties } from 'react';
import { UserStore } from '../../state';
import { AvatarItem } from '../avatarItem';
import './AutoCompleteWithAvatars.scss';
import { getInitials } from '../../util';

interface AutoCompleteWithAvatarsProps {
    id: string,
    items: any[];
    multiple: boolean;
    placeholder: string;
    handleChange: (event, value) => void;
    selectedItems?: any;
    label?: string;
    style?: CSSProperties;
    isUser?: boolean;
    userStore?: UserStore;
    required?: boolean;
    showFavorites?: boolean;
}

const icon = <CheckBoxOutlineBlank fontSize='small' />;
const checkedIcon = <CheckBox fontSize='small' />;

const AutoCompleteWithAvatars = (props: AutoCompleteWithAvatarsProps) => {
    const showAvatar = (renderInputParams) => {
        const res = props.selectedItems && props.selectedItems.name === renderInputParams?.inputProps?.value;
        return res;
    }

    const isOptionEqualToValue = (option, value) => {
        return option?.id?.toString() === value?.id?.toString();
    }

    return (
        <Autocomplete
            id={props.id}
            multiple={props.multiple}
            disableCloseOnSelect={props.multiple}
            limitTags={8}
            options={props.items}
            getOptionLabel={(item) => item?.name || ''}
            sx={props.style}
            onChange={props.handleChange}
            value={props.selectedItems}
            blurOnSelect={!props.multiple}
            isOptionEqualToValue={isOptionEqualToValue}
            renderOption={(listProps, item, { selected }) => (
                <li {...listProps}>
                    {props.multiple &&
                        <Checkbox
                            key={`checkbox-option-${item.id}`}
                            icon={icon}
                            checkedIcon={checkedIcon}
                            checked={selected}
                        />}
                    {
                        props.isUser ?
                            <AvatarItem
                                showFavorites={props.showFavorites}
                                user={item}
                                userStore={props.userStore}
                                size='small' /> :
                            <AvatarItem
                                name={item.name}
                                avatar={item.avatar}
                                size='small' />
                    }
                </li>
            )}
            renderInput={(renderInputParams: AutocompleteRenderInputParams) => (
                <div ref={renderInputParams.InputProps.ref}
                    className='flex-row'
                    style={{
                        alignItems: 'center', width: '100%'
                    }}>
                    {showAvatar(renderInputParams) && (
                        <span
                            style={{
                                position: 'absolute',
                                marginLeft: '10px'
                            }}
                        >
                            {props.selectedItems.avatar ?
                                <Avatar sx={{ width: 32, height: 32 }}
                                    imgProps={{ referrerPolicy: 'no-referrer' }}
                                    src={props.selectedItems.avatar} />
                                : <Avatar sx={{ width: 32, height: 32 }}>{getInitials(props.selectedItems.name)}</Avatar>
                            }

                        </span>
                    )}
                    <TextField
                        {...renderInputParams}
                        label={`${props.label}\u2002`}
                        placeholder={props.placeholder}
                        required={props.required}
                        inputProps={{
                            ...renderInputParams.inputProps,
                            style: showAvatar(renderInputParams) ? {
                                paddingLeft: '40px'
                            } : {}
                        }}
                        InputLabelProps={{ style: { fontSize: '1.1rem' } }}
                    />
                </div >
            )}
            renderTags={(tagValue, getTagProps) => {
                return tagValue.map((item, index) => (
                    <div key={`selected-item${item.id}`} style={{ margin: '3px' }}>
                        <Chip size='medium'
                            avatar={
                                item.avatar ?
                                    <Avatar src={item.avatar} imgProps={{ referrerPolicy: 'no-referrer' }} />
                                    :
                                    <Avatar>{getInitials(item.name)}</Avatar>
                            }
                            label={item.name}
                            variant='outlined'
                            onDelete={getTagProps({ index }).onDelete}
                        />
                    </div>
                ));
            }}
        />
    );
}

export default AutoCompleteWithAvatars;