import { makeAutoObservable } from 'mobx';
import { Subject } from 'rxjs';
import { Event, Location } from '../models';
import { EventService } from '../services';

export class EventStore {
    locations: Location[] = [];
    events: Event[] = [];
    selectedEvent: Event | null = null;
    selectedEventId: string | null = null;
    private readonly SELECTED_EVENT_ID: string = 'selectedEventId';
    private gotEvents = false;
    private gotLocations = false;

    constructor(private eventService: EventService, private stop$: Subject<boolean>) {
        makeAutoObservable(this, {
        });
        this.setSelectedEventId(localStorage[this.SELECTED_EVENT_ID]);
        this.eventService.locations$.subscribe(this.setLocations);
        this.eventService.events$.subscribe(this.setEvents);
    }

    private setLocations = (locations: Location[]) => {
        this.locations = locations;
        this.gotLocations = true;
    }

    private setEvents = (events: Event[]) => {
        this.events = events;
        this.gotEvents = true;
        if (this.selectedEventId) {
            this.setSelectedEventFromId(this.selectedEventId);
            this.eventService.fetchLocationsForEvent(this.selectedEventId);
        } else {
            this.setSelectedEventId(this.events[0].id);
        }
    }

    setSelectedEventId = (eventId: string | null) => {
        if (this.selectedEventId === eventId) {
            return;
        }
        this.eventService.updateSelectedEventId(eventId);
        if (!eventId) {
            localStorage.removeItem(this.SELECTED_EVENT_ID);
            if (this.gotEvents) {
                this.setSelectedEvent(null);
            }
            this.locations = [];
        } else {
            this.selectedEventId = eventId;
            localStorage.setItem(this.SELECTED_EVENT_ID, this.selectedEventId);
            if (this.gotLocations) {
                this.setSelectedEventFromId(this.selectedEventId);
                this.eventService.fetchLocationsForEvent(this.selectedEventId);
            }
        }
    }

    private setSelectedEventFromId = (eventId: string) => {
        const foundEvent = this.findEvent(eventId)
        if (!foundEvent) {
            console.log('Could not find the event for event id: ' + eventId);
        } else {
            this.setSelectedEvent(foundEvent);
        }
    }

    setSelectedEvent = (event: Event | null) => {
        this.selectedEvent = event;
    }

    private findEvent = (eventId: string) => {
        return this.events.find(e => e.id === eventId);
    }
}
