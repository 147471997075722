import { Avatar, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { Event } from '../../models';
import { EventStore } from '../../state';
import './EventDetail.scss';
import { getInitials } from '../../util';

interface EventDetailProps {
    eventStore: EventStore
    selectedEvent?: Event;
}

const EventDetail = (props: EventDetailProps) => {
    //const onToggleFavorite = () => props.eventStore!.toggleEventAsFavorite(props.selectedEvent!)
    return (
        <>
            {props.selectedEvent &&
                <div className='flex-column' style={{ justifyContent: 'center', width: '100%', gap: '10px' }}>
                    {props.selectedEvent!.avatar ?
                        <Avatar src={props.selectedEvent!.avatar}
                            imgProps={{ referrerPolicy: 'no-referrer' }}
                            sx={{
                                height: 360,
                                width: 360,
                                alignSelf: 'center'
                            }} />
                        :
                        <Avatar
                            sx={{
                                height: 360,
                                width: 360
                            }}>{getInitials(props.selectedEvent!.name)}</Avatar>
                    }
                    <Typography fontWeight={'700'} fontSize={'1.2em'} style={{ alignSelf: 'center' }}>{props.selectedEvent.name}</Typography>
                    <Typography fontWeight={'400'} fontSize={'1em'} style={{ alignSelf: 'center' }}>{props.selectedEvent.startDate.toLocaleDateString()} to {props.selectedEvent.endDate.toLocaleDateString()}</Typography>
                    <Typography fontWeight={'400'} fontSize={'1em'} style={{ alignSelf: 'center' }}>@{props.selectedEvent.venue.name}</Typography>
                    {props.selectedEvent.info &&
                        <Typography fontWeight={'400'} fontSize={'1em'} style={{ alignSelf: 'center' }}>{JSON.stringify(props.selectedEvent.info)}</Typography>
                    }
                </div >

            }
        </>
    );
};

export default observer(EventDetail);
