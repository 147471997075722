import {
    FormControl,
    FormControlLabel, Menu, Radio, RadioGroup
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { SessionStore, UserStore } from '../../state';
import { AutoCompleteWithAvatars } from '../autoCompleteWithAvatars';
import './UserFilter.scss';
interface UserFilterProps {
    id: string;
    anchorEl?: Element | null;
    handleClose?: (event) => void;
    userStore: UserStore,
    sessionStore: SessionStore
}

const InnerDiv = observer((props: UserFilterProps) => {
    const onSelectedUserChanged = (userId: string | undefined) => {
        props.sessionStore.setUserIdFilter(userId);
    }
    const handleChange = (event: any) => {
        const selection = event.target.value;
        if (selection === 'All') {
            onSelectedUserChanged(undefined);
        } else if (selection === 'Mine') {
            onSelectedUserChanged(props.userStore.currentUser!.id);
        } else {
            onSelectedUserChanged(selection);
        }
        if (props.handleClose) {
            props.handleClose(null);
        }
    }
    const getValue = () => {
        if (!props.sessionStore.userIdFilter) {
            return 'All';
        } else if (props.sessionStore.userIdFilter === props.userStore.currentUser!.id) {
            return 'Mine';
        } else {
            return 'Others';
        }
    }
    const getIsOthersChecked = (): boolean => {
        return props.sessionStore.userIdFilter !== undefined &&
            props.sessionStore.userIdFilter !== props.userStore.currentUser!.id &&
            props.sessionStore.userIdFilter !== '';
    }
    const getOthersValue = () => {
        if (props.sessionStore.userIdFilter !== undefined &&
            props.sessionStore.userIdFilter !== props.userStore.currentUser!.id) {
            return props.userStore.usersWithoutMe.find(user => user.id.toString() === props.sessionStore.userIdFilter);
        } else {
            return null;
        }
    }
    const handleOthersSelectionChanged = (e, value) => {
        if (value?.id) {
            handleChange({
                target: {
                    value: value.id
                }
            });
        }
    }
    return (
        <FormControl className='user-filter-div'>
            <RadioGroup
                name='controlled-radio-buttons-group'
                value={getValue()}
                onChange={handleChange}
                sx={{ width: '100%' }}
            >
                <FormControlLabel value='All' control={<Radio />} label='All' />
                <FormControlLabel value='Mine' control={<Radio />} label='Mine' />
                <FormControlLabel value='Others' label='' sx={{ marginRight: '0px', width: '100%' }} control={
                    <div className='flex-row'
                        style={{ width: '100%' }}
                        id='others-dropdown-div'>
                        <Radio
                            disabled
                            value='Others'
                            checked={getIsOthersChecked()} />
                        <FormControl style={{ width: '100%' }}>
                            <AutoCompleteWithAvatars
                                id='others-autocomplete-dropdown'
                                aria-label='others-autocomplete-dropdown'
                                placeholder='Start Typing'
                                label='Others*'
                                multiple={false}
                                items={props.userStore.usersWithoutMe}
                                selectedItems={getOthersValue()}
                                handleChange={handleOthersSelectionChanged}
                                userStore={props.userStore}
                                isUser={true}
                                style={{ marginTop: '7px !important', minWidth: '180px' }}
                            />
                        </FormControl>
                    </div>
                } />
            </RadioGroup>
        </FormControl>
    );
})

const UserFilter = (props: UserFilterProps) => {
    return (
        props.anchorEl ?
            <Menu
                id={props.id}
                anchorEl={props.anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(props.anchorEl)}
                onClose={props.handleClose}
            >
                <InnerDiv {...props} />
            </Menu> :
            <InnerDiv {...props} />
    );
}

export default UserFilter;
