import { Observable, ReplaySubject, Subject, Subscription, takeUntil } from 'rxjs';
import { Crew, Message, User, Result, CrewResult } from '../models';
import { createUser, getCrewsForAdmin, getCrewsForMember, findUser, updateUser, findJoinCode, createCrewMember, createCrew, createJoinCode, createCrewAdmin, getJoinCrewCodeForCrewIds, updateCrewName } from './graphqlOperations';
import { Hub, Auth, API, graphqlOperation } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib-esm/types';
import * as subscriptions from '../graphql/subscriptions';
import { AppState } from '../state';
import { addHoursToDate } from '../util';

// For tests only
// import { setupAllTestData } from './testSetup';
interface ProfileAttributes {
    email: string;
    given_name: string;
    family_name: string;
    picture: string;
}

const generateNewJoinCode = (): string => {
    const code = Array.from(Array(20), () => Math.floor(Math.random() * 36).toString(36)).join('').toLocaleUpperCase();
    const setsOfFour = code.match(/.{1,4}/g);
    const val = setsOfFour!.join('-').toLocaleUpperCase();
    return val;
}

export class UserService {
    currentUser$ = new ReplaySubject<User | undefined | null>();
    private currentToken: any | null = null;
    currentToken$ = new ReplaySubject<any | undefined | null>();
    myMemberCrews$ = new ReplaySubject<Crew[]>();
    myAdminCrews$ = new ReplaySubject<Crew[]>();
    myFavoriteFriends$ = new ReplaySubject<User[]>();
    private crewChangesSub$ = new ReplaySubject<boolean>();
    private currentUser: User | null | undefined;
    private crewsWhereAdmin: Crew[] = [];
    private crewsWhereMember: Crew[] = [];
    private uniqueCrewIds: string[] = [];
    errors$ = new ReplaySubject<string>();
    private changesSubscriptions$: Subscription[] = [];
    private isUserAdminSubject$ = new ReplaySubject<boolean>();
    //private allUsersSubject$ = new ReplaySubject<User[]>();

    constructor(private appState: AppState, private stop$: Subject<boolean>) {
        // TO SIGN OUT PROGRAMATICALLY
        // Auth.signOut();
        this.appState.incrementWaitCounter();

        Hub.listen('auth', ({ payload }) => {
            if (payload.event === 'signIn') {
                return this.getUser(true);
            }
            if (payload.event === 'signOut') {
                this.updateCurrentUserFromToken(null);
            }
            if (payload.event === 'signIn_failure') {
                const payloadData = payload.data.toString();
                const errorMessage = decodeURIComponent(
                    payloadData.substring('Error: PreSignUp+failed+with+error+'.length, payloadData.length - 1)
                        .replace(/\+/g, ' '));
                console.error(errorMessage);
                this.errors$.next(errorMessage);
            }
        });
        this.getUser(false);

        this.currentUser$
            .pipe(
                takeUntil(stop$))
            .subscribe(user => {
                if (user) {
                    this.refreshAll();
                } else {
                    this.resetAll();
                }
            });

        // this.changesSubscriptions$.push((API.graphql(graphqlOperation(subscriptions.onCreateUser)) as unknown as Observable<any>)
        //     .subscribe({
        //         next: ({ provider, value }) => this.handleAllowedUserChange(provider, value),
        //         error: (error) => console.warn(error)
        //     }));
        // this.changesSubscriptions$.push((API.graphql(graphqlOperation(subscriptions.onUpdateUser)) as unknown as Observable<any>)
        //     .subscribe({
        //         next: ({ provider, value }) => this.handleAllowedUserChange(provider, value),
        //         error: (error) => console.warn(error)
        //     }));
        // this.changesSubscriptions$.push((API.graphql(graphqlOperation(subscriptions.onDeleteUser)) as unknown as Observable<any>)
        //     .subscribe({
        //         next: ({ provider, value }) => this.handleAllowedUserChange(provider, value),
        //         error: (error) => console.warn(error)
        //     }));

        // this.stop$.pipe(
        //     take(1)
        // ).subscribe(a => {
        //     this.changesSubscriptions$.forEach(s => s.unsubscribe());
        // });
    }

    createCrew = async (crewName: string, crewAvatar?: string): Promise<CrewResult> => {
        try {
            const createdCrew = await createCrew({
                name: crewName,
                avatar: crewAvatar
            });
            if (!createdCrew) {
                return {
                    status: 'error',
                    message: 'Could not create crew.',

                };
            }
            const createdCrewAdmin = await createCrewAdmin(createdCrew, this.currentUser!);
            if (!createdCrewAdmin) {
                return {
                    status: 'error',
                    message: 'Could not become crew admin.',

                };
            }
            const joinCode = generateNewJoinCode();
            const expireDate = addHoursToDate(new Date(), 24 * 30).toISOString();
            const createdJoinCode = await createJoinCode({
                code: joinCode,
                crewId: createdCrew.id,
                expireDate
            });
            if (!createdJoinCode) {
                return {
                    status: 'error',
                    message: 'Could not generate join code.',

                };
            }
            return {
                status: 'success',
                message: 'Crew created successfully',
                joinCode,
                expireDate: new Date(expireDate)
            }
        } catch (err) {
            return {
                status: 'error',
                message: JSON.stringify(err)
            }
        }
    }

    updateCrewName = async (crew: Crew, newName: string): Promise<Result> => {
        try {
            const updatedCrew = await updateCrewName({
                ...crew,
                name: newName
            });
            if (!updatedCrew) {
                return {
                    status: 'error',
                    message: 'Could not update crew name.',

                };
            }
            return {
                status: 'success',
                message: 'Crew name updated successfully'
            }
        } catch (err) {
            return {
                status: 'error',
                message: JSON.stringify(err)
            }
        }
    }

    private getUser = async (shouldCreateIfMissing: boolean = false) => {
        try {
            const token = await Auth.currentAuthenticatedUser();
            this.currentToken = token;
            const attributes = token.attributes as ProfileAttributes;
            this.updateCurrentUserFromToken(attributes, shouldCreateIfMissing);
            this.currentToken = token;
            this.currentToken$.next(token);

        } catch (err) {
            console.log('Did not receive a token from cognito');
            console.log(err);
            this.currentToken = null;
            this.currentToken$.next(null);
        }
    }

    private updateCurrentUserFromToken = async (attributes: ProfileAttributes | null, shouldCreateIfMissing: boolean = false) => {
        if (!attributes) {
            this.updateCurrentUser(null);
            return;
        } else {
            try {
                const foundUser = await findUser(attributes.email);
                if (foundUser) {
                    // Update user so we have latest avatar etc
                    // TODO: How does this work for non Google stuff?
                    // const updatedUser = await updateUser({
                    //     id: foundUser.id,
                    //     email: attributes.email,
                    //     name: attributes.given_name + ' ' + attributes.family_name,
                    //     avatar: attributes.picture
                    // })
                    // this.updateCurrentUser(updatedUser);
                    this.updateCurrentUser(foundUser);
                } else {
                    if (shouldCreateIfMissing) {
                        console.log('Creating new user');
                        //Create user
                        const userToCreate: User = {
                            id: 'placeholder',
                            email: attributes.email,
                            name: attributes.given_name && attributes.family_name
                                ? attributes.given_name + ' ' + attributes.family_name
                                : attributes.given_name ? attributes.given_name
                                    : attributes.family_name ? attributes.family_name
                                        : attributes.email.substring(0, 2),
                            avatar: attributes.picture,
                            _version: 1,
                            _lastChangedAt: Date.now()
                        };
                        console.log(userToCreate);
                        const createdUser = await createUser(userToCreate);
                        if (createdUser) {
                            this.updateCurrentUser(createdUser);
                        }
                    }
                }
            } catch (err) {
                const message = `Could not check whether user exists. ${(err as any).toString()}`;
                console.log(message);
                console.log(err);
            }
        }
    }

    private updateCurrentUser = (user: User | null | undefined) => {
        this.currentUser = user;
        this.currentUser$.next(user);
    }

    login = async () => {
        try {
            await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google, customState: Math.random().toString() });
        } catch (err) {
            this.onLoginFailure(err);
        }
    }

    logout = async () => {
        try {
            await Auth.signOut();
            this.onLogoutSuccess();
        } catch (err) {
            this.onLogoutFailure(err);
        }
    }

    onLoginFailure = (err) => {
        const message = `Could not log in: ${(err as any).toString()}`;
        console.log(message);
        this.errors$.next(message);
        this.updateCurrentUser(undefined);
    }

    onLogoutSuccess = () => {
        this.updateCurrentUser(undefined);
    }

    onLogoutFailure = (err) => {
        const message = `Could not log out: ${(err as any).toString()}`;
        console.log(message);
        this.errors$.next(message);
    }

    get isUserAdmin$(): Observable<boolean> {
        return this.isUserAdminSubject$
        // .pipe(
        //     takeUntil(this.stop$)
        //);
    };

    get crewChanges$(): Observable<boolean> {
        return this.crewChangesSub$;
    }

    // addAllowedUser = async (newUserEmail: string): Promise<Result | undefined> => {
    //     try {
    //         if (this.users.find(au => au.email === newUserEmail)) {
    //             alert('This user e-mail already exists');
    //             return;
    //         }
    //         const createdAllowedUser = await createAllowedUser({
    //             email: newUserEmail
    //         });

    //         return createdAllowedUser ? { status: 'success', message: 'User added to allow list' } : { status: 'error', message: 'Could not add allowed user' };
    //     } catch (e) {
    //         console.error(e);
    //         return { status: 'error', message: `Could not add allowed user` };
    //     }
    // }

    // updateAllowedUser = async (updatedUser: AllowedUser): Promise<Result | undefined> => {
    //     try {
    //         const updatedAllowedUser = await updateAllowedUser(updatedUser);
    //         return updatedAllowedUser ? { status: 'success', message: 'User e-mail updated' } : { status: 'error', message: 'Could not update allowed user' };
    //     } catch (e) {
    //         console.error(e);
    //         return { status: 'error', message: `Could not update allowed user` };
    //     }
    // }

    // deleteAllowedUser = async (deletedUser: AllowedUser): Promise<Result | undefined> => {
    //     try {
    //         const deletedAllowedUser = await deleteAllowedUser(deletedUser);
    //         return deletedAllowedUser ? { status: 'success', message: 'User removed from allow list' } : { status: 'error', message: 'Could not remove user from allow list' };
    //     } catch (e) {
    //         console.error(e);
    //         return { status: 'error', message: `Could not remove user from allow list` };
    //     }
    // }

    // private handleAllowedUserChange(provider: any, value: any) {
    //     console.log('Allowed users changed, server is sending new values...');
    //     this.fetchCollections();
    // }

    // private fetchCollections = async () => {
    //     try {
    //         const users = await getAllUsers();
    //         this.users = users;
    //         this.allUsersSubject$.next(users);
    //         this.isUserAdminSubject$.next(true);
    //     } catch (res: any) {
    //         if (res.errors && res.errors.length > 0 && res.errors[0].errorType === 'Unauthorized') {
    //             this.isUserAdminSubject$.next(false);
    //         } else {
    //             console.log(res);
    //         }
    //     }
    // }

    // private setCurrentUser = async (user: User) => {
    //     const foundUser = await findUser(user.email);
    //     if (foundUser) {
    //         this.updateCurrentUser(foundUser);
    //     } else {
    //         // Create user
    //         const createdUser = await createUser(user);
    //         if (createdUser) {
    //             this.updateCurrentUser(createdUser);
    //         }
    //     }
    //     await this.refreshFavorites(this.currentUser!);
    //     await this.refreshCrews(this.currentUser!);
    //     await this.subscribeToChanges(this.currentUser!);
    // }

    private refreshAll = async () => {
        await this.refreshFavorites(this.currentUser!);
        await this.refreshCrews(this.currentUser!);
        // TODO -> Get Sessions
        await this.setupSubscriptions();
        this.appState.decrementWaitCounter();
    }

    private setupSubscriptions = () => {
        this.changesSubscriptions$.forEach(s => s.unsubscribe);
        this.changesSubscriptions$ = [];

        // When the user creates a new crew
        this.changesSubscriptions$.push((API.graphql(graphqlOperation(
            subscriptions.onCreateCrew, {
            filter: {
                owner: {
                    eq: this.currentToken
                }
            }
        }
        )) as unknown as Observable<any>)
            .subscribe({
                next: () => this.refreshCrews(this.currentUser!),
                error: (error) => console.warn(error)
            }));

        // When the user deletes a crew
        this.changesSubscriptions$.push((API.graphql(graphqlOperation(
            subscriptions.onDeleteCrew, {
            filter: {
                owner: {
                    eq: this.currentToken
                }
            }
        }
        )) as unknown as Observable<any>)
            .subscribe({
                next: () => this.refreshCrews(this.currentUser!),
                error: (error) => console.warn(error)
            }));

        // When the user becomes the admin of a crew
        this.changesSubscriptions$.push((API.graphql(graphqlOperation(
            subscriptions.onCreateCrewAdmin, {
            filter: {
                userId: {
                    eq: this.currentUser!.id
                }
            }
        })) as unknown as Observable<any>)
            .subscribe({
                next: () => this.refreshCrews(this.currentUser!),
                error: (error) => console.warn(error)
            }));

        // When the user is removed from being the admin of a crew
        this.changesSubscriptions$.push((API.graphql(graphqlOperation(
            subscriptions.onDeleteCrewAdmin, {
            filter: {
                userId: {
                    eq: this.currentUser!.id
                }
            }
        })) as unknown as Observable<any>)
            .subscribe({
                next: () => this.refreshCrews(this.currentUser!),
                error: (error) => console.warn(error)
            }));

        // When the user joins a crew
        this.changesSubscriptions$.push((API.graphql(graphqlOperation(
            subscriptions.onCreateCrewMember, {
            filter: {
                userId: {
                    eq: this.currentUser!.id
                }
            }
        })) as unknown as Observable<any>)
            .subscribe({
                next: () => this.refreshCrews(this.currentUser!),
                error: (error) => console.warn(error)
            }));

        // When the user leaves a new crew
        this.changesSubscriptions$.push((API.graphql(graphqlOperation(
            subscriptions.onDeleteCrewMember, {
            filter: {
                userId: {
                    eq: this.currentUser!.id
                }
            }
        })) as unknown as Observable<any>)
            .subscribe({
                next: () => this.refreshCrews(this.currentUser!),
                error: (error) => console.warn(error)
            }));

        // Iterate through all the crews the user is either a member or an admin of
        // And listen to changes to each, such as other members joining 
        // This is to ensure the Crew and User filters are kept up to date
        // For example, when the user is looking at their calendar and someone is added to the crew and registers for an event,
        // they should be able to see that new user in their filter dropdown without having to refresh the page.
        this.uniqueCrewIds.forEach(crewId => {
            // When a new user joins a crew the user is a member or admin of
            this.changesSubscriptions$.push((API.graphql(graphqlOperation(
                subscriptions.onCreateCrewMember, {
                filter: {
                    crewId: {
                        eq: crewId
                    }
                }
            })) as unknown as Observable<any>)
                .subscribe({
                    next: () => this.refreshCrews(this.currentUser!),
                    error: (error) => console.warn(error)
                }));

            // When a user leaves a crew the user is a member or admin of
            this.changesSubscriptions$.push((API.graphql(graphqlOperation(
                subscriptions.onDeleteCrewMember, {
                filter: {
                    crewId: {
                        eq: crewId
                    }
                }
            })) as unknown as Observable<any>)
                .subscribe({
                    next: () => this.refreshCrews(this.currentUser!),
                    error: (error) => console.warn(error)
                }));

            // When a new admin joins a crew the user is a member or admin of
            this.changesSubscriptions$.push((API.graphql(graphqlOperation(
                subscriptions.onCreateCrewAdmin, {
                filter: {
                    crewId: {
                        eq: crewId
                    }
                }
            })) as unknown as Observable<any>)
                .subscribe({
                    next: () => this.refreshCrews(this.currentUser!),
                    error: (error) => console.warn(error)
                }));

            // When an admin leaves a crew the user is a member or admin of
            this.changesSubscriptions$.push((API.graphql(graphqlOperation(
                subscriptions.onDeleteCrewAdmin, {
                filter: {
                    crewId: {
                        eq: crewId
                    }
                }
            })) as unknown as Observable<any>)
                .subscribe({
                    next: () => this.refreshCrews(this.currentUser!),
                    error: (error) => console.warn(error)
                }));

            // When properties such as name or avatar changes for a crew the user is a member or admin of
            this.changesSubscriptions$.push((API.graphql(graphqlOperation(
                subscriptions.onUpdateCrew, {
                filter: {
                    crewId: {
                        eq: crewId
                    }
                }
            })) as unknown as Observable<any>)
                .subscribe({
                    next: () => this.refreshCrews(this.currentUser!),
                    error: (error) => console.warn(error)
                }));

            // When a crew that the user is part of gets deleted
            this.changesSubscriptions$.push((API.graphql(graphqlOperation(
                subscriptions.onDeleteCrew, {
                filter: {
                    crewId: {
                        eq: crewId
                    }
                }
            })) as unknown as Observable<any>)
                .subscribe({
                    next: () => this.refreshCrews(this.currentUser!),
                    error: (error) => console.warn(error)
                }));

        });
    }

    private resetAll = async () => {
        this.myMemberCrews$.next([])
        this.myAdminCrews$.next([]);
        this.myFavoriteFriends$.next([]);
        this.appState.decrementWaitCounter();
    }

    private refreshCrews = async (user: User) => {
        const crewsWhereMember = await getCrewsForMember(user.id);
        const crewsWhereAdmin = await getCrewsForAdmin(user.id);
        const joinCodesForCrewsWhereAdmin = await getJoinCrewCodeForCrewIds(crewsWhereAdmin.map(c => c.id));
        this.crewsWhereMember = crewsWhereMember;
        this.crewsWhereAdmin = crewsWhereAdmin.map(a => {
            return {
                ...a,
                isAdmin: true,
                joinCode: joinCodesForCrewsWhereAdmin?.find(j => j.crew.id === a.id)?.code
            }
        });
        this.uniqueCrewIds = [...new Set([...this.crewsWhereAdmin, ...this.crewsWhereMember].map((crew) => crew.id))];
        this.myMemberCrews$.next(this.crewsWhereMember)
        this.myAdminCrews$.next(this.crewsWhereAdmin);
        // Notify other stores/services
        this.crewChangesSub$.next(true);
    }

    private refreshFavorites = async (user: User) => {
        // const favoriteFriends = await getFavoriteFriends(user.id);
        this.myFavoriteFriends$.next([]);
    }

    // private subscribeToChanges = async (user: User) => {
    //     if (this.crewChanges$) {
    //         this.crewChanges$.unsubscribe();
    //     }
    //     // this.crewAdminChanges$ = (API.graphql(
    //     //         .subscribe(x => {
    //     //     console.log(x)
    //     // });
    // }

    // onLoginSuccess = (gmailUser: GmailUser) => {
    //     this.setCurrentUser({
    //         id: 'dummy',
    //         email: gmailUser.profileObj.email,
    //         name: gmailUser.profileObj.name,
    //         avatar: gmailUser.profileObj.imageUrl
    //     });
    // }

    sendMessage = (message: Message) => {
        // TODO
    }

    toggleFriendAsFavorite = async (friendId: string) => {
        // const foundFavoriteFriend = await findFavoriteFriend(this.currentUser!.id, friendId);
        // if (foundFavoriteFriend) {
        //     await deleteFavoriteFriend({
        //         id: foundFavoriteFriend.id
        //     });
        // } else {
        //     await createFavoriteFriend({
        //         userId: this.currentUser!.id,
        //         friendId: friendId
        //     });
        // }
        // this.refreshFavorites(this.currentUser!);
    }

    toggleFriendsAsFavorite = (friendIds: string[]) => {
        friendIds.forEach(friendId => this.toggleFriendAsFavorite(friendId));
    }

    addAdminToCrew = (crew: Crew, adminUserId: string) => {
        //Test
        // let foundOwner: User | undefined = users.find(u => u.id === adminUserId);
        // const foundCrew = crews.find(c => c.id === crew.id);
        // if (!foundOwner) {
        //     foundOwner = {
        //         id: adminUserId,

        //         name: 'unknown'
        //     }
        // }
        // if (foundCrew) {
        //     foundCrew.admins.push(foundOwner);
        //     //this.refreshCrews();
        // }
    }

    updateUser = async (user: User): Promise<Result | undefined> => {
        const updatedUser = await updateUser(user);
        if (!updatedUser) {
            const message = 'Could not update profile';
            //this.errors$.next(message);
            return { status: 'error', message }
        }
        this.updateCurrentUser(updatedUser);
        return { status: 'success', message: 'Profile changes saved' }
    }

    joinCrewWithCode = async (code: string): Promise<Result | undefined> => {
        const foundJoinCode = await findJoinCode(code);
        if (!foundJoinCode) {
            const message = 'Invalid join code. Codes are valid for 30 days. Please check your code and try again.';
            //this.errors$.next(message);
            return { status: 'error', message }
        }
        const crew = foundJoinCode.crew;
        if (!crew) {
            const message = 'Could not get the crew associated with this code.';
            //this.errors$.next(message);
            return { status: 'error', message }
        }
        // Check if already a member
        const crews = await getCrewsForMember(this.currentUser!.id);
        if (crews.map(c => c.id).includes(crew.id)) {
            const message = `You are already a member of the crew: ${crew.name}`;
            //this.errors$.next(message);
            return { status: 'error', message }
        }

        const addedToCrew = await createCrewMember({
            userId: this.currentUser!.id,
            crewId: crew.id
        });
        if (addedToCrew) {
            await this.refreshCrews(this.currentUser!);
            //await this.subscribeToChanges(this.currentUser!);
            return {
                status: 'success', message: `You joined the crew: ${crew.name}!`
            };
        } else {
            const message = `Could not join the crew: ${crew.name}!`
            //this.errors$.next(message);
            return {
                status: 'error', message
            };
        }
    }
}
