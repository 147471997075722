import { ChevronLeft, ExpandMore, FilterAltOutlined, PeopleAltOutlined } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Badge, Divider, Drawer, IconButton, List, Typography, useTheme } from '@mui/material';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { AppState, EventStore, SessionStore, UserStore } from '../../state';
import { CrewFilter } from '../crewFilter';
import { UserFilter } from '../userFilter';
import './CalendarFiltersDrawer.scss';

interface CalendarFiltersDrawerProps {
    eventStore: EventStore;
    userStore: UserStore;
    sessionStore: SessionStore;
    appState: AppState;
}

const CalendarFiltersDrawer = (props: CalendarFiltersDrawerProps) => {
    const textColor = useTheme().palette.text.secondary;
    const [expanded, setExpanded] = useState<string | false>('filter-by-users');
    const onCloseDrawer = () => props.appState.setIsCalendarFiltersDrawerOpen(false);
    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Drawer
            sx={{
                minWidth: '240px',
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    minWidth: '240px',
                    boxSizing: 'border-box',
                },
            }}
            ModalProps={{ onBackdropClick: onCloseDrawer }}
            anchor='left'
            open={props.appState.isCalendarFiltersDrawerOpen}
            onClose={onCloseDrawer}
        >
            <div>
                <div id='calendar-filters-drawer-header'
                    className='flex-row'
                    style={{ padding: '10px 10px 10px 20px', alignItems: 'center', justifyContent: 'center' }}
                >
                    <Typography
                        sx={{ flex: '2' }}
                        color={textColor}
                        fontSize='1.1rem'
                        fontWeight='bold'>
                        Calendar Filters
                    </Typography>
                    <IconButton
                        style={{ marginLeft: '5px' }}
                        color='default'
                        onClick={onCloseDrawer}>
                        <ChevronLeft />
                    </IconButton>
                </div>
                <Divider />
                <List style={{ marginTop: '10px' }}>
                    <Accordion elevation={0} expanded={expanded === 'filter-by-crew'} onChange={handleChange('filter-by-crew')}>
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls='crew-filter-accordion-content'
                            id='crew-filter-accordion'>
                            <PeopleAltOutlined style={{ marginRight: '10px' }} />
                            <Typography>Filter by Crew</Typography>
                            <Badge
                                sx={{ marginLeft: '15px', marginTop: '5px' }}
                                color='primary'
                                badgeContent={1}
                                invisible={!props.userStore.selectedCrew}></Badge>
                        </AccordionSummary>
                        <AccordionDetails style={{ marginLeft: '10px' }}>
                            <CrewFilter
                                userStore={props.userStore}
                                id='crew-filter'
                            />
                        </AccordionDetails>
                    </Accordion>
                    <Divider />
                    <Accordion elevation={0} expanded={expanded === 'filter-by-users'} onChange={handleChange('filter-by-users')}>
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls='filter-by-person-accordion-content'
                            id='filter-by-person-accordion'>
                            <FilterAltOutlined style={{ marginRight: '10px' }} />
                            <Typography>Filter by Person</Typography>
                            <Badge
                                sx={{ marginLeft: '15px', marginTop: '5px' }}
                                color='primary'
                                badgeContent={1}
                                invisible={!props.sessionStore.userIdFilter}></Badge>
                        </AccordionSummary>
                        <AccordionDetails style={{ marginLeft: '10px' }}>
                            <UserFilter
                                id='user-filter-menu'
                                userStore={props.userStore}
                                sessionStore={props.sessionStore}
                            />
                            <Typography sx={{ marginTop: '5px' }}>*Showing users in your selected crew</Typography>
                        </AccordionDetails>
                    </Accordion>
                </List>
            </div>
        </Drawer>
    );
};

export default observer(CalendarFiltersDrawer);
