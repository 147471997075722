import { TouchApp } from '@mui/icons-material';
import { Avatar, AvatarGroup, Color, Typography } from '@mui/material';
import { useEffect, useRef, useState, memo } from 'react';
import { User } from '../../models';
import { getInitials } from '../../util';

interface AvatarsDivProps {
    users: User[];
    id: string | number | undefined;
    isSessionSelected: boolean;
    isSessionTooltipShownForQueryParam: boolean;
    onTooltipForSessionFromQueryParamsShown: () => void;
    color: string | Color;
}

interface Size {
    width: number,
    height: number
}

const propsAreEqual = (prevProps: AvatarsDivProps, nextProps: AvatarsDivProps): boolean => {
    const prevUserIds = prevProps.users.map(u => u.id);
    const nextUserIds = nextProps.users.map(u => u.id);
    const areUsersEqual = prevProps.users.length === nextProps.users.length &&
        prevUserIds.every(userId => nextUserIds.includes(userId)) &&
        nextUserIds.every(userId => prevUserIds.includes(userId));
    const res = areUsersEqual &&
        prevProps.id === nextProps.id &&
        prevProps.color === nextProps.color &&
        prevProps.isSessionSelected === nextProps.isSessionSelected &&
        prevProps.isSessionTooltipShownForQueryParam === nextProps.isSessionTooltipShownForQueryParam;
    return res;
}

const AvatarsDiv = memo((props: AvatarsDivProps) => {
    const [avatarDivSize, setAvatarDivSize] = useState<Size>({ width: 1, height: 1 });
    const [userChunks, setUserChunks] = useState<User[][]>([]);
    const avatarsDivRef = useRef<HTMLDivElement>(null);

    const getMaxNumRows = (height: number) => Math.floor(height / 42);
    const getMaxNumAvatars = (width: number) => Math.floor(width / 33);

    useEffect(() => {
        const containerDiv = avatarsDivRef.current?.closest(`#div-appointment-card-${props.id}`);
        if (containerDiv) {
            const containerSize = containerDiv.parentElement!.getBoundingClientRect();
            const titleSize = containerDiv.getElementsByClassName('appointment-title')![0].getBoundingClientRect();
            const textSize = containerDiv.getElementsByClassName('appointment-text')![0].getBoundingClientRect();
            const width = containerSize.width;
            const height = containerSize.height - (titleSize.height + textSize.height);

            if (width !== avatarDivSize.width || height !== avatarDivSize.height) {
                setAvatarDivSize({
                    width,
                    height
                });
            }

            const rows = getMaxNumRows(height);
            const avatarsPerRow = getMaxNumAvatars(width);
            const userChunksArr: User[][] = [];
            const usersCopy: User[] = [
                ...props.users
            ];

            for (let i = 0; i < rows; i++) {
                if (i === rows - 1) {
                    userChunksArr.push(usersCopy);
                    break;
                }
                const usersRow: User[] = [];
                for (let k = 0; k < avatarsPerRow; k++) {
                    if (usersCopy.length === 0) {
                        break;
                    }
                    usersRow.push(usersCopy.shift()!);
                }
                userChunksArr.push(usersRow);
            }
            setUserChunks(userChunksArr);
        }
    }, [props.users, props.id, avatarDivSize, avatarsDivRef]);

    useEffect(() => {
        if (props.isSessionSelected && !props.isSessionTooltipShownForQueryParam && avatarsDivRef.current) {
            //const dims = avatarsDivRef.current.getBoundingClientRect();
            //window.scrollTo(window.scrollX, dims.top - 60);
            avatarsDivRef.current.scrollIntoView({
                block: 'center',
                inline: 'center'
            });
            avatarsDivRef.current!.click();
            props.onTooltipForSessionFromQueryParamsShown();
        }
    }, [avatarsDivRef, props.isSessionSelected, props.isSessionTooltipShownForQueryParam, avatarsDivRef.current]);

    return (
        userChunks.length === 0 && avatarDivSize.height > 20 && props.users.length > 0 ?
            <>
                <div className='flex-row' style={{ marginLeft: '5px', alignItems: 'center', color: 'white' }}>
                    <Typography sx={{ fontSize: '0.85rem', marginLeft: '3px' }}><b>{props.users.length}</b> friends</Typography>
                    <TouchApp />
                    <Typography sx={{ fontSize: '0.85rem' }}>to view</Typography>
                </div>
            </>
            :
            <>
                <div ref={avatarsDivRef} className='appointment-users'
                    style={{
                        height: `${avatarDivSize.height}px`
                    }}>
                    {userChunks.map((usersRow, i) =>
                        <AvatarGroup
                            key={`avatars-group-${i}`}
                            max={Math.min(2, getMaxNumAvatars(avatarDivSize.width))}
                            sx={{ margin: '0px 0px 5px 0px', justifyContent: 'center' }}>
                            {usersRow.map((user, i) => {
                                return (
                                    user.avatar ?
                                        <Avatar
                                            key={`avatar-${user.id}-{${i}}`}
                                            sx={{
                                                width: 32,
                                                height: 32,
                                                //bgcolor: props.color[400] 
                                            }}
                                            alt={user.name}
                                            src={user.avatar}
                                            imgProps={{ referrerPolicy: 'no-referrer' }} />
                                        :
                                        <Avatar
                                            key={`avatar-${user.id}-{${i}}`}
                                            sx={{
                                                width: 32,
                                                height: 32,
                                                //bgcolor: props.color[400] 
                                            }}
                                            imgProps={{ referrerPolicy: 'no-referrer' }}
                                            alt={user.name}>{getInitials(user.name)}
                                        </Avatar>
                                )
                            }
                            )}
                        </AvatarGroup>
                    )}
                </div>
            </>
    );
}, propsAreEqual);
//(AvatarsDiv as any).whyDidYouRender = true;
export { AvatarsDiv };
