import { Backdrop, CircularProgress } from '@mui/material';
import { AppState, EventStore, SessionStore, UserStore } from '../../state';
import { CalendarFiltersDrawer } from '../calendarFiltersDrawer';
import { CalendarSettingsDrawer } from '../calendarSettingsDrawer';
import { Scheduler } from '../scheduler';
import './Calendar.scss';
import { SessionSearch } from '../sessionSearch';
import { useCallback, useEffect } from 'react';

interface CalendarProps {
    userStore: UserStore,
    sessionStore: SessionStore,
    eventStore: EventStore,
    appState: AppState
}

const Calendar = (props: CalendarProps) => {
    const onOpenSettingsDrawer = useCallback(() => props.appState.setIsCalendarSettingsDrawerOpen(true), []);
    const onOpenFiltersDrawer = useCallback(() => props.appState.setIsCalendarFiltersDrawerOpen(true), []);
    const onOpenSessionSearchDrawer = useCallback(() => props.appState.setIsSessionSearchDrawerOpen(true), []);

    useEffect(() => {
        console.log('Calendar created');
        return () => {
            console.log('Calendar unmounting');
        }
    }, []);

    return (
        <div id='calendar-div-outer'>
            {
                props.eventStore.selectedEvent &&
                <>
                    <Backdrop
                        sx={{ zIndex: 2147483647659 }}
                        open={props.sessionStore.shouldWait}
                    >
                        <CircularProgress thickness={6} size={'10rem'} />
                    </Backdrop>

                    <div id='calendar-div'>
                        <CalendarSettingsDrawer
                            appState={props.appState}
                            eventStore={props.eventStore}
                            sessionStore={props.sessionStore}
                            userStore={props.userStore}
                        />
                        <CalendarFiltersDrawer
                            appState={props.appState}
                            eventStore={props.eventStore}
                            sessionStore={props.sessionStore}
                            userStore={props.userStore}
                        />
                        <SessionSearch
                            appState={props.appState}
                            sessionStore={props.sessionStore} />
                        {/* <Profiler id="Scheduler" onRender={(props) => console.log(props)}> */}
                        <Scheduler
                            sessionStore={props.sessionStore}
                            eventStore={props.eventStore}
                            userStore={props.userStore}
                            appState={props.appState}
                            onOpenFiltersDrawer={onOpenFiltersDrawer}
                            onOpenSettingsDrawer={onOpenSettingsDrawer}
                            onOpenSessionSearchDrawer={onOpenSessionSearchDrawer}
                        ></Scheduler>
                        {/* </Profiler> */}
                    </div>
                </>
            }
        </div>
    );
}

export default Calendar;
