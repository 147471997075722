import { FormControl } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Crew } from '../../models';
import { UserStore } from '../../state';
import { AutoCompleteWithAvatars } from '../autoCompleteWithAvatars';

interface CrewsSearchProps {
    userStore: UserStore;
    onCrewSelected: (crew: Crew) => void;
    crews?: Crew[];
    label?: string;
}

const CrewsSearch = (props: CrewsSearchProps) => {
    const [selectedCrew, setSelectedCrew] = useState<Crew | null>(null);
    const handleSelectedCrewChange = (e, crew: Crew) => {
        setSelectedCrew(crew);
        props.onCrewSelected(crew);
    };
    useEffect(() => {
        if (selectedCrew) {
            const foundCrew = props.userStore.allMyCrews.find(c => c.id === selectedCrew?.id);
            if (foundCrew) {
                setSelectedCrew(foundCrew);
            } else {
                setSelectedCrew(null);
            }
        }
    }, [props.userStore.usersWithoutMe, selectedCrew, props.userStore.allMyCrews]);

    //const getCrewsWhereAdmin = () => props.userStore.currentUser ? props.userStore.allMyCrews.filter(c => c.admins.map(o => o.id).includes(props.userStore.currentUser!.id)) : [];
    const getAllMyCrews = () => props.crews || props.userStore.allMyCrews;

    return (
        <FormControl sx={{ width: '100%' }}>
            <AutoCompleteWithAvatars
                id='crews-autocomplete-dropdown'
                label={props.label || 'Search Crew'}
                aria-label='crews-autocomplete-dropdown'
                placeholder='Start Typing'
                userStore={props.userStore}
                multiple={false}
                items={getAllMyCrews()}
                selectedItems={selectedCrew}
                handleChange={handleSelectedCrewChange} />
        </FormControl>
    );
};

export default observer(CrewsSearch);
