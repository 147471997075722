/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      email
      name
      avatar
      crews {
        items {
          id
          userId
          crewId
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          crew {
            id
            name
            avatar
            members {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            admins {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoritedByUsers {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      adminCrews {
        items {
          id
          userId
          crewId
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          crew {
            id
            name
            avatar
            members {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            admins {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoritedByUsers {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      favoriteCrews {
        items {
          id
          userId
          crewId
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          crew {
            id
            name
            avatar
            members {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            admins {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoritedByUsers {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      userPreference {
        id
        user {
          id
          email
          name
          avatar
          crews {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          adminCrews {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          favoriteCrews {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          userPreference {
            id
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            selectedEventId
            userIdFilter
            timeFilter
            grouping
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userPreferenceUserId
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserPreferenceId
          owner
          __typename
        }
        selectedEventId
        userIdFilter
        timeFilter
        grouping
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userPreferenceUserId
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userUserPreferenceId
      owner
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      email
      name
      avatar
      crews {
        items {
          id
          userId
          crewId
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          crew {
            id
            name
            avatar
            members {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            admins {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoritedByUsers {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      adminCrews {
        items {
          id
          userId
          crewId
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          crew {
            id
            name
            avatar
            members {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            admins {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoritedByUsers {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      favoriteCrews {
        items {
          id
          userId
          crewId
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          crew {
            id
            name
            avatar
            members {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            admins {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoritedByUsers {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      userPreference {
        id
        user {
          id
          email
          name
          avatar
          crews {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          adminCrews {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          favoriteCrews {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          userPreference {
            id
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            selectedEventId
            userIdFilter
            timeFilter
            grouping
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userPreferenceUserId
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserPreferenceId
          owner
          __typename
        }
        selectedEventId
        userIdFilter
        timeFilter
        grouping
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userPreferenceUserId
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userUserPreferenceId
      owner
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      email
      name
      avatar
      crews {
        items {
          id
          userId
          crewId
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          crew {
            id
            name
            avatar
            members {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            admins {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoritedByUsers {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      adminCrews {
        items {
          id
          userId
          crewId
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          crew {
            id
            name
            avatar
            members {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            admins {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoritedByUsers {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      favoriteCrews {
        items {
          id
          userId
          crewId
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          crew {
            id
            name
            avatar
            members {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            admins {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoritedByUsers {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      userPreference {
        id
        user {
          id
          email
          name
          avatar
          crews {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          adminCrews {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          favoriteCrews {
            items {
              id
              userId
              crewId
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              crew {
                id
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          userPreference {
            id
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            selectedEventId
            userIdFilter
            timeFilter
            grouping
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userPreferenceUserId
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userUserPreferenceId
          owner
          __typename
        }
        selectedEventId
        userIdFilter
        timeFilter
        grouping
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userPreferenceUserId
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userUserPreferenceId
      owner
      __typename
    }
  }
`;
export const createUserPreference = /* GraphQL */ `
  mutation CreateUserPreference(
    $input: CreateUserPreferenceInput!
    $condition: ModelUserPreferenceConditionInput
  ) {
    createUserPreference(input: $input, condition: $condition) {
      id
      user {
        id
        email
        name
        avatar
        crews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        adminCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        favoriteCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userPreference {
          id
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          selectedEventId
          userIdFilter
          timeFilter
          grouping
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userPreferenceUserId
          owner
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserPreferenceId
        owner
        __typename
      }
      selectedEventId
      userIdFilter
      timeFilter
      grouping
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userPreferenceUserId
      owner
      __typename
    }
  }
`;
export const updateUserPreference = /* GraphQL */ `
  mutation UpdateUserPreference(
    $input: UpdateUserPreferenceInput!
    $condition: ModelUserPreferenceConditionInput
  ) {
    updateUserPreference(input: $input, condition: $condition) {
      id
      user {
        id
        email
        name
        avatar
        crews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        adminCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        favoriteCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userPreference {
          id
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          selectedEventId
          userIdFilter
          timeFilter
          grouping
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userPreferenceUserId
          owner
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserPreferenceId
        owner
        __typename
      }
      selectedEventId
      userIdFilter
      timeFilter
      grouping
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userPreferenceUserId
      owner
      __typename
    }
  }
`;
export const deleteUserPreference = /* GraphQL */ `
  mutation DeleteUserPreference(
    $input: DeleteUserPreferenceInput!
    $condition: ModelUserPreferenceConditionInput
  ) {
    deleteUserPreference(input: $input, condition: $condition) {
      id
      user {
        id
        email
        name
        avatar
        crews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        adminCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        favoriteCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userPreference {
          id
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          selectedEventId
          userIdFilter
          timeFilter
          grouping
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userPreferenceUserId
          owner
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserPreferenceId
        owner
        __typename
      }
      selectedEventId
      userIdFilter
      timeFilter
      grouping
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userPreferenceUserId
      owner
      __typename
    }
  }
`;
export const createCrew = /* GraphQL */ `
  mutation CreateCrew(
    $input: CreateCrewInput!
    $condition: ModelCrewConditionInput
  ) {
    createCrew(input: $input, condition: $condition) {
      id
      name
      avatar
      members {
        items {
          id
          userId
          crewId
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          crew {
            id
            name
            avatar
            members {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            admins {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoritedByUsers {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      admins {
        items {
          id
          userId
          crewId
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          crew {
            id
            name
            avatar
            members {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            admins {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoritedByUsers {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      favoritedByUsers {
        items {
          id
          userId
          crewId
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          crew {
            id
            name
            avatar
            members {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            admins {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoritedByUsers {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateCrew = /* GraphQL */ `
  mutation UpdateCrew(
    $input: UpdateCrewInput!
    $condition: ModelCrewConditionInput
  ) {
    updateCrew(input: $input, condition: $condition) {
      id
      name
      avatar
      members {
        items {
          id
          userId
          crewId
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          crew {
            id
            name
            avatar
            members {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            admins {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoritedByUsers {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      admins {
        items {
          id
          userId
          crewId
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          crew {
            id
            name
            avatar
            members {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            admins {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoritedByUsers {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      favoritedByUsers {
        items {
          id
          userId
          crewId
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          crew {
            id
            name
            avatar
            members {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            admins {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoritedByUsers {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteCrew = /* GraphQL */ `
  mutation DeleteCrew(
    $input: DeleteCrewInput!
    $condition: ModelCrewConditionInput
  ) {
    deleteCrew(input: $input, condition: $condition) {
      id
      name
      avatar
      members {
        items {
          id
          userId
          crewId
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          crew {
            id
            name
            avatar
            members {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            admins {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoritedByUsers {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      admins {
        items {
          id
          userId
          crewId
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          crew {
            id
            name
            avatar
            members {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            admins {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoritedByUsers {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      favoritedByUsers {
        items {
          id
          userId
          crewId
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          crew {
            id
            name
            avatar
            members {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            admins {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoritedByUsers {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createVenue = /* GraphQL */ `
  mutation CreateVenue(
    $input: CreateVenueInput!
    $condition: ModelVenueConditionInput
  ) {
    createVenue(input: $input, condition: $condition) {
      id
      name
      address
      info
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateVenue = /* GraphQL */ `
  mutation UpdateVenue(
    $input: UpdateVenueInput!
    $condition: ModelVenueConditionInput
  ) {
    updateVenue(input: $input, condition: $condition) {
      id
      name
      address
      info
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteVenue = /* GraphQL */ `
  mutation DeleteVenue(
    $input: DeleteVenueInput!
    $condition: ModelVenueConditionInput
  ) {
    deleteVenue(input: $input, condition: $condition) {
      id
      name
      address
      info
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
      name
      startDate
      endDate
      venueId
      venue {
        id
        name
        address
        info
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      info
      avatar
      isPublic
      isSponsored
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
      name
      startDate
      endDate
      venueId
      venue {
        id
        name
        address
        info
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      info
      avatar
      isPublic
      isSponsored
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      id
      name
      startDate
      endDate
      venueId
      venue {
        id
        name
        address
        info
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      info
      avatar
      isPublic
      isSponsored
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createLocation = /* GraphQL */ `
  mutation CreateLocation(
    $input: CreateLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    createLocation(input: $input, condition: $condition) {
      id
      eventId
      event {
        id
        name
        startDate
        endDate
        venueId
        venue {
          id
          name
          address
          info
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        info
        avatar
        isPublic
        isSponsored
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      name
      info
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateLocation = /* GraphQL */ `
  mutation UpdateLocation(
    $input: UpdateLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    updateLocation(input: $input, condition: $condition) {
      id
      eventId
      event {
        id
        name
        startDate
        endDate
        venueId
        venue {
          id
          name
          address
          info
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        info
        avatar
        isPublic
        isSponsored
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      name
      info
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteLocation = /* GraphQL */ `
  mutation DeleteLocation(
    $input: DeleteLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    deleteLocation(input: $input, condition: $condition) {
      id
      eventId
      event {
        id
        name
        startDate
        endDate
        venueId
        venue {
          id
          name
          address
          info
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        info
        avatar
        isPublic
        isSponsored
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      name
      info
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createSession = /* GraphQL */ `
  mutation CreateSession(
    $input: CreateSessionInput!
    $condition: ModelSessionConditionInput
  ) {
    createSession(input: $input, condition: $condition) {
      id
      eventId
      event {
        id
        name
        startDate
        endDate
        venueId
        venue {
          id
          name
          address
          info
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        info
        avatar
        isPublic
        isSponsored
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      locationId
      location {
        id
        eventId
        event {
          id
          name
          startDate
          endDate
          venueId
          venue {
            id
            name
            address
            info
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          info
          avatar
          isPublic
          isSponsored
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        name
        info
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      name
      startDate
      endDate
      info
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateSession = /* GraphQL */ `
  mutation UpdateSession(
    $input: UpdateSessionInput!
    $condition: ModelSessionConditionInput
  ) {
    updateSession(input: $input, condition: $condition) {
      id
      eventId
      event {
        id
        name
        startDate
        endDate
        venueId
        venue {
          id
          name
          address
          info
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        info
        avatar
        isPublic
        isSponsored
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      locationId
      location {
        id
        eventId
        event {
          id
          name
          startDate
          endDate
          venueId
          venue {
            id
            name
            address
            info
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          info
          avatar
          isPublic
          isSponsored
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        name
        info
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      name
      startDate
      endDate
      info
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteSession = /* GraphQL */ `
  mutation DeleteSession(
    $input: DeleteSessionInput!
    $condition: ModelSessionConditionInput
  ) {
    deleteSession(input: $input, condition: $condition) {
      id
      eventId
      event {
        id
        name
        startDate
        endDate
        venueId
        venue {
          id
          name
          address
          info
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        info
        avatar
        isPublic
        isSponsored
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      locationId
      location {
        id
        eventId
        event {
          id
          name
          startDate
          endDate
          venueId
          venue {
            id
            name
            address
            info
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          info
          avatar
          isPublic
          isSponsored
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        name
        info
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      name
      startDate
      endDate
      info
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createUserSession = /* GraphQL */ `
  mutation CreateUserSession(
    $input: CreateUserSessionInput!
    $condition: ModelUserSessionConditionInput
  ) {
    createUserSession(input: $input, condition: $condition) {
      id
      eventId
      event {
        id
        name
        startDate
        endDate
        venueId
        venue {
          id
          name
          address
          info
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        info
        avatar
        isPublic
        isSponsored
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      sessionId
      session {
        id
        eventId
        event {
          id
          name
          startDate
          endDate
          venueId
          venue {
            id
            name
            address
            info
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          info
          avatar
          isPublic
          isSponsored
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        locationId
        location {
          id
          eventId
          event {
            id
            name
            startDate
            endDate
            venueId
            venue {
              id
              name
              address
              info
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            info
            avatar
            isPublic
            isSponsored
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          name
          info
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        name
        startDate
        endDate
        info
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      userId
      user {
        id
        email
        name
        avatar
        crews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        adminCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        favoriteCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userPreference {
          id
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          selectedEventId
          userIdFilter
          timeFilter
          grouping
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userPreferenceUserId
          owner
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserPreferenceId
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateUserSession = /* GraphQL */ `
  mutation UpdateUserSession(
    $input: UpdateUserSessionInput!
    $condition: ModelUserSessionConditionInput
  ) {
    updateUserSession(input: $input, condition: $condition) {
      id
      eventId
      event {
        id
        name
        startDate
        endDate
        venueId
        venue {
          id
          name
          address
          info
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        info
        avatar
        isPublic
        isSponsored
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      sessionId
      session {
        id
        eventId
        event {
          id
          name
          startDate
          endDate
          venueId
          venue {
            id
            name
            address
            info
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          info
          avatar
          isPublic
          isSponsored
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        locationId
        location {
          id
          eventId
          event {
            id
            name
            startDate
            endDate
            venueId
            venue {
              id
              name
              address
              info
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            info
            avatar
            isPublic
            isSponsored
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          name
          info
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        name
        startDate
        endDate
        info
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      userId
      user {
        id
        email
        name
        avatar
        crews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        adminCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        favoriteCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userPreference {
          id
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          selectedEventId
          userIdFilter
          timeFilter
          grouping
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userPreferenceUserId
          owner
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserPreferenceId
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteUserSession = /* GraphQL */ `
  mutation DeleteUserSession(
    $input: DeleteUserSessionInput!
    $condition: ModelUserSessionConditionInput
  ) {
    deleteUserSession(input: $input, condition: $condition) {
      id
      eventId
      event {
        id
        name
        startDate
        endDate
        venueId
        venue {
          id
          name
          address
          info
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        info
        avatar
        isPublic
        isSponsored
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      sessionId
      session {
        id
        eventId
        event {
          id
          name
          startDate
          endDate
          venueId
          venue {
            id
            name
            address
            info
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          info
          avatar
          isPublic
          isSponsored
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        locationId
        location {
          id
          eventId
          event {
            id
            name
            startDate
            endDate
            venueId
            venue {
              id
              name
              address
              info
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            info
            avatar
            isPublic
            isSponsored
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          name
          info
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        name
        startDate
        endDate
        info
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      userId
      user {
        id
        email
        name
        avatar
        crews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        adminCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        favoriteCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userPreference {
          id
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          selectedEventId
          userIdFilter
          timeFilter
          grouping
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userPreferenceUserId
          owner
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserPreferenceId
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createJoinCrewCode = /* GraphQL */ `
  mutation CreateJoinCrewCode(
    $input: CreateJoinCrewCodeInput!
    $condition: ModelJoinCrewCodeConditionInput
  ) {
    createJoinCrewCode(input: $input, condition: $condition) {
      id
      crewId
      crew {
        id
        name
        avatar
        members {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        admins {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        favoritedByUsers {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      code
      expireDate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateJoinCrewCode = /* GraphQL */ `
  mutation UpdateJoinCrewCode(
    $input: UpdateJoinCrewCodeInput!
    $condition: ModelJoinCrewCodeConditionInput
  ) {
    updateJoinCrewCode(input: $input, condition: $condition) {
      id
      crewId
      crew {
        id
        name
        avatar
        members {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        admins {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        favoritedByUsers {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      code
      expireDate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteJoinCrewCode = /* GraphQL */ `
  mutation DeleteJoinCrewCode(
    $input: DeleteJoinCrewCodeInput!
    $condition: ModelJoinCrewCodeConditionInput
  ) {
    deleteJoinCrewCode(input: $input, condition: $condition) {
      id
      crewId
      crew {
        id
        name
        avatar
        members {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        admins {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        favoritedByUsers {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      code
      expireDate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createAllowedUser = /* GraphQL */ `
  mutation CreateAllowedUser(
    $input: CreateAllowedUserInput!
    $condition: ModelAllowedUserConditionInput
  ) {
    createAllowedUser(input: $input, condition: $condition) {
      id
      email
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAllowedUser = /* GraphQL */ `
  mutation UpdateAllowedUser(
    $input: UpdateAllowedUserInput!
    $condition: ModelAllowedUserConditionInput
  ) {
    updateAllowedUser(input: $input, condition: $condition) {
      id
      email
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAllowedUser = /* GraphQL */ `
  mutation DeleteAllowedUser(
    $input: DeleteAllowedUserInput!
    $condition: ModelAllowedUserConditionInput
  ) {
    deleteAllowedUser(input: $input, condition: $condition) {
      id
      email
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createCrewMember = /* GraphQL */ `
  mutation CreateCrewMember(
    $input: CreateCrewMemberInput!
    $condition: ModelCrewMemberConditionInput
  ) {
    createCrewMember(input: $input, condition: $condition) {
      id
      userId
      crewId
      user {
        id
        email
        name
        avatar
        crews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        adminCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        favoriteCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userPreference {
          id
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          selectedEventId
          userIdFilter
          timeFilter
          grouping
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userPreferenceUserId
          owner
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserPreferenceId
        owner
        __typename
      }
      crew {
        id
        name
        avatar
        members {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        admins {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        favoritedByUsers {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateCrewMember = /* GraphQL */ `
  mutation UpdateCrewMember(
    $input: UpdateCrewMemberInput!
    $condition: ModelCrewMemberConditionInput
  ) {
    updateCrewMember(input: $input, condition: $condition) {
      id
      userId
      crewId
      user {
        id
        email
        name
        avatar
        crews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        adminCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        favoriteCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userPreference {
          id
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          selectedEventId
          userIdFilter
          timeFilter
          grouping
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userPreferenceUserId
          owner
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserPreferenceId
        owner
        __typename
      }
      crew {
        id
        name
        avatar
        members {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        admins {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        favoritedByUsers {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteCrewMember = /* GraphQL */ `
  mutation DeleteCrewMember(
    $input: DeleteCrewMemberInput!
    $condition: ModelCrewMemberConditionInput
  ) {
    deleteCrewMember(input: $input, condition: $condition) {
      id
      userId
      crewId
      user {
        id
        email
        name
        avatar
        crews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        adminCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        favoriteCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userPreference {
          id
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          selectedEventId
          userIdFilter
          timeFilter
          grouping
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userPreferenceUserId
          owner
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserPreferenceId
        owner
        __typename
      }
      crew {
        id
        name
        avatar
        members {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        admins {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        favoritedByUsers {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createCrewAdmin = /* GraphQL */ `
  mutation CreateCrewAdmin(
    $input: CreateCrewAdminInput!
    $condition: ModelCrewAdminConditionInput
  ) {
    createCrewAdmin(input: $input, condition: $condition) {
      id
      userId
      crewId
      user {
        id
        email
        name
        avatar
        crews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        adminCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        favoriteCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userPreference {
          id
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          selectedEventId
          userIdFilter
          timeFilter
          grouping
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userPreferenceUserId
          owner
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserPreferenceId
        owner
        __typename
      }
      crew {
        id
        name
        avatar
        members {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        admins {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        favoritedByUsers {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateCrewAdmin = /* GraphQL */ `
  mutation UpdateCrewAdmin(
    $input: UpdateCrewAdminInput!
    $condition: ModelCrewAdminConditionInput
  ) {
    updateCrewAdmin(input: $input, condition: $condition) {
      id
      userId
      crewId
      user {
        id
        email
        name
        avatar
        crews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        adminCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        favoriteCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userPreference {
          id
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          selectedEventId
          userIdFilter
          timeFilter
          grouping
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userPreferenceUserId
          owner
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserPreferenceId
        owner
        __typename
      }
      crew {
        id
        name
        avatar
        members {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        admins {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        favoritedByUsers {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteCrewAdmin = /* GraphQL */ `
  mutation DeleteCrewAdmin(
    $input: DeleteCrewAdminInput!
    $condition: ModelCrewAdminConditionInput
  ) {
    deleteCrewAdmin(input: $input, condition: $condition) {
      id
      userId
      crewId
      user {
        id
        email
        name
        avatar
        crews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        adminCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        favoriteCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userPreference {
          id
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          selectedEventId
          userIdFilter
          timeFilter
          grouping
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userPreferenceUserId
          owner
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserPreferenceId
        owner
        __typename
      }
      crew {
        id
        name
        avatar
        members {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        admins {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        favoritedByUsers {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createFavoriteCrew = /* GraphQL */ `
  mutation CreateFavoriteCrew(
    $input: CreateFavoriteCrewInput!
    $condition: ModelFavoriteCrewConditionInput
  ) {
    createFavoriteCrew(input: $input, condition: $condition) {
      id
      userId
      crewId
      user {
        id
        email
        name
        avatar
        crews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        adminCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        favoriteCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userPreference {
          id
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          selectedEventId
          userIdFilter
          timeFilter
          grouping
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userPreferenceUserId
          owner
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserPreferenceId
        owner
        __typename
      }
      crew {
        id
        name
        avatar
        members {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        admins {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        favoritedByUsers {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateFavoriteCrew = /* GraphQL */ `
  mutation UpdateFavoriteCrew(
    $input: UpdateFavoriteCrewInput!
    $condition: ModelFavoriteCrewConditionInput
  ) {
    updateFavoriteCrew(input: $input, condition: $condition) {
      id
      userId
      crewId
      user {
        id
        email
        name
        avatar
        crews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        adminCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        favoriteCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userPreference {
          id
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          selectedEventId
          userIdFilter
          timeFilter
          grouping
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userPreferenceUserId
          owner
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserPreferenceId
        owner
        __typename
      }
      crew {
        id
        name
        avatar
        members {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        admins {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        favoritedByUsers {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteFavoriteCrew = /* GraphQL */ `
  mutation DeleteFavoriteCrew(
    $input: DeleteFavoriteCrewInput!
    $condition: ModelFavoriteCrewConditionInput
  ) {
    deleteFavoriteCrew(input: $input, condition: $condition) {
      id
      userId
      crewId
      user {
        id
        email
        name
        avatar
        crews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        adminCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        favoriteCrews {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userPreference {
          id
          user {
            id
            email
            name
            avatar
            crews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            adminCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            favoriteCrews {
              items {
                id
                userId
                crewId
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            userPreference {
              id
              user {
                id
                email
                name
                avatar
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userUserPreferenceId
                owner
                __typename
              }
              selectedEventId
              userIdFilter
              timeFilter
              grouping
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userPreferenceUserId
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            userUserPreferenceId
            owner
            __typename
          }
          selectedEventId
          userIdFilter
          timeFilter
          grouping
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userPreferenceUserId
          owner
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userUserPreferenceId
        owner
        __typename
      }
      crew {
        id
        name
        avatar
        members {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        admins {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        favoritedByUsers {
          items {
            id
            userId
            crewId
            user {
              id
              email
              name
              avatar
              crews {
                nextToken
                startedAt
                __typename
              }
              adminCrews {
                nextToken
                startedAt
                __typename
              }
              favoriteCrews {
                nextToken
                startedAt
                __typename
              }
              userPreference {
                id
                selectedEventId
                userIdFilter
                timeFilter
                grouping
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                userPreferenceUserId
                owner
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              userUserPreferenceId
              owner
              __typename
            }
            crew {
              id
              name
              avatar
              members {
                nextToken
                startedAt
                __typename
              }
              admins {
                nextToken
                startedAt
                __typename
              }
              favoritedByUsers {
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
