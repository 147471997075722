import { Observable, ReplaySubject, Subject, Subscription, takeUntil } from 'rxjs';
import { Event, Location, User } from '../models';
import { createCrew, createJoinCode, findJoinCode, getEvents, getLocationsForEventId } from './graphqlOperations';
import { createJoinCrewCode } from '../graphql/mutations';
import { addHoursToDate } from '../util';
import { UserService } from './userService';
import { API, graphqlOperation } from 'aws-amplify';
import * as subscriptions from '../graphql/subscriptions';
import { AppState } from '../state';

export class EventService {
    private locationsSub$ = new ReplaySubject<Location[]>();
    private eventsSub$ = new ReplaySubject<Event[]>();
    private selectedEventIdSub$ = new ReplaySubject<string | null>();
    private eventChangesSubscriptions$: Subscription[] = [];
    private locationChangesSubscriptions$: Subscription[] = [];
    private currentUser: User | null | undefined;
    private selectedEventId: string | null | undefined;

    constructor(private appState: AppState, private userService: UserService, private stop$: Subject<boolean>) {
        this.userService.currentUser$.pipe(
            takeUntil(stop$))
            .subscribe(user => {
                this.currentUser = user;
                this.setupEventSubscriptions();
                this.fetchEvents();
                if (this.selectedEventId) {
                    this.setupLocationSubscriptionsForEvent(this.selectedEventId);
                    this.fetchLocationsForEvent(this.selectedEventId);
                }
            });
        this.userService.myMemberCrews$.pipe(
            takeUntil(stop$))
            .subscribe(crews => {
                this.refreshAll();
            });
    }

    private delay = ms => new Promise(res => setTimeout(res, ms));

    private refreshAll = async () => {
        if (this.eventChangesSubscriptions$.length === 0) {
            this.setupEventSubscriptions();
            const events = await this.fetchEvents();
            this.selectedEventId = this.selectedEventId || events[0].id;
            if (this.selectedEventId) {
                this.setupLocationSubscriptionsForEvent(this.selectedEventId);
                this.fetchLocationsForEvent(this.selectedEventId);
            }
        }
    }

    get locations$(): Observable<Location[]> {
        return this.locationsSub$;
    }

    get events$(): Observable<Event[]> {
        return this.eventsSub$;
    }

    get selectedEventId$(): Observable<string | null> {
        return this.selectedEventIdSub$;
    }

    updateSelectedEventId = (eventId: string | null) => {
        if (this.selectedEventId !== eventId) {
            this.selectedEventId = eventId;
            this.selectedEventIdSub$.next(this.selectedEventId);
        }
    }

    fetchLocationsForEvent = async (eventId: string, shouldSpin: boolean = true) => {
        if (!this.currentUser || !eventId) {
            return;
        }
        if (shouldSpin) {
            this.appState.incrementWaitCounter();
        }
        try {
            const locations = await getLocationsForEventId(eventId);
            // await this.delay(5000);
            this.locationsSub$.next(locations);
        } catch (err) {
            console.log('Could not fetch locations for event id: ' + eventId);
            console.log(err);
        } finally {
            if (shouldSpin) {
                this.appState.decrementWaitCounter();
            }
        }
    }

    private fetchEvents = async (shouldSpin: boolean = true) => {
        if (!this.currentUser) {
            return;
        }
        if (shouldSpin) {
            this.appState.incrementWaitCounter();
        }
        try {
            const events = await getEvents();
            // await this.delay(5000);
            this.eventsSub$.next(events);
        } catch (err) {
            console.log('Could not fetch events');
            console.log(err);
        } finally {
            if (shouldSpin) {
                this.appState.decrementWaitCounter();
            }
        }
    }

    private handleEventChanges(provider: any, value: any) {
        console.log('Events changed, server is sending new values...');
        this.fetchEvents(false);
    }

    private handleLocationChanges(provider: any, value: any) {
        console.log('Locations changed, server is sending new values...');
        if (this.selectedEventId) {
            this.fetchLocationsForEvent(this.selectedEventId, false);
        }
    }

    private setupEventSubscriptions = () => {
        this.eventChangesSubscriptions$.forEach(s => s.unsubscribe);
        this.eventChangesSubscriptions$ = [];
        this.eventChangesSubscriptions$.push((API.graphql(graphqlOperation(subscriptions.onCreateEvent)) as unknown as Observable<any>)
            .subscribe({
                next: ({ provider, value }) => this.handleEventChanges(provider, value),
                error: (error) => console.warn(error)
            }));
        this.eventChangesSubscriptions$.push((API.graphql(graphqlOperation(subscriptions.onUpdateEvent)) as unknown as Observable<any>)
            .subscribe({
                next: ({ provider, value }) => this.handleEventChanges(provider, value),
                error: (error) => console.warn(error)
            }));
        this.eventChangesSubscriptions$.push((API.graphql(graphqlOperation(subscriptions.onDeleteEvent)) as unknown as Observable<any>)
            .subscribe({
                next: ({ provider, value }) => this.handleEventChanges(provider, value),
                error: (error) => console.warn(error)
            }));
    }

    private setupLocationSubscriptionsForEvent = (eventId: string) => {
        this.locationChangesSubscriptions$.forEach(s => s.unsubscribe);
        this.locationChangesSubscriptions$ = [];

        this.locationChangesSubscriptions$.push((API.graphql(graphqlOperation(
            subscriptions.onCreateLocation, {
            filter: {
                eventId: {
                    eq: eventId
                }
            }
        }
        )) as unknown as Observable<any>)
            .subscribe({
                next: ({ provider, value }) => this.handleLocationChanges(provider, value),
                error: (error) => console.warn(error)
            }));
        this.locationChangesSubscriptions$.push((API.graphql(graphqlOperation(subscriptions.onUpdateLocation, {
            filter: {
                eventId: {
                    eq: eventId
                }
            }
        })) as unknown as Observable<any>)
            .subscribe({
                next: ({ provider, value }) => this.handleLocationChanges(provider, value),
                error: (error) => console.warn(error)
            }));
        this.locationChangesSubscriptions$.push((API.graphql(graphqlOperation(subscriptions.onDeleteLocation, {
            filter: {
                eventId: {
                    eq: eventId
                }
            }
        })) as unknown as Observable<any>)
            .subscribe({
                next: ({ provider, value }) => this.handleLocationChanges(provider, value),
                error: (error) => console.warn(error)
            }));
    }

    // FOR TESTING
    // private createCrew = async () => {
    //     const createdCrew = await createCrew({
    //         id: 'placeholder',
    //         name: 'Canadian Expats'
    //     });
    //     // if (createdUser) {
    //     //     this.updateCurrentUser(createdUser);
    //     // }
    // }

    // FOR TESTING
    // private createCode = async (crewId: string) => {
    //     const rnd = Math.random();
    //     console.log(rnd);
    //     const code = Math.floor(100000 + Math.random() * 900000);
    //     console.log(code);
    //     const createdCrew = await createJoinCode({
    //         id: 'placeholder',
    //         code: JSON.stringify(code),
    //         crewId,
    //         expireDate: addHoursToDate(new Date(Date.now()), 24 * 30).toISOString()
    //     });
    //     // if (createdUser) {
    //     //     this.updateCurrentUser(createdUser);
    //     // }
    // }
}
