import { makeStyles } from '@material-ui/core/styles';
import { DateRangeOutlined, LocalActivityOutlined, ManageAccountsOutlined, PeopleAltOutlined } from '@mui/icons-material';
import { AppBar, IconButton, Toolbar, Typography, useTheme } from '@mui/material';
import { NavLink, useLocation } from 'react-router-dom';
import './BottomAppBar.scss';

const events = 'events';
const crews = 'crews';
const calendar = 'calendar';
//const home = 'home';
const profile = 'profile';
//const activeGradientColor = 'linear-gradient(259.45deg, #F86343 17.64%, #F319B6 94.22%)';


const BottomAppBar = () => {
    //const background = useTheme().palette.background.default;
    // const dividerColor = useTheme().palette.divider;
    const pathName: string = useLocation().pathname;

    const activeIconStyle = {
        activeColor: useTheme().palette.text.primary,
        normalColor: useTheme().palette.text.primary
    }

    const useStyles = makeStyles(theme => ({
        active: {
            //color: ({ activeColor }: any) => `${activeColor} !important`,
            //color: ({ activeGradientColor }: any) => `${activeGradientColor} !important`,
            textTransform: 'capitalize',
            fontWeight: '600 !important' as any,
            color: '#9343f8'
            // background: '-webkit-linear-gradient(45deg, #f319b6 17.64%, #9343f8 94.22%)',
            // webkitBackgroundClip: 'text',
            // webkitTextFillColor: 'transparent'
        },
        text: {
            marginTop: '-10px !important',
            marginBottom: '-6px !important',
            fontSize: '0.8rem !important',
            textTransform: 'capitalize'
        },
        normalText: {
            color: ({ normalColor }: any) => `${normalColor} !important`,
            textTransform: 'capitalize'
        },
        // gray: {
        //     color: ({ normalColor }: any) => `${normalColor} !important`
        // },
        activeIcon: {
            fill: 'url(#purple-gradient) !important'
        }
    }));

    const classes = useStyles(activeIconStyle);

    return (
        <footer style={{ position: 'fixed', bottom: 0, width: '100%' }}>
            <AppBar
                elevation={0}
                position='static'
                id='bottom-app-bar'
                color='default'
                sx={{
                    bottom: 0,
                    background: 'linear-gradient(126.6deg, rgba(255, 255, 255, 0.12) 28.69%, rgba(255, 255, 255, 0) 100%)',
                    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
                    WebkitFilter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
                    backdropFilter: 'blur(70px)',
                    WebkitBackdropFilter: 'blur(70px)',
                    borderRadius: '20px 20px 0px 0px'
                    //borderTop: `1px solid ${dividerColor}`
                }}>
                <Toolbar sx={{
                    justifyContent: 'space-around',
                    alignItems: 'baseline',
                    padding: '2px',
                    minHeight: '47px !important'
                }}>

                    {/* <div className='icon-div'>
                    <IconButton
                        activeClassName={classes.active}
                        className={classes.gray}
                        aria-label={home}
                        component={NavLink}
                        to={`/${home}`}
                    >
                        <HomeOutlined className={`${(pathName.includes(home)) ? classes.activeIcon : ''}`} />
                    </IconButton>
                    <Typography className={`${classes.text} ${(pathName.includes(home)) ? classes.active : classes.normalText}`}>
                        {home}</Typography>
                </div> */}
                    <div className='icon-div'>
                        <IconButton
                            activeClassName={classes.active}
                            aria-label={events}
                            // className={classes.gray}
                            component={NavLink}
                            to={`/${events}`}
                        >
                            <LocalActivityOutlined className={`${(pathName.includes(events)) ? classes.activeIcon : ''}`} />
                        </IconButton>
                        <Typography className={`${classes.text} ${(pathName.includes(events)) ? classes.active : classes.normalText}`}>
                            {events}
                        </Typography>
                    </div>
                    <div className='icon-div'>
                        <IconButton
                            // className={`icon-div ${classes.gray}`}
                            activeClassName={classes.active}
                            aria-label={calendar}
                            component={NavLink}
                            to={`/${calendar}`}
                        >
                            <DateRangeOutlined className={`${(pathName.includes(calendar)) ? classes.activeIcon : ''}`} />
                        </IconButton>
                        <Typography className={`${classes.text} ${(pathName.includes(calendar)) ? classes.active : classes.normalText}`}>
                            {calendar}
                        </Typography>
                    </div>
                    <div className='icon-div'>
                        <IconButton
                            activeClassName={classes.active}
                            aria-label={crews}
                            // className={classes.gray}
                            component={NavLink}
                            to={`/${crews}`}
                        >
                            <PeopleAltOutlined className={`${(pathName.includes(crews)) ? classes.activeIcon : ''}`} />
                        </IconButton>
                        <Typography className={`${classes.text} ${(pathName.includes(crews)) ? classes.active : classes.normalText}`}>
                            {crews}
                        </Typography>
                    </div>
                    <div className='icon-div'>
                        <IconButton
                            activeClassName={classes.active}
                            aria-label={profile}
                            // className={classes.gray}
                            component={NavLink}
                            to={`/${profile}`}
                        //onClick={(event) => setNavType(NavType.Profile)}
                        >
                            <ManageAccountsOutlined className={`${(pathName.includes(profile)) ? classes.activeIcon : ''}`} />
                        </IconButton>
                        <Typography className={`${classes.text} ${(pathName.includes(profile)) ? classes.active : classes.normalText}`}>
                            {profile}</Typography>
                    </div>
                </Toolbar>
            </AppBar >
        </footer>
    );
}

export default BottomAppBar;
