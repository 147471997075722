import { AppointmentTooltip } from '@devexpress/dx-react-scheduler-material-ui';
import { Cancel, Check, ContentCopyOutlined, IosShareOutlined, ShareOutlined } from '@mui/icons-material';
import { Alert, AlertColor, Avatar, Chip, Divider, IconButton, Snackbar, Tooltip } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { User } from '../../models';
import { AppState, SessionStore, UserStore } from '../../state';
import { MobileOSType, getInitials } from '../../util';
import { AddOrRemove, AddOrRemoveButtonType } from '../addOrRemove';
import './AppointmentTooltipCard.scss';

interface AppointmentTooltipCardProps {
    appState: AppState;
    userStore: UserStore;
    sessionStore: SessionStore;
    onClose: () => void;
    contentProps: AppointmentTooltip.ContentProps;
}

interface AttendeesDivProps {
    attendees: User[];
}

const arePropsEqual = (prevProps: AttendeesDivProps, nextProps: AttendeesDivProps): boolean => {
    const prevUserIds = prevProps.attendees.map(u => u.id);
    const nextUserIds = nextProps.attendees.map(u => u.id);
    const areUsersEqual = prevProps.attendees.length === nextProps.attendees.length &&
        prevUserIds.every(userId => nextUserIds.includes(userId)) &&
        nextUserIds.every(userId => prevUserIds.includes(userId));
    return areUsersEqual;
}

const AttendeesDiv = memo((props: AttendeesDivProps) => {
    useEffect(() => {
        console.debug('Attendees Div useEffect');

        return () => {
            console.debug('Attandees Div component is unmounting');
        }
    }, [])
    return (
        <div className='user-pills-div'>
            {props.attendees.map((user, i) =>
                <Chip size='medium'
                    key={`appointment-user-${user.id}`}
                    avatar={
                        user.avatar ?
                            <Avatar src={user.avatar} imgProps={{ referrerPolicy: 'no-referrer' }} />
                            : <Avatar>{getInitials(user.name)}</Avatar>
                    }
                    label={user.name}
                    variant='outlined'
                />)}
        </div>
    );
}, arePropsEqual);

const shareMessageTitle = `Event invite from CrewCal`;

const AppointmentTooltipCard = (appointmentTooltipCardProps: AppointmentTooltipCardProps) => {

    useEffect(() => {
        console.debug('AppointmentTooltipCard useEffect');

        return () => {
            console.debug('AppointmentTooltipCard component is unmounting');
            appointmentTooltipCardProps.sessionStore.setSessionId(undefined);
        }
    }, [])

    //console.debug('AppointmentTooltip RE-RENDERING')
    const [isToastOpen, setIsToastOpen] = useState<Boolean>(false);
    const [toastMessage, setToastMessage] = useState<string>('');
    const [toastSeverity, setToastSeverity] = useState<AlertColor>('success');

    const selectedSession = useMemo(() =>
        appointmentTooltipCardProps.sessionStore.sessions.find(
            s => s.id === appointmentTooltipCardProps.contentProps.appointmentData!.id)!,
        [
            appointmentTooltipCardProps.sessionStore.sessions,
            appointmentTooltipCardProps.contentProps.appointmentData
        ]);
    const attendees = useMemo(() => appointmentTooltipCardProps.sessionStore.getUsersForSession(selectedSession.id!),
        [
            selectedSession, appointmentTooltipCardProps.sessionStore.allUserSessions
        ]);

    const shareMessageBody = useMemo(() => `Hey, check this out! \r` +
        `${selectedSession.name} on ` +
        `${selectedSession.startDate.toLocaleString([], { dateStyle: 'short', timeStyle: 'short' })} \r` +
        `${selectedSession.location.name} - ${selectedSession.location.event.name} \r`,
        [
            selectedSession
        ]);

    const shareUrl = useMemo(() => `${window.location.origin}/calendar?sessionId=${selectedSession.id}`, [selectedSession]);

    const handleToastClose = useCallback(() => {
        setIsToastOpen(false);
    }, []);

    const copyLinkToClipboard = useCallback(async () => {
        let message = 'Link copied to clipboard!';
        let severity = 'success';
        try {
            await navigator.clipboard.writeText(shareUrl);
        } catch (error) {
            message = 'Could not copy link to clipboard';
            severity = 'error';
        }
        setToastMessage(message);
        setToastSeverity(severity as AlertColor);
        setIsToastOpen(true);
    }, [shareUrl]);

    const handleShare = useCallback(() => {
        try {
            navigator.share({
                //title: ``,
                text: shareMessageBody,
                url: shareUrl
            });
        } catch (error) {
            setToastMessage(`Could not share content: ${error}`);
            setToastSeverity('error' as AlertColor);
            setIsToastOpen(true);
        }
    }, [shareMessageBody, shareUrl]);

    const addUserToSession = useCallback(() => appointmentTooltipCardProps.sessionStore.joinSession(selectedSession.id!), [selectedSession]);
    const removeUserFromSession = useCallback(() => appointmentTooltipCardProps.sessionStore.leaveSession(selectedSession.id!), [selectedSession]);
    return (
        <AppointmentTooltip.Content {...
            {
                /* Remove the 'user' resource. This is so the user groups are not displayed on the tooltip */
                ...appointmentTooltipCardProps.contentProps,
                appointmentResources: (appointmentTooltipCardProps.contentProps as any).appointmentResources.filter(r => r.fieldName === 'location')
            }}
            style={{ backgroundColor: '#272537' }}>
            <div id='tooltip-div'>
                <div className='top-right flex-row' style={{ alignItems: 'center' }}>
                    {appointmentTooltipCardProps.sessionStore.isUserInSession(selectedSession.id!) &&
                        <Chip size='small' label='Going' color='success' variant='outlined' icon={<Check />} />
                    }
                    <IconButton color='default' className='less-padding' onClick={appointmentTooltipCardProps.onClose}>
                        <Cancel />
                    </IconButton>
                </div>
                <div>
                    <AttendeesDiv attendees={attendees} />
                    <div className='buttons-div'>
                        <div className='flex-row icons-div'>
                            <IconButton
                                color='default'
                                area-label='Share'
                                aria-controls='share-button'
                                onClick={(navigator as any).share ? handleShare : () => { }}
                                href={(navigator as any).share ?
                                    '' :
                                    `mailto:?to=&body=${shareMessageBody.replaceAll('\r', '%0D')} ${shareUrl}&subject=${shareMessageTitle}`}
                            >
                                <Tooltip title='Share' placement='top'>
                                    {appointmentTooltipCardProps.appState.mobileType === MobileOSType.IOS ?
                                        <IosShareOutlined /> :
                                        <ShareOutlined />
                                    }
                                </Tooltip>
                            </IconButton>
                            {/* <IconButton
                                href={`mailto:${attendees.map(a => a.id).join(',')}`}
                                color='default'>
                                <Tooltip title='E-mail Attendees' placement='top'>
                                    <EmailOutlined />
                                </Tooltip>
                            </IconButton> */}
                            <Divider />
                            <IconButton onClick={copyLinkToClipboard} color='default'>
                                <Tooltip title='Copy Link' placement='top'>
                                    <ContentCopyOutlined />
                                </Tooltip>
                            </IconButton>
                            <Snackbar
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center'
                                }}
                                open={isToastOpen as boolean}
                                autoHideDuration={5000}
                                onClose={handleToastClose}
                            >
                                <Alert onClose={handleToastClose} severity={toastSeverity} style={{ opacity: '1' }}>
                                    {toastMessage}
                                </Alert>
                            </Snackbar>
                        </div>
                        <AddOrRemove
                            buttonType={AddOrRemoveButtonType.Buttons}
                            isAdded={appointmentTooltipCardProps.sessionStore.isUserInSession(selectedSession.id!)}
                            onAdd={addUserToSession}
                            onRemove={removeUserFromSession}
                            disabled={appointmentTooltipCardProps.sessionStore.shouldWait}
                        />
                    </div>
                </div>
            </div>
        </AppointmentTooltip.Content >
    );
};

export default observer(AppointmentTooltipCard);
